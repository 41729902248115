import { Component, OnInit } from '@angular/core';
import {CONSTANTS} from "../../lib/constants";
import {
  MonthEndService, MonthlyNutritionistProduction, ReportsService, PDSService,
  ClientResult, MonthCloseState, ProductUtilization
} from "../../api/generated-api";
import {AppUtilities} from "../../lib/appUtilities.service";

@Component({
  selector: 'product-utilization',
  templateUrl: './productUtilization.component.html',
  styleUrls: ['./productUtilization.component.css']
})
export class ProductUtilizationComponent implements OnInit {

  public months: Array<{ nMonth: number, sMonth: string }> = CONSTANTS.MONTHS_SELECT_LIST;
  public years: Array<number> = CONSTANTS.YEARS;
  public monthOneString: string = '';
  public monthOneNumber: number;
  public monthTwoString: string = '';
  public monthTwoNumber: number;
  public yearOneNumber: number;
  public yearTwoNumber: number;
  public masterInfo: MonthlyNutritionistProduction[] = [];
  public appUtilities: AppUtilities = new AppUtilities();
  public products: ProductUtilization[] = [];
  public monthCloseState: MonthCloseState = <MonthCloseState>{};


  constructor(private monthEnd: MonthEndService, private reports: ReportsService, private pdsService: PDSService) { }

  ngOnInit(): void {
    this.initiate();
  }

  async initiate(): Promise<void> {
    await this.setClosedState();
    this.getMasterInfo()
  }

  async setClosedState() {
    this.monthCloseState = await this.monthEnd.getMonthCloseState().toPromise();
    this.monthOneNumber = this.monthCloseState.lastClosedMonth;
    this.monthTwoNumber = this.monthCloseState.lastClosedMonth;
    this.yearOneNumber = this.monthCloseState.lastClosedYear;
    this.yearTwoNumber = this.monthCloseState.lastClosedYear;
    this.setMonthString();
  }

  async getMasterInfo() {
    const response = await this.pdsService.getProductUtilization(this.monthOneNumber, this.yearOneNumber, this.monthTwoNumber, this.yearTwoNumber).toPromise();
    // console.log(response, 'response');
    this.products = response;
  }

  setMonthString() {
    if(this.monthOneNumber != null) {
      this.monthOneString = this.months.find(month => +month.nMonth === +this.monthOneNumber).sMonth;
    }
    if(this.monthOneNumber != null) {
      this.monthTwoString = this.months.find(month => +month.nMonth === +this.monthTwoNumber).sMonth;
    }
  }

  selectDate() {
    this.setMonthString();
    this.getMasterInfo();
  }

  getTotalByColumn(columnName: string) {
    return AppUtilities.addByColumn(this.masterInfo, columnName);
  }

  // changeMonthString(context) {
  //   if(context === 'One') {
  //     this.monthOneString = this.months.find(month => +month.nMonth === +this.monthOneNumber).sMonth;
  //   } else {
  //     this.monthTwoString = this.months.find(month => +month.nMonth === +this.monthTwoNumber).sMonth;
  //   }
  // }

}

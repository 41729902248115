import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Lookup, Entity, Compensation, MonthEndService, MonthCloseState } from '../../api/generated-api';
import { SwalDialog } from 'src/app/lib/swal-dialog';
import { LookupService } from '../../services/lookup.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'add-edit-compensation-modal',
  templateUrl: './add-edit-compensation-modal.component.html',
  styleUrls: ['./add-edit-compensation-modal.component.css']
})
export class AddEditCompensationModalComponent implements OnInit {

  public compensation: Compensation = <Compensation>{};
  public grossProfit: number;
  public grossPay: number;
  public isEdit: boolean = false;
  public isSaving: boolean = false;
  public constFlat: number;
  public constPercentage: number;
  public types: Lookup[] = [];
  public loggedInEntity: Entity = <Entity>{};
  public monthCloseState: MonthCloseState = <MonthCloseState>{};
  public isSubmitted: boolean = false;
  public nutritionistOid: number;

  constructor(public activeModal: NgbActiveModal,
    private lookup: LookupService,
    private userService: UserService,
    private monthEndService: MonthEndService) {
    this.types = this.lookup.findLookupByLookupName('AmountType');
    this.constFlat = this.lookup.findLookupOidByConstantValue('AMOUNTTYPE->FLAT');
    this.constPercentage = this.lookup.findLookupOidByConstantValue('AMOUNTTYPE->PERCENTAGE');
  }

  async ngOnInit(): Promise<void> {
    await this.getLoggedInUser();
    this.monthCloseState = await this.userService.getMonthCloseState();
    if (this.compensation && this.compensation.oid) {
      this.isEdit = true;
    }
    if (!this.isEdit) {
      this.createDefaultAdjustment();
    } else {
      this.calculateGrossPay();
    }
    if (!this.compensation.lkpAmountTypeOid) {
      this.compensation.lkpAmountTypeOid = this.constFlat;
    }
  }

  async getLoggedInUser(): Promise<void> {
    this.loggedInEntity = await this.userService.getLoggedInEntityUser();
  }

  createDefaultAdjustment() {
    this.compensation.entityOid = this.nutritionistOid;
    this.compensation.isManual = true;
    this.compensation.lkpAmountTypeOid = this.constFlat;
    this.compensation.grossProfit = this.grossProfit;
    this.compensation.reportedMonth = this.monthCloseState.currentMonth;
    this.compensation.reportedYear = this.monthCloseState.currentYear;
  }

  cancel(): void {
    this.resetProperties();
    this.activeModal.dismiss();
  }

  resetProperties(): void {
    this.compensation = <Compensation>{};
    this.isEdit = false;
    this.grossPay = null;
    this.grossProfit = null;
    this.nutritionistOid = null;
    this.isSubmitted = false;
  }

  isInvalid(): string {
    let rString: string = '';
    if (!this.compensation.description || this.compensation.description === '') {
      rString = rString.concat('Must include a description </br>');
    }
    if (+this.compensation.lkpAmountTypeOid === this.constFlat) {
      if (!this.compensation.flatAmount || +this.compensation.flatAmount === 0) {
        rString = rString.concat('Must include an amount </br>');
      }
    }
    if (+this.compensation.lkpAmountTypeOid === this.constPercentage && (!this.compensation.mathPercentage || +this.compensation.mathPercentage === 0)) {
      rString = rString.concat('Must include a percentage </br>');
    }
    if (!this.grossProfit && +this.compensation.lkpAmountTypeOid === +this.constPercentage) {
      rString = rString.concat('You are attempting to make a compensation adjustment that is a percentage of gross margin. However, in this month your nutritionist has no gross margin earned. Any attempt to save a percentage will result in 0 dollars paid. No save occurred. </br>'); // tslint:disable-line
    }
    return rString;
  }

  calculateGrossPay() {
    if (this.grossProfit) {
      if (+this.compensation.lkpAmountTypeOid === +this.constFlat) {
        this.compensation.grossPay = this.compensation.flatAmount ? this.compensation.flatAmount : 0;
        // console.log(this.compensation.grossPay, 'gross pay 1')
      } else {
         this.compensation.grossPay = this.compensation.mathPercentage ? ((+this.compensation.mathPercentage * .01) * this.grossProfit) : 0;
        // console.log(this.compensation.grossPay, 'gross pay 2')
      }
    }
  }

  async save() {
    const isInvalidString: string = this.isInvalid();
    if (this.isInvalid()) {
      this.isSubmitted = true;
      const myhtml = document.createElement('div');
      myhtml.innerHTML = isInvalidString;
      SwalDialog.show({
        title: 'Validation Failed',
        content: myhtml,
        icon: 'error',
      });
      this.isSaving = false;
      return false;
    } else {
      this.isSubmitted = true;
      this.isSaving = true;
      const saveCompensation: Compensation = this.createSaveCompensation();
      const res = await this.monthEndService.addUpdateCompensation(saveCompensation).toPromise();
      this.isSaving = false;
      this.resetProperties();
      this.activeModal.dismiss(res);
    }
  }

  public createSaveCompensation(): Compensation {
    const saveComp: Compensation = <Compensation>{ ...this.compensation };
    saveComp.grossProfit = this.grossProfit;
    if (saveComp.lkpAmountTypeOid === this.constFlat) {
      saveComp.displayPercentage = 0;
      saveComp.mathPercentage = 0;
    } else {
      saveComp.flatAmount = 0;
      saveComp.displayPercentage = saveComp.mathPercentage;
      saveComp.mathPercentage = (saveComp.mathPercentage * .01);
    }
    return saveComp;
  }

  isFlatAmount(): boolean {
    if (!this.compensation.lkpAmountTypeOid || +this.compensation.lkpAmountTypeOid === +this.constFlat) {
      return true;
    } else if(+this.compensation.lkpAmountTypeOid === this.constPercentage) {
      return false;
    }
    return true;
  }


}

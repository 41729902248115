import { Injectable } from '@angular/core';
import { RegionDTO, CompPlan, UserDTO, ValuePair, PDSService, AuthenticationService } from 'src/app/api/generated-api';
import { BehaviorSubject } from 'rxjs';
import { AppUtilities } from 'src/app/lib/appUtilities.service';
import { SwalDialog } from 'src/app/lib/swal-dialog';

export interface SecurityGroupObject {
    value: string;
    oid: number;
}

@Injectable()
export class UsersService {

    public allRegions: RegionDTO[] = [];
    public allCompPlans: CompPlan[] = [];
    public allMills: ValuePair[] = [];

    constructor(
        private pdsService: PDSService,
        private appUtilities: AppUtilities,
        private authentication: AuthenticationService,
    ) { }

    async getAllMills() {
        try {
            this.allMills = await this.pdsService.getAllMillsList(true).toPromise();
        } catch (error) {
            this.appUtilities.showServerErrorSwal(error.message ? error.message : error);
        }
    }

    async getAllRegions() {
        try {
            this.allRegions = await this.pdsService.getAllRegionDTOs().toPromise();
        } catch (error) {
            this.appUtilities.showServerErrorSwal(error.message ? error.message : error);
        }
    }

    async getAllCompPlans(): Promise<void> {
        try {
            this.allCompPlans = await this.pdsService.getAllCompPlans().toPromise();
        } catch (error) {
            this.appUtilities.showServerErrorSwal(error.message ? error.message : error);
        }
    }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Product } from 'src/app/api/generated-api';
import { PDSService } from '../../api/generated-api';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {SwalDialog} from '../../lib/swal-dialog';

@Component({
  selector: 'add-feed-product-mix-modal',
  templateUrl: './add-feed-product-mix-modal.component.html',
  styleUrls: ['./add-feed-product-mix-modal.component.scss']
})
export class AddFeedProductMixModalComponent implements OnInit, OnDestroy {

  public products: Product[] = [];
  public loggedInIsMill: boolean = false;
  public loggedInOid: number;
  public product: Product = <Product>{};
  public lbsPerTon: number;
  public isSubmitted: boolean = false;
  public isEdit: boolean = false;
  public currentProductOid: number = -1;

  search = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => this.products.filter(product => new RegExp(term, 'mi').test(product.name)).slice(0, 10))
  )
  public formatter = (product: Product) => product.name;
  constructor(public activeModal: NgbActiveModal, private pdsService: PDSService) { }

  ngOnInit(): void {

    if(this.product && this.product.oid && this.product.oid != null) {
      this.currentProductOid = this.product.oid;
    }

    this.getProductsList();
  }

  ngOnDestroy(): void {
    this.clearProperties();
  }

  async getProductsList() {
    if (this.loggedInIsMill) {
      this.products = await this.pdsService.getProductsByEntityOidThroughProduct2EntityMap(this.loggedInOid).toPromise();
    } else {
      this.products = await this.pdsService.getAllActiveProductsByLoggedInUser().toPromise();
    }
  }

  async save(): Promise<void> {

    this.isSubmitted = true;
    const isInvalidString: string = this.isInvalid();
    
    if (isInvalidString && isInvalidString !== '') {
      this.isSubmitted = true;
      const myhtml = document.createElement('div');
      myhtml.innerHTML = isInvalidString;
      SwalDialog.show({
        title: 'Validation Failed',
        content: myhtml,
        icon: 'error',
      });
      return;
    }
      if (this.isValid()) {
        this.product.lbsPerTon = this.lbsPerTon;
        this.activeModal.dismiss(this.product);
      }
  }

  isValid(): boolean {
    //if (!this.lbsPerTon || isNaN(this.lbsPerTon) || this.lbsPerTon < 0) {
    if (this.lbsPerTon == null || this.lbsPerTon == undefined || isNaN(this.lbsPerTon) || this.lbsPerTon < 0) {
      return false;
    }
    if (this.product && this.product.oid && this.product.name && this.product.name !== '') {
      return true;
    } else {
      return false;
    }
  }

  clearProperties() {
    this.product = <Product>{};
    this.isSubmitted = false;
    this.currentProductOid = -1;
  }

  cancel(): void {
    this.clearProperties();
    this.activeModal.dismiss();
  }

  isInvalid(): string {
    let rString: string = '';
    if (!this.product || this.product === undefined || this.product.name === undefined  || this.product.name === '') {
      rString = rString.concat('Sorry, that product name is not registered in our system </br>');
    }
    if (this.lbsPerTon == null || this.lbsPerTon == undefined || isNaN(this.lbsPerTon) || this.lbsPerTon < 0) {
      rString = rString.concat('Must set lbs per ton </br>');
    }
    return rString;
  }

  selectProduct() {
    const newProduct = this.products.find(p => p.oid === Number(this.currentProductOid));
	if(newProduct){
		this.product = newProduct;
	}
  }

}

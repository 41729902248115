import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Entity, Feed, FeedDelivery, ValuePair, FeedDeliveryDTO, PDSService, MonthCloseState, FeedRecipe } from 'src/app/api/generated-api';
import { UserService } from 'src/app/services/user.service';
import { SwalDialog } from 'src/app/lib/swal-dialog';
import { CONSTANTS } from 'src/app/lib/constants';
import { ToastrService } from 'ngx-toastr';
import { AppUtilities } from 'src/app/lib/appUtilities.service';

@Component({
  selector: 'add-edit-feed-delivery-modal',
  templateUrl: './add-edit-feed-delivery-modal.component.html',
  styleUrls: ['./add-edit-feed-delivery-modal.component.scss']
})

export class AddEditFeedDeliveryModalComponent implements OnInit {

  @ViewChild('tons', null) tonsEl: ElementRef;

  public constAdmin: string = CONSTANTS.SECURITY_GROUPING.ADMIN;
  public constMill: string = CONSTANTS.SECURITY_GROUPING.MILL;
  public constSuper: string = CONSTANTS.SECURITY_GROUPING.SUPERUSER;

  public isSaving: boolean = false;
  public loggedInEntity: Entity = <Entity>{};
  public monthCloseState: MonthCloseState;
  public millOid: number;
  public mills: ValuePair[] = [];
  public dairies: ValuePair[] = [];
  public feeds: Feed[] = [];
  public hasSaved: boolean = false;
  public currentMonth: string = '';
  public isSubmitted: boolean = false;
  public pageLoading: boolean = false;
  public feed: FeedDelivery = <FeedDelivery>{};
  public viewFeed: FeedDelivery = <FeedDelivery>{};
  public recipeList: FeedRecipe[] = [];


  constructor(public activeModal: NgbActiveModal,
    private userService: UserService,
    private pdsService: PDSService,
    private appUtilities: AppUtilities,
    private toaster: ToastrService
    ) {

    }

  async ngOnInit(): Promise<void> {
    await this.getSelectDairies(this.millOid);
    this.pageLoading = true;
    this.loggedInEntity = await this.userService.getLoggedInEntityUser();
    this.monthCloseState = await this.userService.getMonthCloseState();
    if (!this.viewFeed.oid) {
      this.setDefaultValues();
    } else {
      this.selectFeed(this.viewFeed.feedOid);
    }
    // await this.getDairies();
    // await this.getFeeds();
    this.pageLoading = false;
  }

  async getMills() {
    this.mills = await this.pdsService.getAllMillsList(true).toPromise();
  }

  async getSelectDairies(oid: number) {
    this.dairies = await this.pdsService.getListOfDairiesByMillOid(oid).toPromise();
    if ((!this.dairies || !this.dairies.length) && +oid > 0) {
      this.feed.entityOid_Dairy = -1;


      const res: string = await SwalDialog.show({
        icon: 'error',
        title: 'No Dairies Assigned',
        text: 'There are no dairies currently assigned to this Mill. Please contact Tech Support to map dairies.',
        buttons: {
          test1: { text: CONSTANTS.SWAL_RESPONSE.OK, value: CONSTANTS.SWAL_RESPONSE.OK }
        }
      });
      if (res === CONSTANTS.SWAL_RESPONSE.OK) {
      this.activeModal.dismiss();
      }
    }
  }

  async getFeeds() {
    this.feeds = await this.pdsService.getAllFeeds().toPromise();
  }

  // async getSelectFeeds(oid: number) {
  //     this.feeds = await this.pdsService.getMappedFeedsByDairyOid(oid).toPromise();
  //     if ((!this.feeds || !this.feeds.length) && +oid > 0) {
  //       SwalDialog.show({
  //         title: 'No Feeds Assigned',
  //         text: 'There are no feeds currently assigned to this Mill. Please contact Tech Support to add feeds.',
  //         icon: 'error',
  //       });
  //     }
  // }

  selectMill(oid) {
    if (this.feed.feedOid) {
      this.feed.feedOid = -1;
    }
    this.getSelectDairies(oid);
  }

  selectDairy(oid) {
    if (this.feed.entityOid_Dairy > 0) {
      this.getSelectFeeds(oid);
    }
    if(this.feed != null){
      this.feed.feedOid = null;
    }
  }

  async selectFeed(oid: number) {
    this.recipeList = await this.pdsService.getFeedRecipeByFeedOid(oid).toPromise();
  }

  async getSelectFeeds(oid) {
    const response = await this.pdsService.getMappedFeedsByDairyOid(oid).toPromise();
    // console.log(response, 'response')
    this.feeds = response;
  }

  setDefaultValues() {
    this.feed.entityOid_Dairy = -1;
    this.feed.feedOid = -1;
    // if (this.loggedInEntity.securityGroupName === this.constMill) {
    //   this.feed.entityOid_Mill = this.loggedInEntity.entityOid_Master;
    // } else {
    //   this.feed.entityOid_Mill = -1;
    // }
  }

  cancel(): void {
    this.resetProperties();
    this.close(this.hasSaved);
  }

  async save(isClose?: boolean) {
    this.feed.entityOid_Mill = this.millOid;
    const isInvalidString: string = this.isInvalid();
    if (isInvalidString && isInvalidString !== '') {
      this.isSubmitted = true;
      const myhtml = document.createElement('div');
      myhtml.innerHTML = isInvalidString;
      SwalDialog.show({
        title: 'Validation Failed',
        content: myhtml,
        icon: 'error',
      });
    } else {
      this.isSubmitted = true;
      this.isSaving = true;
      try {
        await this.pdsService.addUpdateFeedDeliveries([this.feed]).toPromise();
        this.hasSaved = true;
        this.toaster.success('Feed Delivery Added');
      } catch (error) {
        await this.appUtilities.showServerErrorSwal(error);
      }
      this.isSaving = false;
      if (isClose) {
        this.close(this.hasSaved);
      } else {
        this.feed.tons = null;
        this.tonsEl.nativeElement.focus();
      }
    }
  }

  buildFeedDeliveryDTO(): FeedDeliveryDTO {
    const newDTO: FeedDeliveryDTO = <FeedDeliveryDTO>{};
    newDTO.entityOid_Mill = this.feed.entityOid_Mill;
    newDTO.entityOid_Dairy = this.feed.entityOid_Dairy;
    newDTO.feedOid = this.feed.feedOid;
    newDTO.tons = this.feed.tons;
    const dairy: ValuePair = this.dairies.find(d => d.oid === +newDTO.entityOid_Dairy);
    newDTO.dairyName = dairy ? dairy.name : 'Unknown';
    const mill: ValuePair = this.mills.find(m => m.oid === +newDTO.entityOid_Mill);
    newDTO.millName = mill ? mill.name : 'Unknown';
    const feed: Feed = this.feeds.find(f => f.oid === +newDTO.feedOid);
    newDTO.feedName = feed ? feed.name : 'Unknown';
    return newDTO;
  }

  isInvalid(): string {
    let rString: string = '';
    if (!this.feed.entityOid_Dairy || +this.feed.entityOid_Dairy === -1) {
      rString = rString.concat('Must select a Dairy  </br>');
    }
    if (!this.feed.feedOid || +this.feed.feedOid === -1) {
      rString = rString.concat('Must select a Feed  </br>');
    }
    if (+this.feed.tons === null) {
      rString = rString.concat('Must input Tons </br>');
    }
    return rString;
  }

  resetProperties() {
    this.hasSaved = false;
    this.isSubmitted = false;
    this.feed = <FeedDelivery>{};
  }

  close(hasSaved: boolean) {
    this.resetProperties();
    this.activeModal.dismiss(hasSaved);
  }

}


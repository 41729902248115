import { Component, OnInit, ViewChild } from '@angular/core';
import { TriListAssignor, PDSService, ValuePair } from '../../api/generated-api';
import { CONSTANTS } from '../../lib/constants';
import { AppUtilities } from '../../lib/appUtilities.service';
import { ToastrService } from 'ngx-toastr';
import { SideBarListComponent } from 'src/app/components/side-bar-list/side-bar-list.component';
import { Subscription } from "rxjs/index";
import { UrlObfuscationService } from "../../lib/obfuscate.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'mapping',
  templateUrl: './mapping.component.html',
  styleUrls: ['./mapping.component.css']
})
export class MappingComponent implements OnInit {

  public masterList: Array<any> = [];
  public selectedOid;
  public activeObj: TriListAssignor = <TriListAssignor>{list1: [], list2: [], list3: []};
  public pageLoading: boolean = true;
  public allItemsChecked1: boolean = false;
  public allItemsChecked2: boolean = false;
  public allItemsChecked3: boolean = false;
  public activeTab: string = 'Product Mapping';
  public originalCheckSum: string = '';
  public isSaving: boolean = false;
  public constNutritionists: string = 'Nutritionist Mapping';
  public constMills: string = 'Mill Mapping';
  public constFeeds: string = 'Feed Mapping';
  public constProducts: string = 'Product Mapping';
  public constRegions: string = 'Region Mapping';
  public constDairies: string = 'Dairy Mapping';
  public navTitles: Array<{ name: string, value: string }> = [
    { name: CONSTANTS.MAPPING_TABLE.PRODUCTS, value: 'Product Mapping' },
    { name: CONSTANTS.MAPPING_TABLE.MILLS, value: 'Mill Mapping' },
    { name: CONSTANTS.MAPPING_TABLE.NUTRITIONISTS, value: 'Nutritionist Mapping' },
    { name: CONSTANTS.MAPPING_TABLE.REGIONS, value: 'Region Mapping' },
    { name: CONSTANTS.MAPPING_TABLE.DAIRIES, value: 'Dairy Mapping' }
  ];
  public initTab: string = '';
  public userSubscription: Subscription;
  public initOid: number;

  @ViewChild('sideBarList', null) sideBarListRef: SideBarListComponent;

  constructor(private pds: PDSService,
    private appUtilities: AppUtilities,
    private toast: ToastrService,
    private activatedRoute: ActivatedRoute, private router: Router) { }

  async ngOnInit(): Promise<void> {
    const paramString = this.activatedRoute.snapshot.queryParams.mappingTab;
    if(paramString && this.navTitles.indexOf(paramString)){
      // const paramOid = this.activatedRoute.snapshot.params.id;
      // if(paramOid && paramOid !== ""){
      //   await this.setInitActiveTab(paramString);
        // await this.setInitActiveTab(paramString, +UrlObfuscationService.deobfuscate(paramOid));
      // } else {
        await this.setInitActiveTab(paramString);
      // }
    } else {
      await this.setInitActiveTab(this.constProducts);
    }
    this.getParamSubscription();

    this.pageLoading = false;
  }

  getParamSubscription(){
    this.userSubscription = this.activatedRoute.params.subscribe(async (param: { id: string }) => {
      if (param.id) {
        const foundMapping = this.masterList.find(m => m.oid === +UrlObfuscationService.deobfuscate(param.id));
        // console.log(foundMapping, 'found mapping')
        this.selectObject(foundMapping.oid);
      } else {
        await this.router.navigate([`/mapping/${UrlObfuscationService.obfuscate(this.masterList[0].oid)}`], { queryParams: { mappingTab: this.activeTab} });
      }
    });
  }

  async setMasterList() {
    switch (this.activeTab) {
      // case this.constFeeds:
      //   this.masterList = await this.pds.getAllFeeds().toPromise();
      //   await this.getAllFeeds();
      //   break;

      case this.constMills:
        this.masterList = await this.pds.getAllMillsList(true).toPromise();
        await this.getAllMills();
        break;

      case this.constNutritionists:
        this.masterList = await this.pds.getAllNutritionistsList(true).toPromise();
        await this.getAllNutritionists();
        break;

      case this.constRegions:
        this.masterList = await this.pds.getAllRegionsList(true).toPromise();
        await this.getAllRegions();
        break;

      case this.constDairies:
        this.masterList = await this.pds.getAllDairiesList(true).toPromise();
        await this.getAllDairies();
        break;

      case this.constProducts:
        this.masterList = await this.pds.getAllProductsList(true).toPromise();
        await this.getAllProducts();
        break;

      default:
        break;
    }
  }

  async initiate() {
    this.masterList = await this.pds.getAllProductsList(true).toPromise();
    if (this.masterList[0]) {
      this.selectedOid = this.masterList[0].oid;
      this.activeObj = await this.pds.getMillAndNutritionistMappingForProduct(this.masterList[0].oid).toPromise();
      this.originalCheckSum = this.appUtilities.createCheckSum(this.activeObj);
    }
    this.updateLists();
    this.pageLoading = false;
  }

  updateLists() {
    this.checkSelectStatus(this.activeObj.list1, 'allItemsChecked1');
    this.checkSelectStatus(this.activeObj.list2, 'allItemsChecked2');
    this.checkSelectStatus(this.activeObj.list3, 'allItemsChecked3');
  }

  async getAllFeeds(currentOid?: number) {
    this.masterList = await this.pds.getAllFeeds().toPromise();
    if (currentOid) {
      await this.selectObject(currentOid);
    } else {
      await this.selectObject(this.masterList[0].oid);
    }
  }

  async getAllNutritionists(currentOid?: number) {
    this.masterList = await this.pds.getAllNutritionistsList(true).toPromise();
    if (currentOid) {
      await this.selectObject(currentOid);
    } else {
      await this.selectObject(this.masterList[0].oid);
    }
  }

  async getAllMills(currentOid?: number) {
    this.masterList = await this.pds.getAllMillsList(true).toPromise();
    if (currentOid) {
      await this.selectObject(currentOid);
    } else {
      await this.selectObject(this.masterList[0].oid);
    }
  }

  async getAllDairies(currentOid?: number) {
    this.masterList = await this.pds.getAllDairiesList(true).toPromise();
    if (currentOid) {
      await this.selectObject(currentOid);
    } else {
      await this.selectObject(this.masterList[0].oid);
    }
  }

  async getAllRegions(currentOid?: number) {
    // this.masterList = await this.pds.getAllRegionsList().toPromise();
    this.masterList = await this.pds.getAllRegionsList(true).toPromise();
    if (currentOid) {
      await this.selectObject(currentOid);
    } else {
      await this.selectObject(this.masterList[0].oid);
    }
  }

  async getAllProducts(currentOid?: number) {
    this.masterList = await this.pds.getAllProductsList(true).toPromise();
    if (currentOid) {
      await this.selectObject(currentOid);
    } else {
      await this.selectObject(this.masterList[0].oid);
    }
  }


  async selectObject(oid: number): Promise<void> {
    switch (this.activeTab) {
      case this.constProducts:
        this.activeObj = await this.pds.getMillAndNutritionistMappingForProduct(oid).toPromise();
        break;

      // case this.constFeeds:
      //   this.activeObj = await this.pds.getMillAndNutritionistMappingForFeed(oid).toPromise();
      //   break;

      case this.constNutritionists:
        this.activeObj = await this.pds.getFeedAndProductMappingForNutritionist(oid).toPromise();
        break;

      case this.constRegions:
        this.activeObj = await this.pds.getDairyAndManagerMappingForRegion(oid).toPromise();
        break;

      case this.constDairies:
        this.activeObj = await this.pds.getNutritionistAndRegionMappingForDairy(oid).toPromise();
        break;

      case this.constMills:
        this.activeObj = await this.pds.getFeedAndProductAndDairyMappingForMill(oid).toPromise();
        break;

      default:
        break;
    }
    this.selectedOid = oid;
    this.originalCheckSum = this.appUtilities.createCheckSum(this.activeObj);
  }


  async canDeactivate(): Promise<boolean> {
    let bReturn = false;
    if (this.activeObj.targetOid === -1 ||
      (this.originalCheckSum !== '' && this.appUtilities.createCheckSum(this.activeObj) !== this.originalCheckSum)) {
      const res: string = await this.appUtilities.showUnsavedChangesSwal();
      if (res === CONSTANTS.SWAL_RESPONSE.YES) {
        await this.saveUpdateObject();
        bReturn = true;
      } else if (res === CONSTANTS.SWAL_RESPONSE.NO) {
        // await this.getRegions();
        // TODO: add no functionality
        bReturn = true;
      } else {
        bReturn = false;
      }
    } else {
      bReturn = true;
    }
    return bReturn;
  }

  async setInitActiveTab(tabName) {
    const isValidToNavigate: boolean = await this.canDeactivate();
    if (isValidToNavigate) {
      this.pageLoading = true;

      switch (tabName) {
        // case this.constFeeds:
        //   this.activeTab = this.constFeeds;
        //   await this.getAllFeeds(avtiveOid);
        //   break;

        case this.constMills:
          this.activeTab = this.constMills;
          this.masterList = await this.pds.getAllMillsList(true).toPromise();
          break;
        case this.constNutritionists:
          this.activeTab = this.constNutritionists;
          this.masterList = await this.pds.getAllNutritionistsList(true).toPromise();
          break;

        case this.constRegions:
          this.activeTab = this.constRegions;
          this.masterList = await this.pds.getAllRegionsList(true).toPromise();
          break;

        case this.constDairies:
          this.activeTab = this.constDairies;
          this.masterList = await this.pds.getAllDairiesList(true).toPromise();
          break;

        case this.constProducts:
          this.activeTab = this.constProducts;
          this.masterList = await this.pds.getAllProductsList(true).toPromise();
          break;

        default:
          break;
      }
      this.updateLists();
    }
  }

  async setActiveTab(tabName, avtiveOid?: number) {
    const isValidToNavigate: boolean = await this.canDeactivate();
    if (isValidToNavigate) {
      this.pageLoading = true;

      switch (tabName) {
        // case this.constFeeds:
        //   this.activeTab = this.constFeeds;
        //   await this.getAllFeeds(avtiveOid);
        //   break;

        case this.constMills:
          this.activeTab = this.constMills;
          await this.getAllMills(avtiveOid);
          break;

        case this.constNutritionists:
          this.activeTab = this.constNutritionists;
          await this.getAllNutritionists(avtiveOid);
          break;

        case this.constRegions:
          this.activeTab = this.constRegions;
          await this.getAllRegions(avtiveOid);
          break;

        case this.constDairies:
          this.activeTab = this.constDairies;
          await this.getAllDairies(avtiveOid);
          break;

        case this.constProducts:
          this.activeTab = this.constProducts;
          await this.getAllProducts(avtiveOid);
          break;

        default:
          break;
      }
      this.updateLists();
      this.pageLoading = false;
      this.sideBarListRef.clearSearch();
    }
  }

  async saveUpdateObject() {
    try {
      this.isSaving = true;
      const response = await this.pds.addUpdateTriListAssignor(this.activeObj).toPromise();
      this.toast.success('Save successful');
      this.originalCheckSum = this.appUtilities.createCheckSum(this.activeObj);
      if (this.activeTab === this.constFeeds) {
        this.getAllFeeds(this.selectedOid);
      } else if (this.activeTab === this.constProducts) {
        this.getAllProducts(this.selectedOid);
      } else if (this.activeTab === this.constNutritionists) {
        this.getAllNutritionists(this.selectedOid);
      } else if (this.activeTab === this.constMills) {
        this.getAllMills(this.selectedOid);
      }
      this.selectObject(this.selectedOid);
      this.isSaving = false;
    } catch (error) {
      this.isSaving = false;
    }
  }

  checkSelectStatus(list: ValuePair[], allSelectedProperty: string) {
    for (const obj of list) {
      if (!obj.isChecked) {
        this[allSelectedProperty] = false;
        break;
      } else {
        this[allSelectedProperty] = true;
      }
    }
  }

  selectAllMappings(list: ValuePair[], allSelectedProperty: string) {
    if (!this[allSelectedProperty]) {
      this[allSelectedProperty] = true;
      for (const item of list) {
        item.isChecked = true;
      }
    } else {
      this[allSelectedProperty] = false;
      for (const item of list) {
        item.isChecked = false;
      }
    }
  }

  async cancel() {
    await this.selectObject(this.selectedOid);
    this.updateLists();
  }

}

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {DairyNutritionistDTO} from '../../api/generated-api';

@Component({
  selector: 'add-nutritionists-modal',
  templateUrl: './add-nutritionists-modal.component.html',
  styleUrls: ['./add-nutritionists-modal.component.scss']
})
export class AddNutritionistsModalComponent implements OnInit {

  public nutritionist: DairyNutritionistDTO = <DairyNutritionistDTO>{};

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

}

import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../services/user.service';
import { AddDairyModalComponent } from '../../components/add-dairy-modal/add-dairy-modal.component';
import { SwalDialog } from '../../lib/swal-dialog';
import { AppUtilities } from '../../lib/appUtilities.service';
import { CONSTANTS } from '../../lib/constants';
import { ToastrService } from 'ngx-toastr';
import { NutritionistDTO, Lookup, Entity, DairyDTO, RegionDTO, PDSService, ValuePair } from '../../api/generated-api';
import { UrlObfuscationService } from '../../lib/obfuscate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/index';


@Component({
  selector: 'regions',
  templateUrl: './regions.component.html',
  styleUrls: ['./regions.component.scss']
})
export class RegionsComponent implements OnInit {

  public regions: ValuePair[] = [];
  public masterRegionsList: ValuePair[] = [];
  public dairies: DairyDTO[] = [];
  public selectedRegion: RegionDTO = <RegionDTO>{ dairies: [], managers: [] };
  public loggedInUser: Entity;
  public availableDairies: DairyDTO[] = [];
  public availableManagers: NutritionistDTO[] = [];
  public originalCheckSum: string = '';
  public pageLoading: boolean = false;
  public saving: boolean = false;
  public nutritionists: NutritionistDTO[] = [];
  public regionalManager: NutritionistDTO = <NutritionistDTO>{};
  public showPreference: string = 'Active';
  public managerOid: number;
  public userSubscription: Subscription;
  public isSubmitted: boolean = false;

  @ViewChild("regionName", null) regionNameEl: ElementRef;

  constructor(private modalService: NgbModal,
    private pdsService: PDSService,
    private userService: UserService,
    private appUtilities: AppUtilities,
    private toast: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
    this.pageLoading = true;
    await this.initiate();
    await this.getParamSubscription();
    this.pageLoading = false;
  }

  async initiate(): Promise<void> {
    await this.getLoggedInUser();
    await this.getAllDairies();
    await this.getAllNutritionists();
    await this.getRegions();
    this.regions = this.regions.filter(region => {
      return region.isActive;
    });
    if(this.regions && this.regions.length) {
     await this.selectRegion(this.regions[0].oid);
    }
    // if (this.regions[0]) {
    //   this.selectedRegion = this.regions[0];
    //   this.originalCheckSum = this.appUtilities.createCheckSum(this.selectedRegion);
    //   // this.router.location.go('regions/' + UrlObfuscationService.obfuscate(this.selectedRegion.oid));
    // }
    // this.getParamSubscription();
  }

  async getLoggedInUser(): Promise<void> {
    this.loggedInUser = await this.userService.getLoggedInEntityUser();
  }

  getParamSubscription(): void {
    this.userSubscription = this.activatedRoute.params.subscribe(async (param: { id: string }) => {
      if (param.id) {
        const foundActiveRegion = this.regions.find(feed => feed.oid === +UrlObfuscationService.deobfuscate(param.id));
        this.selectedRegion = await this.pdsService.getRegionDTOByOid(foundActiveRegion.oid).toPromise();
        this.originalCheckSum = this.appUtilities.createCheckSum(this.selectedRegion);
      } else {
        await this.router.navigate([`/regions/${UrlObfuscationService.obfuscate(this.regions[0].oid)}`]);
      }
    });
  }

  async getAllDairies() {
    try {
      this.dairies = await this.pdsService.getAllDairyDTOs().toPromise();
    } catch (error) {
    }
  }

  async getAllNutritionists(): Promise<void> {
    try {
      this.nutritionists = await this.pdsService.getAllNutritionistDTOs().toPromise();
    } catch (error) {
    }
  }

  async changeActiveRegion(oid: number): Promise<void> {
    this.router.navigate([`/regions/${UrlObfuscationService.obfuscate(oid)}`]);
  }

  addRegion(): Promise<void> {
    if (this.selectedRegion.oid === -1) {
      return SwalDialog.show({
        title: 'Cannot Add New',
        text: 'There is already a new Region creation in progress. Please cancel or save before adding another.',
        icon: 'warning',
        dangerMode: true,
      });
    } else {
      const newRegionDTO: RegionDTO = <RegionDTO>{ dairies: [], managers: [] };
      newRegionDTO.name = '';
      newRegionDTO.isActive = true;
      newRegionDTO.oid = -1;
      const newRegionValuePair: ValuePair = <ValuePair>{};
      newRegionValuePair.isChecked = false;
      newRegionValuePair.isActive = false;
      newRegionValuePair.oid = -1;
      newRegionValuePair.name = '';
      this.regions.push(newRegionValuePair);
      this.selectRegion(-1, newRegionDTO);

      this.regionNameEl.nativeElement.focus();
    }
    // if (this.selectedRegion.oid === -1) {
    //   return SwalDialog.show({
    //     title: 'Cannot Add New',
    //     text: 'There is already a new region creation in progress. Please cancel or save before adding another.',
    //     icon: 'warning',
    //     dangerMode: true,
    //   });
    // } else {
    //   const newRegion: RegionDTO = <RegionDTO>{};
    //   newRegion.name = 'New Region';
    //   newRegion.oid = -1;
    //   newRegion.isActive = true;
    //   newRegion.dairies = [];
    //   newRegion.managers = [];
    //   this.regions.push(newRegion);
    //   this.selectedRegion = newRegion;
    // }
  }

  async getRegions(): Promise<void> {
    try {
      this.regions = await this.pdsService.getRegionListMapped2LoggedInUser().toPromise();
      this.masterRegionsList = JSON.parse(JSON.stringify(this.regions));
    } catch (error) {
      await this.appUtilities.showServerErrorSwal(error);
    }
    // try {
    //   this.regions = await this.pdsService.getAllRegionDTOs().toPromise();
    //   this.masterRegionsList = JSON.parse(JSON.stringify(this.regions));
    //   this.pageLoading = false;
    // } catch (error) {
    // }
  }

  async canDeactivate(): Promise<boolean> {
    // tslint:disable-next-line
    if (this.selectedRegion.oid === -1 || (this.selectedRegion && this.originalCheckSum !== '' && this.appUtilities.createCheckSum(this.selectedRegion) !== this.originalCheckSum)
    ) {
      const res: string = await this.appUtilities.showUnsavedChangesSwal();
      if (res === CONSTANTS.SWAL_RESPONSE.YES) {
        await this.saveUpdateRegion();
        return true;
      } else if (res === CONSTANTS.SWAL_RESPONSE.NO) {
        this.regions = JSON.parse(JSON.stringify(this.masterRegionsList));
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  async selectRegion(oid: number, newRegion?): Promise<void> {
    if (oid !== -1) {
      this.selectedRegion = await this.pdsService.getRegionDTOByOid(oid).toPromise();
    } else {
      this.selectedRegion = newRegion;
    }
    this.originalCheckSum = this.appUtilities.createCheckSum(this.selectedRegion);
  }

  async saveUpdateRegion(): Promise<void> {
    const isInvalidString: string = this.isInvalid();
    if (isInvalidString && isInvalidString !== '') {
      this.isSubmitted = true;
      // this.savingRegion = false;
      const myhtml = document.createElement('div');
      myhtml.innerHTML = isInvalidString;
      SwalDialog.show({
        title: 'Validation Failed',
        content: myhtml,
        icon: 'error',
      });
      return;
    } else {
      this.isSubmitted = true;
      this.saving = true;
      try {
        if (this.selectedRegion.oid === -1) {
          this.selectedRegion.oid = null;
        }
        const response = await this.pdsService.addUpdateRegionDTO(this.selectedRegion).toPromise();
        this.saving = false;
        this.toast.success('Successfully Saved');
        const savedOid: number = response.oid;
        await this.getRegions();
        this.showPreferenceChange(this.showPreference);
        const foundRegion = this.regions.find(m => m.oid === savedOid);
        if (foundRegion) {
          this.selectRegion(foundRegion.oid);
        } else {
          this.selectRegion(this.regions[0].oid);
        }
        this.originalCheckSum = this.appUtilities.createCheckSum(this.selectedRegion);
      } catch (error) {
        this.saving = false;
        this.appUtilities.showServerErrorSwal(error.message);
      }
    }
  }

  isInvalid(): string {
    let rString: string = '';
    if (!this.selectedRegion.name || this.selectedRegion.name === '') {
      rString = rString.concat('Must have a Region name to save </br>');
    }
    return rString;
  }

  async addDairyModal(): Promise<void> {
    const dairyModal: NgbModalRef = this.modalService.open(AddDairyModalComponent, { backdrop: 'static' });
    dairyModal.componentInstance.existingDairies = this.selectedRegion.dairies;
    dairyModal.result.then((result) => { }, (reason) => {
      if (reason) {
        this.selectedRegion.dairies = reason;
      }
    });
  }


  selectDairy(index, oid): void {
    if (+oid === +-2) {
      this.dairies.filter(dairy => {
        if (this.selectedRegion.dairies.find(sDairy => sDairy.oid === dairy.oid)) {
          return;
        } else {
          this.selectedRegion.dairies.push(dairy);
        }
      });
      this.selectedRegion.dairies.splice(index, 1);
    } else {
      const foundDairy: DairyDTO = this.dairies.find(d => +d.oid === +oid);
      if (foundDairy) {
        this.selectedRegion.dairies[index] = foundDairy;

      }
    }
  }

  removeDairy(index) {
    this.selectedRegion.dairies.splice(index, 1);
  }

  addDairyLineItem() {
    const oidList = this.selectedRegion.dairies.map(d => d.oid);
    this.availableDairies = this.dairies.filter(dairy => {
      if (oidList.indexOf(dairy.oid) === -1) {
        return dairy;
      }
    });
    this.availableDairies.sort();
    if (!this.availableDairies || this.availableDairies.length < 1) {
      const response = SwalDialog.show({
        title: 'No Available Dairies',
        text: 'There are no available dairies to add to this region.',
        icon: 'warning',
        dangerMode: true,
      });
      return;
    } else {
      const newDairy: DairyDTO = new DairyDTO;
      newDairy.oid = -1;
      this.selectedRegion.dairies.splice(0, 0, newDairy);
    }
  }

  addManagerLine() {
    const oidList = this.selectedRegion.managers.map(m => m.oid);
    this.availableManagers = this.nutritionists.filter(manager => {
      if (oidList.indexOf(manager.oid) === -1) {
        return manager;
      }
    });
    this.availableManagers.sort(function(a, b) {
      var textA = a.alphaName.toUpperCase();
      var textB = b.alphaName.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
    if (!this.availableManagers || this.availableManagers.length < 1) {
      const response = SwalDialog.show({
        title: 'No Available Managers',
        text: 'There are no available managers to add to this region.',
        icon: 'warning',
        dangerMode: true,
      });
      return;
    } else {
      const newManager: NutritionistDTO = new NutritionistDTO();
      newManager.oid = -1;
      this.selectedRegion.managers.splice(0,0,newManager);
    }
  }

  selectManager(index, oid): void {
    const foundManager: NutritionistDTO = this.nutritionists.find(d => +d.oid === +oid);
    if (foundManager) {
      this.selectedRegion.managers[index] = foundManager;
    }
  }

  removeManager(index) {
    this.selectedRegion.managers.splice(index, 1);
  }

  async cancel(): Promise<void> {
    this.isSubmitted = false;
    const currentOid: number = this.selectedRegion.oid;
    await this.getRegions();
    const foundRegion: ValuePair = this.regions.find(f => f.oid === currentOid);
    if (foundRegion) {
      this.selectRegion(foundRegion.oid);
    } else {
      this.selectRegion(this.regions[0].oid);
    }
  }

  showPreferenceChange(event): void {
    if (!this.masterRegionsList.length) {
      this.masterRegionsList = this.regions;
    }
    this.showPreference = event;
    if (this.showPreference === 'Active') {
      this.regions = this.masterRegionsList.filter(item => {
        return item.isActive;
      });
    } else if (this.showPreference === 'Inactive') {
      this.regions = this.masterRegionsList.filter(item => {
        return !item.isActive;
      });
    } else {
      this.regions = JSON.parse(JSON.stringify(this.masterRegionsList));
    }
    if (this.regions.length) {
      if (this.regions.find(r => r.oid === this.selectedRegion.oid)) {
        this.selectRegion(this.selectedRegion.oid);
      } else {
        this.selectRegion(this.regions[0].oid);
      }
    } else {
    }
  }

  navToDairy(dairy) {
    this.router.navigate([`/dairies/${UrlObfuscationService.obfuscate(dairy.oid)}`]);
  }

  navToManager(manager) {
    // TODO: Do these go to the users page or worksheet?
    this.router.navigate([`/users/${UrlObfuscationService.obfuscate(manager.oid)}`]);
  }

}

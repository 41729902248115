import { Component, OnInit } from '@angular/core';
import {CONSTANTS} from '../../lib/constants';
import {MonthCloseState, MonthEndService, PDSService, ProductionVsPlan} from '../../api/generated-api';
import {ActivatedRoute} from '@angular/router';
import {AppUtilities} from "../../lib/appUtilities.service";

@Component({
  selector: 'monthly-dairy-production-vs-plan',
  templateUrl: './monthly-dairy-production-vs-plan.component.html',
  styleUrls: ['./monthly-dairy-production-vs-plan.component.css']
})
export class MonthlyDairyProductionVsPlanComponent implements OnInit {

  public monthString: string = '';
  public months: Array<{ nMonth: number, sMonth: string }> = CONSTANTS.MONTHS_SELECT_LIST;
  public years: Array<number> = CONSTANTS.YEARS;
  public selectedMonth: number;
  public selectedYear: number;
  public monthCloseState: MonthCloseState = <MonthCloseState>{};
  public plans: ProductionVsPlan[] = [];
  public appUtilities: AppUtilities = new AppUtilities();
  // public userSubscription: Subscription;



  constructor(private pds: PDSService, private monthEnd: MonthEndService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.setClosedState();
    // this.getParamSubscription();
    this.initiate();
  }

  async initiate() {
    await this.setClosedState();
    await this.getDeliveryDetail();
  }

  // async getParamSubscription(): Promise<void> {
  //   this.userSubscription = this.activatedRoute.params.subscribe(async (param: {id: string}) => {
  //     if (param.id) {
  //       const foundNutritionist = +UrlObfuscationService.deobfuscate(param.id);
  //       this.activeNutritionistOid = foundNutritionist;
  //     }
  //   });
  // }

  async setClosedState() {
    this.monthCloseState = await this.monthEnd.getMonthCloseState().toPromise();
    this.selectedMonth = this.monthCloseState.currentMonth;
    this.selectedYear = this.monthCloseState.currentYear;
    this.setMonthString();
  }

  selectDate() {
    this.setMonthString();
    this.getDeliveryDetail();
  }

  setMonthString() {
    this.monthString = this.months.find(month => +month.nMonth === +this.selectedMonth).sMonth;
  }

  async getDeliveryDetail() {
    const res = await this.pds.getProductionVsPlanSnapShot(this.selectedMonth, this.selectedYear).toPromise();
    if(res.hasErrors) {
      this.plans = [];
      await this.appUtilities.showServerErrorSwal(res.errorMessage);
    } else {
      this.plans = res.data.plans;
    }
    // const response = await this.pds.getNutritionistsDeliveriesByMonthAndYear(this.activeNutritionistOid, this.selectedMonth, this.selectedYear).toPromise();
    // this.details = response;
  }

  getTotalByColumn(columnName: string) {
    return AppUtilities.addByColumn(this.plans, columnName);
  }

}

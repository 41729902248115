import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './pages/login/login.component';
import { MainComponent } from './components/main/main.component';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { RegisterComponent } from './pages/register/register.component';
import { FeedsComponent } from './pages/feeds/feeds.component';
import { DairiesComponent } from './pages/dairies/dairies.component';
import { MillsComponent } from './pages/mills/mills.component';
import { MonthlyProductionComponent } from './pages/monthly-production/monthly-production.component';
import { MonthlyFeedProductionComponent } from './pages/monthly-feed-production/monthly-feed-production.component'; // tslint:disable-line
import { NutritionistWorksheetComponent } from './pages/nutritionist-worksheet/nutritionist-worksheet.component';
import { ProductsComponent } from './pages/products/products.component';
import { RegionsComponent } from './pages/regions/regions.component';
import { UsersComponent } from './pages/users/users.component';
import { AddMonthlyProductionModalComponent } from './components/add-monthly-production-modal/add-monthly-production-modal.component';
import { AddDairyModalComponent } from './components/add-dairy-modal/add-dairy-modal.component';
import { AddFeedPlanModalComponent } from './components/add-feed-plan-modal/add-feed-plan-modal.component';
import { AddNutritionistsModalComponent } from './components/add-nutritionists-modal/add-nutritionists-modal.component';
import { AddNutritionistsWorksheetModalComponent } from './components/add-nutritionists-worksheet-modal/add-nutritionists-worksheet-modal.component'; // tslint:disable-line
import { AddNutritionistsWorksheetAdjustmentModalComponent } from './components/add-nutritionists-worksheet-adjustment-modal/add-nutritionists-worksheet-adjustment-modal.component'; // tslint:disable-line
import { AddFeedProductMixModalComponent } from './components/add-feed-product-mix-modal/add-feed-product-mix-modal.component';
import { AddRegionModalComponent } from './components/add-region-modal/add-region-modal.component';
import { JWTService } from './lib/jwt.service';
import { AuthGuardService } from './lib/authguard.service';
import { AddHeaderInterceptor } from './lib/interceptor.service';
import { StandardUserComponent } from './pages/users/standard-user/standard-user.component';
import { MillUserComponent } from './pages/users/mill-user/mill-user.component';
import { NutritionistUserComponent } from './pages/users/nutritionist-user/nutritionist-user.component';
import { UserService } from './services/user.service';
import { LookupService } from './services/lookup.service';
import { SideBarListComponent } from './components/side-bar-list/side-bar-list.component';
import { FilterByPipe } from './components/pipes/filter-by-pipe';
import { ArraySortPipe } from './components/pipes/sort.pipe';
import { FilterByFlexStringPipe } from './components/pipes/filter-by-flex-string-pipe';
import { AppUtilities } from './lib/appUtilities.service';
import { DateUtilities } from './lib/dateUtilities.service';
import { CanDeactivateGuard } from './lib/canDeactivate.service';
import { CwGridModule } from './components/cw-grid/cw-grid.module';
import { ColumnResizingService } from '@progress/kendo-angular-grid';
import { AddEditFeedDeliveryModalComponent } from './components/add-edit-feed-delivery/add-edit-feed-delivery-modal.component';
import { FeedDeliveryEntryComponent } from './pages/feed-delivery-entry/feed-delivery-entry.component';
import {MonthEndComponent} from './pages/month-end/month-end.component';
import {NgDatepickerModule} from 'ng2-datepicker';
import {AddProductModalComponent} from './components/add-product-modal/add-product-modal.component';
import {CompAdjustmentsModalComponent} from './components/comp-adjustments-modal/comp-adjustments-modal.component';
import {
  EntityService, PDSService, CWGridService, AuthenticationService,
  MonthEndService, AnalyticsService, API_BASE_URL, ReportsService, ClientResult
} from './api/generated-api';
import {AddEditNutritionistAdjustmentsComponent} from './components/add-edit-nutritionist-adjustments-modal/add-edit-nutritionist-adjustments.component';// tslint:disable-line
import {NotificationsComponent} from './pages/notifications/notifications.component';
import {ViewByDairyComponent} from './pages/view-by-dairy/view-by-dairy.component';
import {ViewByNutritionistComponent} from './pages/view-by-nutritionist/view-by-nutritionist.component';
import {ViewByMillComponent} from './pages/view-by-mill/view-by-mill.component';
import {MappingComponent} from './pages/mapping/mapping.component';
import {IncompleteSetupComponent} from './pages/incomplete-setup/incomplete-setup.component';
import {NutritionistPayablesComponent} from './pages/nutritionist-payables/nutritionist-payables.component';
import { AddEditCompensationModalComponent } from './components/add-edit-compensation-modal/add-edit-compensation-modal.component';
import {PdsGrossProfitComponent} from './pages/pds-gross-profit/pds-gross-profit.component';
import { FeedRecipeAdjustmentModal } from './components/feed-recipe-adjustment-modal/feed-recipe-adjustment-modal.component';
import {ViewProductsComponent} from './pages/view-products/view-products.component';
import {environment} from '../environments/environment';
import {NgxCurrencyModule} from 'ngx-currency';
import {PopupModule} from '@progress/kendo-angular-popup';
import {UiSwitchModule} from 'ngx-toggle-switch';
import { UsersService } from './pages/users/users.service';
import { SelectUserTypeModalComponent } from './components/select-user-type-modal/select-user-typecomponent';
import { CwGridPreferenceComponent } from './components/cw-grid/cw-grid-preference.component';
import {YtdMtdComponent} from "./pages/ytd-mtd/ytd-mtd.component";
import {CowCountComponent} from "./pages/cow-count/cow-count.component";
import {MonthlyDairyProductionComponent} from "./pages/monthly-dairy-production/monthly-dairy-production.component";
import {MonthlyMillProductionComponent} from "./pages/monthly-mill-production/monthly-mill-production.component";
import {MonthlyNutritionistProductionComponent} from "./pages/monthly-nutritionist-production/monthly-nutritionist-production.component";
import {NutritionistDeliveryDetailComponent} from "./pages/nutritionist-delivery-detail/nutritionist-delivery-detail.component";
import {MonthlyDairyProductionVsPlanComponent} from "./pages/monthly-dairy-production-vs-plan/monthly-dairy-production-vs-plan.component";
import {NutritionistProductionSummaryComponent} from "./pages/nutritionist-production-summary/nutritionist-production-summary.component";
import {CloneFeedModalComponent} from "./components/clone-feed-modal/clone-feed-modal.component";
import {CowCountByMonthComponent} from "./pages/cow-count-by-month/cow-count-by-month.component";
import {ProfitBreakdownModalComponent} from "./components/profit-breakdown-modal/profit-breakdown-modal.component";
import {ConfirmSealModalComponent} from "./components/confirm-seal-modal/confirm-seal-modal.component";
import {MillDeliveryDetailComponent} from "./pages/mill-delivery-detail/mill-delivery-detail.component";
import { ChangepasswordModal } from './components/change-password-modal/change-password-modal.component';
import {ProductUtilizationComponent} from "./pages/ProductUtilization/productUtilization.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    LoginComponent,
    MainComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    FeedsComponent,
    DairiesComponent,
    MillsComponent,
    MonthlyProductionComponent,
    MonthlyFeedProductionComponent,
    FeedDeliveryEntryComponent,
    NutritionistWorksheetComponent,
    ProductsComponent,
    RegionsComponent,
    UsersComponent,
    FilterByPipe,
    ArraySortPipe,
    FilterByFlexStringPipe,
    AddMonthlyProductionModalComponent,
    ChangepasswordModal,
    AddEditFeedDeliveryModalComponent,
    AddEditCompensationModalComponent,
    AddDairyModalComponent,
    AddFeedPlanModalComponent,
    AddNutritionistsModalComponent,
    SideBarListComponent,
    AddNutritionistsWorksheetModalComponent,
    AddNutritionistsWorksheetAdjustmentModalComponent,
    AddFeedProductMixModalComponent,
    AddRegionModalComponent,
    StandardUserComponent,
    MillUserComponent,
    NutritionistUserComponent,
    MonthEndComponent,
    AddProductModalComponent,
    SelectUserTypeModalComponent,
    FeedRecipeAdjustmentModal,
    CompAdjustmentsModalComponent,
    AddEditNutritionistAdjustmentsComponent,
    NotificationsComponent,
    ViewByMillComponent,
    ViewByDairyComponent,
    ViewByNutritionistComponent,
    MappingComponent,
    IncompleteSetupComponent,
    NutritionistPayablesComponent,
    PdsGrossProfitComponent,
    ViewProductsComponent,
    YtdMtdComponent,
    CowCountComponent,
    MonthlyDairyProductionComponent,
    MonthlyMillProductionComponent,
    MonthlyNutritionistProductionComponent,
    NutritionistDeliveryDetailComponent,
    MonthlyDairyProductionVsPlanComponent,
    NutritionistProductionSummaryComponent,
    CloneFeedModalComponent,
    CowCountByMonthComponent,
    ProfitBreakdownModalComponent,
    ConfirmSealModalComponent,
    MillDeliveryDetailComponent,
    ProductUtilizationComponent
  ],

  entryComponents: [
    AddMonthlyProductionModalComponent,
    MonthlyFeedProductionComponent,
    AddDairyModalComponent,
    FeedRecipeAdjustmentModal,
    AddFeedPlanModalComponent,
    SelectUserTypeModalComponent,
    AddNutritionistsModalComponent,
    AddNutritionistsWorksheetModalComponent,
    AddNutritionistsWorksheetAdjustmentModalComponent,
    AddFeedProductMixModalComponent,
    ChangepasswordModal,
    AddRegionModalComponent,
    AddEditFeedDeliveryModalComponent,
    CwGridPreferenceComponent,
    AddProductModalComponent,
    CompAdjustmentsModalComponent,
    AddEditNutritionistAdjustmentsComponent,
    AddEditCompensationModalComponent,
    CloneFeedModalComponent,
    ProfitBreakdownModalComponent,
    ConfirmSealModalComponent,
    MillDeliveryDetailComponent


  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgbModule,
    CwGridModule,
    PopupModule,
    ToastrModule.forRoot(),
    NgDatepickerModule,
    NgxCurrencyModule,
    UiSwitchModule
  ],
  providers: [
    { provide: API_BASE_URL, useValue: environment.apiUrl },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },
    AuthGuardService,
    CanDeactivateGuard,
    JWTService,
    UserService,
    LookupService,
    AppUtilities,
    DateUtilities,
    ColumnResizingService,
    EntityService,
    PDSService,
    CWGridService,
    AuthenticationService,
    MonthEndService,
    AnalyticsService,
    UsersService,
    ReportsService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

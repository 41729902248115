import { Component, OnInit } from '@angular/core';
import {CONSTANTS} from '../../lib/constants';
import {MonthEndService, PDSService, Entity} from '../../api/generated-api';
import {Observable, Subscription} from "rxjs/index";
import {UrlObfuscationService} from "../../lib/obfuscate.service";
import {ActivatedRoute, Router} from '@angular/router';
import {AppUtilities} from "../../lib/appUtilities.service";
import {UserService} from "../../services/user.service";
import {NutritionistsDelivery} from "../../api/generated/models/nutritionists-delivery";

@Component({
  selector: 'nutritionist-delivery-detail',
  templateUrl: './nutritionist-delivery-detail.component.html',
  styleUrls: ['./nutritionist-delivery-detail.component.css']
})
export class NutritionistDeliveryDetailComponent implements OnInit {

  public details: NutritionistsDelivery[] = [];
  public monthString: string = '';
  public months: Array<{ nMonth: number, sMonth: string }> = CONSTANTS.MONTHS_SELECT_LIST;
  public years: Array<number> = CONSTANTS.YEARS;
  public selectedMonth: number;
  public selectedYear: number;
  public userSubscription: Subscription;
  public activeNutritionistOid: number;
  public constNutritionist: string = CONSTANTS.SECURITY_GROUPING.NUTRITIONIST;
  public constSuper: string = CONSTANTS.SECURITY_GROUPING.SUPERUSER;
  public constAdmin: string = CONSTANTS.SECURITY_GROUPING.ADMIN;
  public loggedInEntity: Entity = <Entity>{};
  public appUtilities: AppUtilities = new AppUtilities();



  constructor(private pds: PDSService,
              private monthEnd: MonthEndService,
              private activatedRoute: ActivatedRoute,
              private userService: UserService,) { }

  ngOnInit() {
    this.getParamSubscription();
    this.initiate();
  }

  async initiate() {
    await this.getLoggedInEntity();
    await this.setClosedState();
    await this.getDeliveryDetail();
  }

  async getLoggedInEntity(): Promise<void> {
    this.loggedInEntity = await this.userService.getLoggedInEntityUser();
  }

  async getParamSubscription(): Promise<void> {
    this.userSubscription = this.activatedRoute.params.subscribe(async (param: {id: string}) => {
      if (param.id) {
        const foundNutritionist = +UrlObfuscationService.deobfuscate(param.id);
        this.activeNutritionistOid = foundNutritionist;
      }
    });
  }

  async setClosedState() {
    const response = await this.userService.getMonthCloseState();
    if(this.isNotAdmin()) {
      if(response.currentMonth === 1) {
        this.selectedMonth = 12;
        this.selectedYear = response.currentYear -1;
      } else {
        this.selectedMonth = response.currentMonth - 1;
        this.selectedYear = response.currentYear;
      }
    } else {
      this.selectedMonth = response.currentMonth;
      this.selectedYear = response.currentYear;
    }
    // const paramMonth = this.activatedRoute.snapshot.queryParams.month;
    // const paramYear = this.activatedRoute.snapshot.queryParams.year;
    // this.selectedYear = paramYear;
    // this.selectedMonth = paramMonth;
    this.setMonthString();
  }

  isNotAdmin() {
    if(this.loggedInEntity.securityGroupName != this.constSuper || this.loggedInEntity.securityGroupName != this.constAdmin) {
      return true;
    }
  }

  selectDate() {
    this.setMonthString();
    this.getDeliveryDetail();
  }

  setMonthString() {
    this.monthString = this.months.find(month => +month.nMonth === +this.selectedMonth).sMonth;
  }

  async getDeliveryDetail() {
    // console.log('look here')
    const res = await this.pds.getNutritionistsDeliveriesByMonthAndYear(this.activeNutritionistOid, this.selectedMonth, this.selectedYear).toPromise();
    if(res.hasErrors) {
      this.details = [];
      await this.appUtilities.showServerErrorSwal(res.errorMessage);
    } else {
      this.details = res.data;
    }

  }

  getTotalByColumn(columnName: string) {
    return AppUtilities.addByColumn(this.details, columnName);
  }

  public isAShareholder() {
    if(this.loggedInEntity.securityGroupName === this.constSuper || this.loggedInEntity.securityGroupName === this.constAdmin || (this.loggedInEntity.securityGroupName === this.constNutritionist && this.loggedInEntity.isShareholder)) {
      return true;
    } else {
      return false;
    }
  }


}

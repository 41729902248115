import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'filterBy', pure: true})

export class FilterByPipe implements PipeTransform {
  transform(items: any[], filterValue: Object, filterProperty: any, notEqual?: boolean): any {
      if (!items || !filterValue) {
          return items;
      }
      if (notEqual) {
        return items.filter(item => item[filterProperty].toString() !== filterValue.toString());
      } else {
        return items.filter(item => item[filterProperty] && item[filterProperty].toString() === filterValue.toString());
      }
  }
}

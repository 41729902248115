import { FormGroup, AbstractControl, FormControl } from '@angular/forms';
import { CwGridDefinition } from './cw-grid-common';

export class FormGroupConverter {

    public static convertToForm(grid: CwGridDefinition, dataItem: any) {
        const fields: { [key: string]: AbstractControl } = {};

        // tslint:disable-next-line: forin
        for (const columnName in grid.columns) {
            const control = new FormControl(dataItem[columnName]);
            fields[columnName] = control;
        }

        return new FormGroup(fields);
    }

    public static updateDataItem(dataItem: any, form: FormGroup): void {
        const formValue = form.value;
        Object.assign(dataItem, formValue);
    }

}

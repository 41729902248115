import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AppUtilities } from '../../lib/appUtilities.service';
import { CONSTANTS } from '../../lib/constants';
import { CompSummaryDTO, NutritionistsCalcDTO, PDSService, NutritionistDTO,
  MonthEndService, Entity, DairyProductionGridRecord, MonthCloseState, Compensation
} from '../../api/generated-api'; // tslint:disable-line
import { UserService } from '../../services/user.service';
import { AddEditCompensationModalComponent } from 'src/app/components/add-edit-compensation-modal/add-edit-compensation-modal.component';
import { LookupService } from 'src/app/services/lookup.service';
import { CwGridComponent } from 'src/app/components/cw-grid/cw-grid.component';
import {Subscription} from "rxjs/index";
import {ActivatedRoute, Router} from "@angular/router";
import {UrlObfuscationService} from "../../lib/obfuscate.service";

@Component({
  selector: 'nutritionist-worksheet',
  templateUrl: './nutritionist-worksheet.component.html',
  styleUrls: ['./nutritionist-worksheet.component.scss']
})
export class NutritionistWorksheetComponent implements OnInit {

  @ViewChild('monthlyProductionGridNutritionist', null) productionParentGrid: CwGridComponent;
  @ViewChild('CompGrid', null) CompGrid: CwGridComponent;

  public selectedMonth: number;
  public currentMonth: number;
  public currentYear: number;
  public selectedYear: number;
  public monthList: Array<{ nMonth: number, sMonth: string }> = CONSTANTS.MONTHS_SELECT_LIST;
  public years: number[] = CONSTANTS.YEARS;
  public monthCloseState: MonthCloseState;
  public pageLoading: boolean = false;
  public nutritionists: Array<NutritionistDTO> = [];
  public masterList: Array<NutritionistDTO> = [];
  public selectedNutritionist: NutritionistDTO = <NutritionistDTO>{};
  public worksheetGridData: NutritionistsCalcDTO = <NutritionistsCalcDTO>{};
  public productionData: DairyProductionGridRecord[] = [];
  public compensationData: CompSummaryDTO[] = [];
  public originalCheckSum: string = '';
  public linesToDelete: CompSummaryDTO[] = [];
  public currentRunningTotal: number = 0;
  public constFlat: number;
  public constPercentage: number;
  public constSuper: string = CONSTANTS.SECURITY_GROUPING.SUPERUSER;
  public constAdmin: string = CONSTANTS.SECURITY_GROUPING.ADMIN;
  public constNutritionist: string = CONSTANTS.SECURITY_GROUPING.NUTRITIONIST;
  public loggedInEntity: Entity = <Entity>{};
  public monthString: string = '';
  public totalProfit: number;
  public userSubscription: Subscription;
  public showPreference: string = 'Active';
  public appUtil: AppUtilities = new AppUtilities();
  public pdfLoading: boolean = false;

  constructor(private modalService: NgbModal,
    private pdsService: PDSService,
    private appUtilities: AppUtilities,
    private lookup: LookupService,
    private monthEndService: MonthEndService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
      this.constFlat = this.lookup.findLookupOidByConstantValue('AMOUNTTYPE->FLAT');
      this.constPercentage = this.lookup.findLookupOidByConstantValue('AMOUNTTYPE->PERCENTAGE');
    }

  async ngOnInit(): Promise<void> {
    this.pageLoading = true;
    this.monthCloseState = await this.monthEndService.getMonthCloseState().toPromise();
    await this.getLoggedInUser();
    await this.getNutritionists();
    this.initiate();
  }

  async initiate(): Promise<void> {
    if (this.nutritionists[0] && this.loggedInEntity.securityGroupName === this.constSuper) {
      this. nutritionists = this.masterList.filter(n => {
        return n.isActive;
      });
      this.selectedNutritionist = this.nutritionists[0];
    } else if (this.nutritionists[0] && this.loggedInEntity.securityGroupName === this.constNutritionist) {
      this.selectedNutritionist = this.nutritionists.find(n => n.oid === this.loggedInEntity.oid);
    }
    await this.getCurrentMonthYear();
    await this.getNutritionistWorksheet();
    // this.getParamSubscription();
    this.pageLoading = false;
  }

  async downloadPdf() {
    this.pdfLoading = true;
   const res = await this.pdsService.generateNutritionistPDF(this.selectedNutritionist.oid, this.selectedMonth, this.selectedYear).toPromise();
   if(res && res.data && res.data != "") {
     this.openFileInNewTab(res.data);
   } else {
     await this.appUtil.showServerErrorSwal(res.errorMessage);
   }
   this.pdfLoading = false;
  }

  openFileInNewTab(url): void {
    window.open(url);
}

  getParamSubscription(): void {
    this.userSubscription = this.activatedRoute.params.subscribe(async (param: {id: string}) => {
      if (param.id) {
        const foundActiveNutritionist = this.nutritionists.find(n => n.oid === +UrlObfuscationService.deobfuscate(param.id));
        this.selectedNutritionist = foundActiveNutritionist;
        this.getNutritionistWorksheet();
      } else {
        if(this.nutritionists.length > 0){
          await this.router.navigate([`/nutritionist-worksheet/${UrlObfuscationService.obfuscate(this.nutritionists[0].oid)}`]);
        }
      }
    });
  }

  async getLoggedInUser(): Promise<void> {
    this.loggedInEntity = await this.userService.getLoggedInEntityUser();
    // console.log(this.loggedInEntity, 'logged in')
  }

  async getCurrentMonthYear(): Promise<void> {
    const response = await this.userService.getMonthCloseState();
    if(this.loggedInEntity.securityGroupName === this.constNutritionist) {
      if(response.currentMonth === 1) {
        this.selectedMonth = 12;
        this.currentMonth = 12;
        this.selectedYear = response.currentYear -1;
        this.currentYear = response.currentYear -1;
      } else {
        this.selectedMonth = response.currentMonth - 1;
        this.currentMonth = response.currentMonth -1;
        this.selectedYear = response.currentYear;
        this.currentYear = response.currentYear;
      }
    } else {
      this.selectedMonth = response.currentMonth;
      this.currentMonth = response.currentMonth;
      this.selectedYear = response.currentYear;
      this.currentYear = response.currentYear;
    }
    this.setMonthString();
  }

  async getNutritionistWorksheet(): Promise<void> {
    try {
      const res = await this.pdsService.getNutritionistCalc(this.selectedNutritionist.oid, this.selectedMonth, this.selectedYear).toPromise(); // tslint:disable-line
      if(res.hasErrors) {
        this.productionData = [];
        await this.appUtil.showServerErrorSwal(res.errorMessage);
      } else {
        this.worksheetGridData = res.data;
        this.compensationData = this.worksheetGridData.compSummary;
        this.productionData = this.worksheetGridData.productionData.dairies;
        this.monthCloseState = this.worksheetGridData.monthCloseState;
        this.originalCheckSum = this.appUtilities.createCheckSum(this.worksheetGridData);
      }
    } catch (error) {
      this.appUtil.showDataErrorSwal(error.message);
    }
  }

  async getNutritionists(): Promise<void> {
    this.nutritionists = await this.pdsService.getAllNutritionistDTOs().toPromise();
    this.masterList = JSON.parse(JSON.stringify(this.nutritionists));
  }

  calculateAdjustmentTotals() {
    this.currentRunningTotal = 0;
    for (const adj of this.compensationData) {
      adj.runningTotal = (adj.grossPay + this.currentRunningTotal);
      this.currentRunningTotal = adj.runningTotal;
    }
  }

  calculateGrossPay() {
    for (const adj of this.compensationData) {
      if (adj.lkpAmountTypeOid === this.constFlat) {
        adj.grossPay = adj.amount;
      }
      if (adj.lkpAmountTypeOid === this.constPercentage) {
        adj.grossPay = (adj.amount * adj.grossProfit);
      }
    }
    this.calculateAdjustmentTotals();
  }

  async removeAdjustment(obj, index: number) {
    await this.pdsService.deleteCompSummaryDTOs([obj]).toPromise();
    this.compensationData.splice(index, 1);
  }

  getTotalByColumn(columnName: string, data?) {
    return AppUtilities.addByColumn(data.deliveries, columnName);
  }

  getParentTotalyByColumn(columnName: string, data?) {
    if (columnName === 'totalProfit_Nutritionist') {
      this.totalProfit = AppUtilities.addByColumn(data, columnName)
    }
    return AppUtilities.addByColumn(data, columnName);
  }

  getAdjustmentTotalByColumn(columnName: string) {
    return AppUtilities.addByColumn(this.compensationData, columnName);
  }

  selectDate() {
    this.setMonthString();
    this.getNutritionistWorksheet();
  }

  async selectNutritionist(oid: number): Promise<void> {
    // this.router.navigate([`/nutritionist-worksheet/${UrlObfuscationService.obfuscate(oid)}`]);
      const foundNutritionist = this.nutritionists.find(f => f.oid === oid);
      this.selectedNutritionist = foundNutritionist;
      this.getNutritionistWorksheet();
  }


  addEditCompensationModal(summary?: CompSummaryDTO) {
    const compModal: NgbModalRef = this.modalService.open(AddEditCompensationModalComponent, {backdrop: 'static'});
    //compModal.componentInstance.totalProfit = this.worksheetGridData.monthlyGrossProfit;
    if (summary) {
      const newComp = this.convertCompSummaryToCompensation(summary);
      compModal.componentInstance.compensation = newComp;
      compModal.componentInstance.grossProfit = this.totalProfit;
    } else {
      compModal.componentInstance.grossProfit = this.totalProfit;
      compModal.componentInstance.nutritionistOid = this.selectedNutritionist.oid;
    }
    compModal.result.then((result) => { }, (reason) => {
      if (reason) {
        this.getCompSummaryDTOS();
      }
    });
  }

  async getCompSummaryDTOS() {
    const res = await this.pdsService.getCompSummaryDTOList(this.selectedNutritionist.oid, this.currentMonth, this.currentYear).toPromise();
    this.worksheetGridData.compSummary = res;
    this.compensationData = this.worksheetGridData.compSummary;
    this.originalCheckSum = this.appUtilities.createCheckSum(this.worksheetGridData);
  }

  convertCompSummaryToCompensation(summary: CompSummaryDTO): Compensation {
    const newComp: Compensation = <Compensation>{};
    newComp.oid = summary.oid;
    newComp.description = summary.description;
    newComp.lkpAmountTypeOid = summary.lkpAmountTypeOid;
    if (newComp.lkpAmountTypeOid === this.constFlat) {
      newComp.flatAmount = summary.amount;
    } else {
      newComp.mathPercentage =  summary.amount;
    }
    newComp.reportedMonth = this.monthCloseState.currentMonth;
    newComp.reportedYear = this.monthCloseState.currentYear;
    return newComp;
  }

  isNutritionistAndCannotViewCurrent(): boolean {
    if (this.loggedInEntity && this.loggedInEntity.securityGroupName === this.constNutritionist
      && +this.selectedMonth === +this.monthCloseState.currentMonth
      && +this.selectedYear === +this.monthCloseState.currentYear
      && this.monthCloseState.isCurrentCloseInProgress) {
      return true;
    } else {
      return false;
    }
  }

  canViewGrossProfit(): boolean {
    if (this.loggedInEntity && ( this.loggedInEntity.isShareholder || this.loggedInEntity.securityGroupName === this.constSuper || this.loggedInEntity.securityGroupName === this.constAdmin)) {
      return true;
    }
    return false;
  }

  setMonthString() {
    this.monthString = this.monthList.find(month => +month.nMonth === +this.selectedMonth).sMonth;
  }

  showPreferenceChange(event): void {
    if (!this.masterList.length) {
      this.masterList = this.nutritionists;
    }
    this.showPreference = event;
    if (this.showPreference === 'Active') {
      this.nutritionists = this.masterList.filter(item => {
        return item.isActive;
      });
    } else if (this.showPreference === 'Inactive') {
      this.nutritionists = this.masterList.filter(item => {
        return !item.isActive;
      });
    } else {
      this.nutritionists = JSON.parse(JSON.stringify(this.masterList));
    }
    if (this.nutritionists.length) {
      if (this.nutritionists.find(n => n.oid === this.selectedNutritionist.oid)) {
        this.selectNutritionist(this.selectedNutritionist.oid);
      } else {
        this.selectNutritionist(this.nutritionists[0].oid);
      }
    }
  }


  public isHiddenFromNonShareholder() {
    if(this.loggedInEntity.securityGroupName === this.constSuper || (this.loggedInEntity.securityGroupName === this.constNutritionist && this.loggedInEntity.isShareholder) ) {
      return true;
    } else {
      return false;
    }
  }

}

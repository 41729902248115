import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SecurityGroupObject } from 'src/app/pages/users/users.service';
import { CONSTANTS } from 'src/app/lib/constants';

@Component({
  selector: 'select-user-type',
  templateUrl: './select-user-type.component.html',
  styleUrls: ['./select-user-type.component.css']
})
export class SelectUserTypeModalComponent implements OnInit, OnDestroy {

  public securityGroupOid: number = -1;
  public securityGroups: SecurityGroupObject[] = CONSTANTS.SECURITY_GROUP_DROPDOWN_LIST;

  constructor(public activeModal: NgbActiveModal) { }

  async ngOnInit(): Promise<void> {
    this.securityGroupOid = -1;
  }

  ngOnDestroy(): void {
  }

  select(){
    this.activeModal.dismiss(this.securityGroupOid);
  }

  cancel(){
    this.activeModal.dismiss();
  }


}

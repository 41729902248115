import { Injectable } from '@angular/core';
import { SwalDialog } from './swal-dialog';
import { CONSTANTS } from './constants';
import { ToastrService, Toast } from 'ngx-toastr';

@Injectable()
export class AppUtilities {

    constructor() {
    }

    createCheckSum(pObject: any): string {
        return JSON.stringify(pObject);
    }

    async showUnsavedChangesSwal(): Promise<string> {
        const res: string = await SwalDialog.show({
            dangerMode: false,
            title: CONSTANTS.ERROR.UNSAVED_CHANGES_TITLE,
            text: CONSTANTS.ERROR.UNSAVED_CHANGES_TEXT,
            icon: 'warning',
            buttons: {
                test1: { text: CONSTANTS.SWAL_RESPONSE.YES, value: CONSTANTS.SWAL_RESPONSE.YES },
                test2: { text: CONSTANTS.SWAL_RESPONSE.NO, value: CONSTANTS.SWAL_RESPONSE.NO },
                test3: { text: CONSTANTS.SWAL_RESPONSE.CANCEL, value: CONSTANTS.SWAL_RESPONSE.CANCEL }
            }
        }); // tslint:disable-line
        return res;
    }

    async showServerErrorSwal(errorMessage: string): Promise<boolean> {
        const res: boolean = await SwalDialog.show({
            dangerMode: false,
            title: CONSTANTS.ERROR.SERVER_ERROR_TITLE,
            text: errorMessage ? errorMessage.substring(0, 500) : 'Server unavailable',
            icon: 'warning',
            buttons: {
                ok: true
            }
        }); // tslint:disable-line
        return res;
    }

  async showDataErrorSwal(errorMessage: string): Promise<boolean> {
    const res: boolean = await SwalDialog.show({
      dangerMode: false,
      title: CONSTANTS.ERROR.INVALID_DATA_ERROR,
      text: errorMessage ? errorMessage.substring(0, 500) : 'Server unavailable',
      icon: 'warning',
      buttons: {
        ok: true
      }
    }); // tslint:disable-line
    return res;
  }

    public static addByColumn(list: any[], columnName: string): number { // tslint:disable-line
        if (list && list.length > 0) {
            const totalArray = [];
            list.forEach(item => {
                if (item[columnName]) {
                    if (!isNaN(+item[columnName])) {
                        totalArray.push(+item[columnName]);
                    }
                }
            });
            if (totalArray.length > 0) {
                return totalArray.reduce((a, b) => a + b);
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }

    // public triggerSaveSuccessToaster() {
    //     this.toast.success('Save successful');
    // }

    public static convertDate(date: string): string { // tslint:disable-line
        return date ? date.toString().split('T')[0] : null;
    }

    public static convertToDate(date: string): Date { // tslint:disable-line
        return date ? new Date(date) : null;
    }

}

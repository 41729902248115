import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {SwalDialog} from '../../lib/swal-dialog';
import {
  Entity, Feed, FeedDeliveryProfitBreakdown, FeedDTO, FeedPlanDTO, PDSService,
  ValuePair
} from '../../api/generated-api';
import {CONSTANTS} from "../../lib/constants";

@Component({
  selector: 'profit-breakdown-modal',
  templateUrl: './profit-breakdown-modal.component.html',
  styleUrls: ['./profit-breakdown-modal.component.css']
})
export class ProfitBreakdownModalComponent implements OnInit {

  public feedDeliveryOid: number;
  public feedDelivery: FeedDeliveryProfitBreakdown = <FeedDeliveryProfitBreakdown>{};
  public constMill: string = CONSTANTS.SECURITY_GROUPING.MILL;
  public constNutritionist: string = CONSTANTS.SECURITY_GROUPING.NUTRITIONIST;
  public loggedInUser: Entity = <Entity>{};
  public pageLoading: boolean = false;
  public feedPlanDTO: FeedPlanDTO = <FeedPlanDTO>{};
  public feedOid: number = -1;
  public isSubmitted: boolean = false;
  public dairyOid: number;

  constructor(public activeModal: NgbActiveModal,
              private pdsService: PDSService
  ) {
  }

  async ngOnInit(): Promise<void> {
    // console.log(this.feedDeliveryOid, 'feed oid');
    this.pageLoading = true;
    await this.getProfitBreakdown();
    this.pageLoading = false;
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

  async getProfitBreakdown() {
    const result = await this.pdsService.getFeedDeliveryProfitBreakdown(this.feedDeliveryOid).toPromise();
    // console.log(result, 'result of call');
    // if(result &&result.products.length) {
      this.feedDelivery = result;
    // }
  }

  CanViewColumn() {
    if(this.loggedInUser.securityGroupName === this.constMill || (this.loggedInUser.securityGroupName === this.constNutritionist && !this.loggedInUser.isShareholder)) {
      return false;
    } else{
      return true;
    }
  }
}

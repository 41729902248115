import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { AddDairyModalComponent } from '../../components/add-dairy-modal/add-dairy-modal.component';
import {LookupService} from '../../services/lookup.service';
import {UserService} from '../../services/user.service';
import {AppUtilities} from '../../lib/appUtilities.service';
import {CONSTANTS} from '../../lib/constants';
import {SwalDialog} from '../../lib/swal-dialog';
import {ToastrService} from 'ngx-toastr';
import {Entity, Lookup, PDSService, MillDTO, DairyDTO, Product, ValuePair} from '../../api/generated-api';
import {UrlObfuscationService} from '../../lib/obfuscate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs/index';

@Component({
  selector: 'mills',
  templateUrl: './mills.component.html',
  styleUrls: ['./mills.component.scss']
})
export class MillsComponent implements OnInit {

  @ViewChild("millName", null) millNameEl: ElementRef;

  public mills: MillDTO[] = [];
  // public masterMillList: MillDTO[] = [];
  public selectedMill: MillDTO = <MillDTO>{};
  public savingMill: boolean = false;
  public pageLoading: boolean = false;
  public originalCheckSum: string = '';
  public showPreference: string = 'Active';
  public dairies: DairyDTO[] = [];
  public availableDairies: DairyDTO[] = [];
  public loggedInEntity: Entity = <Entity>{};
  public isLoggedInAsMill: boolean = false;
  // public activeProducts: ValuePair[] = [];
  // public activeFeeds: ValuePair[] = [];
  public userSubscription: Subscription;
  public lkpStates: Lookup[] = [];
  public masterMillsList: ValuePair[] = [];
  public activeMill: MillDTO = <MillDTO>{};
  public millsList: ValuePair[] = [];
  public hasNoMill: boolean = false;
  public isSubmitted: boolean = false;

  constructor(private modalService: NgbModal,
              private pdsService: PDSService,
              private lookup: LookupService,
              private userService: UserService,
              private appUtilities: AppUtilities,
              private toastr: ToastrService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    this.lkpStates = this.lookup.findLookupByLookupName('State');
  }

  async ngOnInit(): Promise<void> {
    await this.initiate();
    this.getParamSubscription();
  }

  async initiate(): Promise<void> {
    await this.getLoggedInUser();
    await this.getAllDairies();
    await this.getAllMills();
    this.millsList = this.millsList.filter(mill => {
      return mill.isActive;
    });
    this.setActiveMill(this.millsList[0].oid);
  }

  async getLoggedInUser(): Promise<void> {
    this.loggedInEntity = await this.userService.getLoggedInEntityUser();
    this.isAdminView();
  }

  async getAllMills() {
    try {
      this.millsList = await this.pdsService.getMillListMapped2LoggedInUser(false).toPromise();
      this.masterMillsList = JSON.parse(JSON.stringify(this.millsList));
    } catch (error) {
      await this.appUtilities.showServerErrorSwal(error);
    }
  }

  async getParamSubscription(): Promise<void> {
    this.userSubscription = this.activatedRoute.params.subscribe(async (param: {id: string}) => {
      if (param.id) {
        const foundActiveMill = this.millsList.find(mill => mill.oid === +UrlObfuscationService.deobfuscate(param.id));
        this.activeMill = await this.pdsService.getMillDTOByOid(foundActiveMill.oid).toPromise();
        this.originalCheckSum = this.appUtilities.createCheckSum(this.activeMill);
      } else {
        await this.router.navigate([`/mills/${UrlObfuscationService.obfuscate(this.millsList[0].oid)}`]);
      }
    });
  }

  async getAllDairies() {
    try {
      this.dairies = await this.pdsService.getAllDairyDTOs().toPromise();
    } catch (error) {
      this.appUtilities.showServerErrorSwal(error.message);
    }
  }

  async setActiveMill(oid: number, newMill?): Promise<void> {
    if (oid !== -1) {
      this.activeMill = await this.pdsService.getMillDTOByOid(oid).toPromise();
    } else {
      this.activeMill = newMill;
    }
    this.originalCheckSum = this.appUtilities.createCheckSum(this.activeMill);
  }

  async selectMill(oid: number): Promise<void> {
    this.router.navigate([`/mills/${UrlObfuscationService.obfuscate(oid)}`]);
  }

  async canDeactivate(): Promise<boolean> {
    let bReturn = false;
    if (this.activeMill.oid === -1 || (this.activeMill && this.originalCheckSum !== '' && this.appUtilities.createCheckSum(this.activeMill) !== this.originalCheckSum)) { // tslint:disable-line
      const res: string = await this.appUtilities.showUnsavedChangesSwal();
      if (res === CONSTANTS.SWAL_RESPONSE.YES) {
        await this.saveUpdateMill();
        bReturn = true;
      } else if (res === CONSTANTS.SWAL_RESPONSE.NO) {
        this.millsList = JSON.parse(JSON.stringify(this.masterMillsList));
        bReturn = true;
      } else {
        bReturn = false;
      }
    } else {
      bReturn = true;
    }
    return bReturn;
  }

  async saveUpdateMill(): Promise<void> {
    this.savingMill = true;
    try {
      if (this.activeMill.oid === -1) {
        this.activeMill.oid = null;
      }
      this.activeMill.availableProducts = this.activeMill.availableProducts.filter(product => {
        return product.isChecked;
      });
      const isInvalidString: string = this.isInvalid();
      if (isInvalidString && isInvalidString !== '') {
        this.isSubmitted = true;
        this.savingMill = false;
        const myhtml = document.createElement('div');
        myhtml.innerHTML = isInvalidString;
        SwalDialog.show({
          title: 'Validation Failed',
          content: myhtml,
          icon: 'error',
        });
        return;
      } else {
        this.isSubmitted = true;
        const response = await this.pdsService.addUpdateMillDTO(this.activeMill).toPromise();
        this.savingMill = false;
        this.toastr.success('Successfully Saved');
        const savedOid: number = response.oid;
        await this.getAllMills();
        this.showPreferenceChange(this.showPreference);
        const foundMill = this.millsList.find(m => m.oid === savedOid);
        if (foundMill) {
          this.setActiveMill(foundMill.oid);
        } else {
          this.setActiveMill(this.millsList[0].oid);
        }
        this.originalCheckSum = this.appUtilities.createCheckSum(this.activeMill);
        // const saveCheck: string = this.checkProductsAndFeeds(this.activeMill);
        // if (saveCheck && saveCheck !== '') {
        //   const checkHtml = document.createElement('div');
        //   checkHtml.innerHTML = saveCheck;
        //   const res = await SwalDialog.show({
        //     title: 'Setup Incomplete',
        //     content: checkHtml,
        //     icon: 'warning',
        //     buttons: {
        //       test1: { text: CONSTANTS.SWAL_RESPONSE.YES, value: CONSTANTS.SWAL_RESPONSE.YES },
        //       test2: { text: CONSTANTS.SWAL_RESPONSE.NO, value: CONSTANTS.SWAL_RESPONSE.NO },
        //     }
        //   });
        //   if (res === CONSTANTS.SWAL_RESPONSE.YES) {
        //     this.router.navigate(['/mapping'])
        //   }
        // }
      }
    } catch (error) {
      this.savingMill = false;
      this.appUtilities.showServerErrorSwal(error.message);
    }
  }

  // checkProductsAndFeeds(mill): string {
  //   let rString: string = '';
  //   if (!mill.availableProducts || !mill.availableProducts.length) {
  //     rString = rString.concat('There are no products assigned to this mill </br>');
  //   }
  //   if (!mill.availableFeeds || !mill.availableFeeds.length) {
  //     rString = rString.concat('There are no feeds assigned to this mill </br>');
  //   }
  //   if (rString !== '') {
  //     rString = rString.concat('Do you wish to add them now?')
  //   }
  //   return rString;
  // }

  isInvalid(): string {
    let rString: string = '';
    if (!this.activeMill.companyName || this.activeMill.companyName === '') {
      rString = rString.concat('Must have a Mill name to save </br>');
    }
    return rString;
  }

  addMill(): Promise<void> {
    if (this.activeMill.oid === -1) {
      return SwalDialog.show({
        title: 'Cannot Add New',
        text: 'There is already a new mill creation in progress. Please cancel or save before adding another.',
        icon: 'warning',
        dangerMode: true,
      });
    } else {
      const newMill: MillDTO = <MillDTO>{};
      newMill.oid = -1;
      newMill.companyName = '';
      newMill.isActive = true;
      newMill.availableProducts = [];
      newMill.availableFeeds = [];
      newMill.dairies = [];
      newMill.availableFeeds = [];
      newMill.availableProducts = [];
      const newMillValuePair: ValuePair = <ValuePair>{};
      newMillValuePair.isChecked = false;
      newMillValuePair.isActive = false;
      newMillValuePair.oid = -1;
      newMillValuePair.name = '';

      this.millsList.push(newMillValuePair);
      this.setActiveMill(-1, newMill);
      this.millNameEl.nativeElement.focus();
    }
  }

  async cancel(): Promise<void> {
    try {
      this.isSubmitted = false;
      const currentOid: number = this.activeMill.oid;
      await this.getAllMills();
      const foundMill: ValuePair = this.millsList.find(m => m.oid === currentOid);
      if (foundMill) {
        this.setActiveMill(foundMill.oid);
      } else {
        this.setActiveMill(this.millsList[0].oid);
      }
    } catch (error) {
      this.appUtilities.showServerErrorSwal(error.message);
    }
    // try {
    //   const currentOid: number = this.activeMill.oid;
    //   this.millsList = await this.pdsService.getMillListMapped2LoggedInUser().toPromise();
    //   const foundMill: MillDTO = this.millsList.find(f => f.oid === currentOid);
    //   if (foundMill) {
    //     this.activeMill = foundMill;
    //   } else {
    //     this.activeMill = this.mills[0];
    //   }
    //   this.originalCheckSum = this.appUtilities.createCheckSum(this.activeMill);
    // } catch (error) {
    //   this.appUtilities.showServerErrorSwal(error.message);
    // }
  }

  // async addEditDairies(): Promise<void> {
  //   const dairyModal: NgbModalRef = this.modalService.open(AddDairyModalComponent, {backdrop: 'static'});
  //   dairyModal.componentInstance.existingDairies = this.selectedMill.dairies;
  //   dairyModal.result.then((result) => { }, (reason) => {
  //     if (reason) {
  //       this.selectedMill.dairies = reason;
  //     }
  //   });
  // }

  showPreferenceChange(event): void {
    if (!this.masterMillsList.length) {
      this.masterMillsList = this.millsList;
    }
    this.showPreference = event;
    if (this.showPreference === 'Active') {
      this.millsList = this.masterMillsList.filter(item => {
        return item.isActive;
      });
    } else if (this.showPreference === 'Inactive') {
      this.millsList = this.masterMillsList.filter(item => {
        return !item.isActive;
      });
    } else {
      this.millsList = JSON.parse(JSON.stringify(this.masterMillsList));
    }
    if (this.millsList.length) {
      if (this.millsList.find(mill => mill.oid === this.activeMill.oid)) {
        this.setActiveMill(this.activeMill.oid);
      } else {
        this.setActiveMill(this.millsList[0].oid);
      }
      this.hasNoMill = false;
    } else {
      this.hasNoMill = true;
    }
  }

  selectDairy(index, oid): void {
    const foundDairy = this.dairies.find(d => +d.oid === +oid);
    if (foundDairy) {
      this.activeMill.dairies[index] = foundDairy;
    } else {
      // TODO Throw error cant find dairy
    }
  }

  removeDairy(index) {
    this.activeMill.dairies.splice(index, 1);
  }

  addDairyLineItem() {
    const oidList = this.activeMill.dairies.map(d => d.oid);
    this.availableDairies = this.dairies.filter(dairy => {
      if (oidList.indexOf(dairy.oid) === -1) {
        return dairy;
      }
    });
    if (!this.availableDairies || this.availableDairies.length < 1) {
      const response = SwalDialog.show({
        title: 'No Available Dairies',
        text: 'There are no available dairies to add to this region.',
        icon: 'warning',
        dangerMode: true,
      });
      return;
    } else {
      // tslint:disable-next-line
      // TODO There must be more to the newly added dairy? default name maybe? Verify that the below code is enough and adds a dairy correctly
      const newDairy: DairyDTO = <DairyDTO>{};
      newDairy.oid = -1;
      this.activeMill.dairies.splice(0, 0, newDairy);
    }
  }

  allowedToAdd() {
    if (this.loggedInEntity) {
      if (this.loggedInEntity.securityGroupName === CONSTANTS.SECURITY_GROUPING.ADMIN || this.loggedInEntity.securityGroupName === CONSTANTS.SECURITY_GROUPING.SUPERUSER) { // tslint:disable-line
        return true;
      } else {
        return false;
      }
    }
  }

  navToDairy(dairy) {
    this.router.navigate([`/dairies/${UrlObfuscationService.obfuscate(dairy.oid)}`]);
  }

  navToFeed(feed) {
    this.router.navigate([`/feeds/${UrlObfuscationService.obfuscate(feed.oid)}`]);
  }

  isAdminView(): boolean {
    if (this.loggedInEntity && this.loggedInEntity.securityGroupName && (this.loggedInEntity.securityGroupName === CONSTANTS.SECURITY_GROUPING.SUPERUSER) || (this.loggedInEntity.securityGroupName === CONSTANTS.SECURITY_GROUPING.ADMIN)) { // tslint:disable-line
      return true;
    }
    return false;
  }

  async confirmNavigation(context) {
    const res = await SwalDialog.show({
      title: 'Edit Mapping',
      text: 'To edit the available ' + context + ' you will need to navigate to the mappings page. Are you sure you want to edit?',
      icon: 'warning',
      buttons: {
        test1: { text: CONSTANTS.SWAL_RESPONSE.YES, value: CONSTANTS.SWAL_RESPONSE.YES },
        test2: { text: CONSTANTS.SWAL_RESPONSE.NO, value: CONSTANTS.SWAL_RESPONSE.NO },
      }
    });
    if (res === CONSTANTS.SWAL_RESPONSE.YES) {
      this.router.navigate([`/mapping` + `/${UrlObfuscationService.obfuscate(this.activeMill.oid)}`], { queryParams: { mappingTab: 'Mill Mapping'} })
    }
  }

}

import {Component, Input, Output, EventEmitter} from '@angular/core';
import {FilterDescriptor} from '@progress/kendo-data-query';
import {CwColumnComponent} from '../cw-column.component';

@Component({
  selector: 'date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.css']
})
export class DateRangeFilterComponent {
  @Input() public currentDateFilters: FilterDescriptor[];

  @Input() public column: CwColumnComponent;

  @Output() private onDateRangeFilterChange: EventEmitter<FilterDescriptor[]> = new EventEmitter(); // tslint:disable-line

  public dateRange: {start: Date, end: Date} = {start: null, end: null};

  constructor( ) { }

  /**
   * Conditionally adds filters to the depending on what data is provided
   */
  public onDateRangeSelect(): void {
    const filters: FilterDescriptor[] = [ ];
    const setFilterByColumnKey = (columnKey: string) => {
      if (this.dateRange.start) {
        filters.push({
          field: columnKey,
          operator: 'gte',
          value: this.dateRange.start.toISOString()
        });
      }
      if (this.dateRange.end) {
        filters.push({
          field: columnKey,
          operator: 'lte',
          value: this.dateRange.end.toISOString()
        });
      }
    };
    if (this.column.dateStartKey) {
      setFilterByColumnKey(this.column.dateStartKey);
    }
    if (this.column.dateEndKey) {
      setFilterByColumnKey(this.column.dateEndKey);
    }
    this.onDateRangeFilterChange.emit(filters);
  }

  /**
   * Removes the filters from the parents filter array
   */
  public clearFilters(): void {
    this.onDateRangeFilterChange.emit([]);
  }

}

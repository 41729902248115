import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public isLoading: boolean = false;
  public loginName: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  sendTemporaryEmail(): void {
    this.isLoading = true;
  }

}

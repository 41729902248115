import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {UserDTO, PDSService, CompAdjustment} from '../../api/generated-api';
import { AddEditNutritionistAdjustmentsComponent } from '../../components/add-edit-nutritionist-adjustments-modal/add-edit-nutritionist-adjustments.component'; // tslint:disable-line

@Component({
  selector: 'comp-adjustments-modal',
  templateUrl: './comp-adjustments-modal.component.html',
  styleUrls: ['./comp-adjustments-modal.component.css']
})
export class CompAdjustmentsModalComponent implements OnInit {

  compAdjustments;
  currentUser: UserDTO = <UserDTO>{};
  public compTotal: number;
  public isSaving: boolean = false;
  public masterList: CompAdjustment[] = [];
  public showPreference: string = 'All';

  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal, private pdsService: PDSService) { }

  ngOnInit(): void {
    this.initiate();
  }

  async initiate() {
    this.compAdjustments = await this.pdsService.getCompAdjustmentsByEntityOid(this.currentUser.oid).toPromise();
    this.masterList = this.compAdjustments;
  }

  cancel(): void {
    this.showPreference = 'All';
    this.compAdjustments = [];
    this.currentUser = <UserDTO>{};
    this.masterList = [];
    this.isSaving = false;
    this.activeModal.dismiss();
  }

  showPreferenceChange(event): void {
    if (!this.masterList.length) {
      this.masterList = this.compAdjustments;
    }
    this.showPreference = event;
    if (this.showPreference === 'Active') {
      this.compAdjustments = this.masterList.filter(item => {
        return item.isActive;
      });
    } else if (this.showPreference === 'Inactive') {
      this.compAdjustments = this.masterList.filter(item => {
        return !item.isActive;
      });
    } else {
      this.compAdjustments = this.masterList;
    }
  }

  addAdjustment() {
    const adjustmentsModal: NgbModalRef = this.modalService.open(AddEditNutritionistAdjustmentsComponent, {backdrop: 'static'});

    adjustmentsModal.result.then((result) => { }, (reason) => {
      this.processAdjustmentModalResponse(reason);
    });
  }

  editAdjustment(comp: CompAdjustment, index: number) {
    const adjustmentsModal: NgbModalRef = this.modalService.open(AddEditNutritionistAdjustmentsComponent, {backdrop: 'static'});
    adjustmentsModal.componentInstance.adjustment = JSON.parse(JSON.stringify(comp));

    adjustmentsModal.result.then((result) => { }, (reason) => {
      this.processAdjustmentModalResponse(reason, index);
    });
  }

  processAdjustmentModalResponse(reason, index?: number) {
    if (reason) {
      if (reason.oid) {
        if (index) {
          this.compAdjustments[index] = reason;
        }
      } else {
        const newComp = <CompAdjustment>{...reason};
        this.compAdjustments.push(newComp);
      }
    }
  }

}

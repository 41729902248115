import {Component, OnInit, OnDestroy, ViewEncapsulation, EventEmitter} from '@angular/core';
import {CwColumnComponent} from '../cw-column.component';

@Component({
  selector: 'batch-update-row',
  templateUrl: './batch-update-row.component.html',
  styleUrls: ['./batch-update-row.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BatchUpdateRowComponent implements OnInit, OnDestroy {

  public columns: CwColumnComponent[];
  public hiddenColumns: string[] = [];

  public isHidden;
  public columnEdits: {[key: string]: any} = {};
  public onBatchUpdate: EventEmitter<{key: string, value: string}> = new EventEmitter<{key: string, value: string}>();

  constructor(
  ) { }

  ngOnInit(): void {
    this.columns.forEach(column => {
      this.columnEdits[column.name] = '';
    });
  }

  ngOnDestroy(): void {
  }

  public updateData(column: CwColumnComponent): void {
    this.onBatchUpdate.emit({key: column.name, value: this.columnEdits[column.name]});

    column.onBulkUpdate.emit(this.columnEdits[column.name]);
  }

  public getInputType(type): string {
    if (type === 'number') {
      return 'number';
    } else {
      return 'text';
    }
  }
}

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Entity, FeedDeliveryDTO, PDSService, MonthEndService, MonthCloseState, Product,
  ValuePair, FeedDeliveryCloneDTO,
  FeedRecipeChangeDTO, ClientResult
} from '../../api/generated-api';
import { AddEditFeedDeliveryModalComponent } from 'src/app/components/add-edit-feed-delivery/add-edit-feed-delivery-modal.component';
import { CONSTANTS } from 'src/app/lib/constants';
import { AppUtilities } from 'src/app/lib/appUtilities.service';
import { CwGridComponent } from 'src/app/components/cw-grid/cw-grid.component';
import { UserService } from 'src/app/services/user.service';
import { SwalDialog } from 'src/app/lib/swal-dialog';
import { FeedRecipeAdjustmentModal } from 'src/app/components/feed-recipe-adjustment-modal/feed-recipe-adjustment-modal.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import {ToastrService} from "ngx-toastr";
import {ProfitBreakdownModalComponent} from "../../components/profit-breakdown-modal/profit-breakdown-modal.component";

@Component({
  selector: 'feed-delivery-entry',
  templateUrl: './feed-delivery-entry.component.html',
  styleUrls: ['./feed-delivery-entry.component.scss']
})
export class FeedDeliveryEntryComponent implements OnInit {

  @ViewChild('feedDeliveryGrid', null) feedDeliveryGrid: CwGridComponent;
  @ViewChild('tonsInput', null) searchElement: ElementRef;

  public constAdmin: string = CONSTANTS.SECURITY_GROUPING.ADMIN;
  public constMill: string = CONSTANTS.SECURITY_GROUPING.MILL;
  public constSuper: string = CONSTANTS.SECURITY_GROUPING.SUPERUSER;

  public appUtilities: AppUtilities = new AppUtilities();
  public loggedInEntity: Entity = <Entity>{};
  public monthCloseState: MonthCloseState = <MonthCloseState>{};
  public millsList: ValuePair[] = [];
  public selectedMillOid: number;


  public currentMonth: number;
  public currentMonthString: string = '';
  public currentYear: number;
  public activeMonth: number;
  public activeMonthString: string = '';
  public activeYear: number;
  public monthList: Array<{ nMonth: number, sMonth: string }> = CONSTANTS.MONTHS_SELECT_LIST;
  public deliveries: FeedDeliveryDTO[] = [];
  public originalCheckSumDeliveries: string = '';
  public years: number[] = CONSTANTS.YEARS;
  public securityGroupName: string = '';

  constructor(private modalService: NgbModal,
    private userService: UserService,
    private monthEndService: MonthEndService,
    private pdsService: PDSService,
    private toast: ToastrService
  ) { }

  async ngOnInit(): Promise<void> {
    this.loggedInEntity = await this.userService.getLoggedInEntityUser();
    this.securityGroupName = this.loggedInEntity.securityGroupName;
    this.monthCloseState = await this.userService.getMonthCloseState();
    await this.getMillsList();
    this.activeYear = this.monthCloseState.currentYear;
    this.activeMonth = this.monthCloseState.currentMonth;
    this.activeMonthString = CONSTANTS.MONTHS[this.activeMonth];
    this.currentDateSet();


    await this.getFeedDeliverDTOs();
    // if (this.deliveries.length <= 0 && (this.loggedInEntity && this.loggedInEntity.securityGroupName === this.constMill)) {
    //   this.suggestClonePreviousMonthDialogue();
    // }
  }

  getMonthDelivered(deliveryMonth: number): string {
    return CONSTANTS.MONTHS[deliveryMonth];
  }


async getMillsList() {
    const response = await this.pdsService.getMillListMapped2LoggedInUser(true).toPromise();
    if(this.loggedInEntity.securityGroupName === this.constMill) {
      this.selectedMillOid = this.loggedInEntity.entityOid_Master;
    } else {
      this.millsList = response;
    }
}

  currentDateSet() {
    this.currentYear = this.monthCloseState.currentYear;
    this.currentMonth = this.monthCloseState.currentMonth;
    this.currentMonthString = CONSTANTS.MONTHS[this.currentMonth];
  }

  async alterRecipe(dto: FeedDeliveryDTO) {
    const alterRecipeModal = this.modalService.open(FeedRecipeAdjustmentModal, { backdrop: 'static', size: 'lg' });
    if(this.loggedInEntity.securityGroupName === this.constMill) {
      alterRecipeModal.componentInstance.isMillUser = true;
    }
    alterRecipeModal.componentInstance.dto = JSON.parse(JSON.stringify(dto));
    alterRecipeModal.componentInstance.millOid = dto.entityOid_Mill;
    alterRecipeModal.result.then((result) => { }, (reason) => {
      this.getFeedDeliverDTOs();
    });
  }

  async processModalResponse(res, dto: FeedDeliveryDTO) {
    if (res && res.submitted) {
      const myhtml = document.createElement('div');
      myhtml.innerHTML = `Would you like to use this recipe for all other <br> <strong>${dto.feedName}</strong> <br> entries for this month?`; // tslint:disable-line
      // tslint:disable-next-line
      const res: boolean = await SwalDialog.show({
        dangerMode: false,
        title: `Recipe Changed`,
        content: myhtml,
        icon: 'warning',
        buttons: {
          yes: { text: CONSTANTS.SWAL_RESPONSE.YES, value: true },
          no: { text: CONSTANTS.SWAL_RESPONSE.NO, value: false },
        }
      });
      if (res) {
// await update all call
      }
      this.getFeedDeliverDTOs();
    }
  }

  canViewLockedMonth(): boolean {
    let bReturn = false;
    if(this.monthCloseState.isLocked){
      return false;
    } else {
      return true;
    }
  }

  canCloneOrAdd(): boolean {
    let bReturn = false;
    if((this.currentYear != this.activeYear) || (this.currentMonth != this.activeMonth)) {
      bReturn = false;
    } else {
      bReturn =  true;
    }
    return bReturn;

  }

  async clonePreviousCheck() {
    const isValidToNavigate: boolean = await this.canCloneOrAdd();
    if(!isValidToNavigate) {
      SwalDialog.show({
        title: 'Unable To Clone',
        text: 'Feed deliveries can only be cloned to the current active month and year (' + this.activeMonthString +' of ' + this.activeYear + ')' ,
        icon: 'warning',
        dangerMode: true,
      });
    } else {
      if (this.deliveries.length > 0) {
        const res: string = await SwalDialog.show({
          dangerMode: false,
          title: 'Confirm',
          text: 'This will add last months records to the current data set. Do you want to continue?',
          icon: 'warning',
          buttons: {
            test1: { text: CONSTANTS.SWAL_RESPONSE.YES, value: CONSTANTS.SWAL_RESPONSE.YES },
            test2: { text: CONSTANTS.SWAL_RESPONSE.NO, value: CONSTANTS.SWAL_RESPONSE.NO },
          }
        });
        if (res === CONSTANTS.SWAL_RESPONSE.YES) {
          await this.clonePreviousMonth();
        }
      } else {
        await this.clonePreviousMonth();
      }
    }
  }

  async clonePreviousMonth() {
    const res: FeedDeliveryCloneDTO = await this.monthEndService.getFeedDeliveryClone(this.selectedMillOid).toPromise();
    this.deliveries = this.deliveries.concat(res.clonedDeliveries);
      SwalDialog.show({
        title: ' ',
        text: res.resultMessage,
        dangerMode: false,
      });
  }

  private filterAndConcatPreviousMonthDeliveries(deliveries: FeedDeliveryDTO[]){
      const currentOids: number[] = this.deliveries.map(delivery => {if(delivery.feedDeliveryOid_Clone !== null){return delivery.feedDeliveryOid_Clone}});
      deliveries.forEach(delivery => {
        if(currentOids.indexOf(delivery.feedDeliveryOid_Clone) == -1){
          this.deliveries.push(delivery);
        }
      })
  }

  async getFeedDeliverDTOs(): Promise<void> {
    if(!this.selectedMillOid || this.selectedMillOid <= 0) {
      this.selectedMillOid = this.millsList[0].oid;
    }
      const res: ClientResult = await this.pdsService.getCurrentFeedDeliveryDTOs(+this.currentMonth, +this.currentYear, this.selectedMillOid).toPromise();
      if(res.hasErrors) {
        this.deliveries = [];
        await this.appUtilities.showServerErrorSwal(res.errorMessage);
      } else {
        this.deliveries = res.data;
        this.originalCheckSumDeliveries = this.appUtilities.createCheckSum(this.deliveries);
      }

    // if(!this.deliveries || !this.deliveries.length) {
    //   const response: string = await SwalDialog.show({
    //     dangerMode: false,
    //     text: 'Would you like to clone last month\'s production?',
    //     buttons: {
    //       test1: { text: CONSTANTS.SWAL_RESPONSE.YES, value: CONSTANTS.SWAL_RESPONSE.YES },
    //       test2: { text: CONSTANTS.SWAL_RESPONSE.NO, value: CONSTANTS.SWAL_RESPONSE.NO },
    //     }
    //   });
    //   if (response === CONSTANTS.SWAL_RESPONSE.YES) {
    //     await this.clonePreviousMonth();
    //   }
    // }
  }

  // async suggestClonePreviousMonthDialogue() {
  //   // if (this.loggedInEntity && this.loggedInEntity.securityGroupName === this.constMill) {
  //     const res: string = await SwalDialog.show({
  //       dangerMode: false,
  //       text: 'Would you like to clone last month\'s production?',
  //       buttons: {
  //         test1: { text: CONSTANTS.SWAL_RESPONSE.YES, value: CONSTANTS.SWAL_RESPONSE.YES },
  //         test2: { text: CONSTANTS.SWAL_RESPONSE.NO, value: CONSTANTS.SWAL_RESPONSE.NO },
  //       }
  //     });
  //     if (res === CONSTANTS.SWAL_RESPONSE.YES) {
  //       await this.clonePreviousMonth();
  //     }
  //   // }
  // }

  async addEditDelivery(pDelivery?: FeedDeliveryDTO): Promise<any> {
    const isValidToNavigate: boolean = await this.canCloneOrAdd();
    if(!isValidToNavigate) {
      SwalDialog.show({
        title: 'Cannot Add',
        text: 'Feed deliveries can only be added to the current active month and year (' + this.activeMonthString +' of ' + this.activeYear + ')' ,
        icon: 'warning',
        dangerMode: true,
      });
    } else if(!this.isAdmin() && this.monthCloseState.isLocked) {
      SwalDialog.show({
        title: 'Month Closed',
        text: 'The month of '+ this.activeMonthString +' has been closed for processing. If this creates a problem, feel free to call Progressive Dairy Solutions.' ,
        icon: 'warning',
        dangerMode: true,
      });
    } else {
      const deliveryModal = this.modalService.open(AddEditFeedDeliveryModalComponent, { backdrop: 'static' });
      deliveryModal.componentInstance.currentMonth = this.currentMonthString;
      deliveryModal.componentInstance.millOid = this.selectedMillOid;
      if (pDelivery) {
        deliveryModal.componentInstance.viewFeed = JSON.parse(JSON.stringify(pDelivery));
        deliveryModal.componentInstance.feed = JSON.parse(JSON.stringify(pDelivery));
      }
      deliveryModal.result.then((result) => { }, (reason) => {
        this.getFeedDeliverDTOs();
      });
    }
  }



  async removeDelivery(obj) {
    const res = await SwalDialog.show({
      dangerMode: true,
      text: 'Are you sure you want to delete this entry?',
      buttons: {
        yes: { text: CONSTANTS.SWAL_RESPONSE.YES, value: true },
        no: { text: CONSTANTS.SWAL_RESPONSE.NO, value: false },
      }
    });
    if(res){
      await this.pdsService.deleteFeedDelivery(obj.oid).toPromise();
      this.toast.success('Successfully Deleted');
      this.getFeedDeliverDTOs();
    }
  }

  public getTotalQty(columnName: string): number {
    return AppUtilities.addByColumn(this.deliveries, columnName);
  }

  async onBlurSave(obj: FeedDeliveryDTO): Promise<void> {
    // tslint:disable-next-line
    await this.pdsService.addUpdateFeedDeliveries([obj]).toPromise();
    obj.isEditing = false;
  }

  cancel(): void {
    this.getFeedDeliverDTOs();
  }


  async tonsOnKeyPress(event, obj) {
    if(event.keyCode === 40 || event.keyCode === 13){
    await this.onBlurSave(obj);
    const currentindex = this.feedDeliveryGrid.dataView.data.indexOf(obj);
      if(currentindex + 1 === this.feedDeliveryGrid.dataView.data.length) {
        obj.isEditing = false;
      } else {
        const foundItem = this.feedDeliveryGrid.dataView.data[(currentindex + 1)];
        obj.isEditing = false;
        if(foundItem !== null){
          foundItem.isEditing = true;
        }
        setTimeout(() => this.focusElement());
      }
    }
    if(event.keyCode === 38){
      await this.onBlurSave(obj);
      const currentindex = this.feedDeliveryGrid.dataView.data.indexOf(obj);
      if(currentindex !== -1 && currentindex - 1 < 0 ) {
        obj.isEditing = false;
      } else {
        const foundItem = this.feedDeliveryGrid.dataView.data[(currentindex - 1)];
        obj.isEditing = false;
        if(foundItem !== null){
          foundItem.isEditing = true;
        }
        setTimeout(() => this.focusElement());
      }
    }
  }

  focusElement(){
    this.searchElement.nativeElement.focus();
    this.searchElement.nativeElement.select();
  }

  // async canDeactivate(): Promise<boolean> {
  //   let bReturn = false;
  //   if (this.appUtilities.createCheckSum(this.deliveries) !== this.originalCheckSumDeliveries) {
  //     const res: string = await this.appUtilities.showUnsavedChangesSwal();
  //     if (res === CONSTANTS.SWAL_RESPONSE.YES) {
  //       await this.save();
  //       bReturn = true;
  //     } else if (res === CONSTANTS.SWAL_RESPONSE.NO) {
  //       bReturn = true;
  //     } else {
  //       bReturn = false;
  //     }
  //   } else {
  //     bReturn = true;
  //   }
  //   return bReturn;
  // }

  selectDate() {
    this.currentMonthString = CONSTANTS.MONTHS[this.currentMonth];
    this.getFeedDeliverDTOs();
  }

  selectMill() {
    this.getFeedDeliverDTOs();
  }

  editCell(obj) {
    setTimeout(() => this.focusElement());
    obj.isEditing = true;
  }

  isAdmin() {
    if(this.loggedInEntity.securityGroupName === this.constSuper || this.loggedInEntity.securityGroupName === this.constAdmin) {
      return true;
    } else {
      return false;
    }
  }

  viewPopup(obj) {
    // console.log(obj, 'object passed in')
    const profitBreakdownModal = this.modalService.open(ProfitBreakdownModalComponent, { backdrop: 'static', size: 'lg' });
    profitBreakdownModal.componentInstance.feedDeliveryOid = obj.oid;
    profitBreakdownModal.componentInstance.loggedInUser = this.loggedInEntity;
    // v.result.then((result) => { }, (reason) => {
    //   this.getFeedDeliverDTOs();
    // });
  }

  allowRowEdit(){
    var dt = new Date();
    if(this.currentMonth < (dt.getMonth() + 1) && this.currentYear <= dt.getFullYear())
      return false
    else if(this.currentYear < dt.getFullYear())
      return false
    else if(this.currentYear < (dt.getMonth() + 1))  
      return false
    else
      return true  
  }

}

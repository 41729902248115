import { Component, OnInit } from '@angular/core';
import {CONSTANTS} from '../../lib/constants';
import {ClientResult, Entity, MillProductionGrid, MonthCloseState, MonthEndService} from '../../api/generated-api';
import {AppUtilities} from '../../lib/appUtilities.service';
import {UserService} from "../../services/user.service";

@Component({
  selector: 'view-by-mill',
  templateUrl: './view-by-mill.component.html',
  styleUrls: ['./view-by-mill.component.css']
})
export class ViewByMillComponent implements OnInit {

  public millData: MillProductionGrid[] = [];
  public monthCloseState: MonthCloseState = <MonthCloseState>{};
  public months: Array<{ nMonth: number, sMonth: string }> = CONSTANTS.MONTHS_SELECT_LIST;
  public years: Array<number> = CONSTANTS.YEARS;
  public selectedMonth: number = new Date().getMonth() + 1;
  public selectedYear: number = new Date().getFullYear();
  public monthString: string = '';
  public constMill: string = CONSTANTS.SECURITY_GROUPING.MILL;
  public loggedInEntity: Entity = <Entity>{};
  public appUtilities: AppUtilities = new AppUtilities();
  public pdfLoading: boolean = false;

  constructor(private monthEnd: MonthEndService,
              private userService: UserService,) { }

  ngOnInit(): void {
    this.initiate();
  }

  async initiate(): Promise<void> {
    await this.getLoggedInUser();
    await this.setClosedState();
    this.getProductionData();

  }

  async getLoggedInUser(): Promise<void> {
    this.loggedInEntity = await this.userService.getLoggedInEntityUser();
    // console.log(this.loggedInEntity, 'logged in')
  }

  async setClosedState() {
    this.monthCloseState = await this.monthEnd.getMonthCloseState().toPromise();
    if(this.loggedInEntity.securityGroupName === this.constMill) {
      if(this.monthCloseState.currentMonth === 1) {
        this.selectedMonth = 12;
        this.selectedYear = this.monthCloseState.currentYear -1;
      } else {
        this.selectedMonth = this.monthCloseState.currentMonth -1;
        this.selectedYear = this.monthCloseState.currentYear
      }
    } else {
      this.selectedMonth = this.monthCloseState.currentMonth;
      this.selectedYear = this.monthCloseState.currentYear;
    }
    this.setMonthString();
  }

  async getProductionData() {
    const res = await this.monthEnd.getMillProductionGridByLoggedInUsersOidByMonthAndYear(this.selectedMonth, this.selectedYear).toPromise(); // tslint:disable-line
    if(res.hasErrors) {
      this.millData = [];
      await this.appUtilities.showServerErrorSwal(res.errorMessage);
    } else {
      this.millData = res.data.mills;
    }
  }

  getTotalByColumn(columnName: string, data?) {
    return AppUtilities.addByColumn(data.deliveries, columnName);
  }

  selectDate() {
    this.setMonthString();
    this.getProductionData();
  }

  setMonthString() {
    this.monthString = this.months.find(month => +month.nMonth === +this.selectedMonth).sMonth;
  }

  public collapseAll(topGrid) {
    this.millData.forEach((item, idx) => {
      if(topGrid.grid){
        topGrid.grid.collapseRow(idx);
      }
    })
  }

  public expandAll(topGrid) {
    this.millData.forEach((item, idx) => {
      if(topGrid.grid){
        topGrid.grid.expandRow(idx);
      }
      else{
        topGrid.expandRow(idx);
      }
    })
  }
  
  async downloadPdf() {
    this.pdfLoading = true;
   const res = await this.monthEnd.generateMillProductionPDF( this.selectedMonth, this.selectedYear).toPromise();
   if(res && res.data && res.data != "") {
     this.openFileInNewTab(res.data);
   } else {
     //await this.appUtil.showServerErrorSwal(res.errorMessage);
   }
   this.pdfLoading = false;
  }

  openFileInNewTab(url): void {
    window.open(url);
  }
}

import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CONSTANTS } from './constants';

export class AddHeaderInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem(CONSTANTS.LOCAL_STORAGE.AUTH_TOKEN);
    let request = req;
    if (token) {
      request = request.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) });
    }
    // Pass the cloned request instead of the original request to the next handle
    return next.handle(request);
  }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AppUtilities } from '../../../lib/appUtilities.service';
import { CompPlan, UserRegionInfo, PDSService, RegionDTO, UserDTO, CompAdjustment, CompPlanInfo, Entity } from '../../../api/generated-api';
import { AddEditNutritionistAdjustmentsComponent } from "../../../components/add-edit-nutritionist-adjustments-modal/add-edit-nutritionist-adjustments.component";
import { UsersService, SecurityGroupObject } from '../users.service';
import { Subscription } from 'rxjs';
import { CONSTANTS } from 'src/app/lib/constants';
import {SwalDialog} from "../../../lib/swal-dialog";

export interface CompPlanExtended extends CompPlan {
  isChecked: boolean;
}
@Component({
  selector: 'nutritionist-user',
  templateUrl: './nutritionist-user.component.html',
  styleUrls: ['./nutritionist-user.component.css']
})
export class NutritionistUserComponent implements OnInit, OnDestroy {

  @Input() public viewOnly: boolean;
  @Input() public selectedUser: UserDTO;
  @Input() public loggedInEntity: Entity;

  @Output() changeGroup: EventEmitter<any> = new EventEmitter();
  @Output() userInfo: EventEmitter<any> = new EventEmitter();
  @Output() emailBlur: EventEmitter<any> = new EventEmitter();
  @Output() generateRandomPassword: EventEmitter<any> = new EventEmitter();

  public securityGroups: SecurityGroupObject[] = CONSTANTS.SECURITY_GROUP_DROPDOWN_LIST;
  public allRegions: RegionDTO[] = [];
  public availableRegions: RegionDTO[] = [];
  public allCompPlans: CompPlan[] = [];
  public compPlans: CompPlanExtended[] = [];
  public compAdjustments: CompAdjustment[] = [];
  public masterCompAdjustments: CompAdjustment[] = [];
  public constSuper: string = CONSTANTS.SECURITY_GROUPING.SUPERUSER;
  public selectedSecurityGroupOid: number;
  public radioPreference: string = 'Active';

  public userSubscription: Subscription;

  @ViewChild("firstName", null) nameEl: ElementRef;

  constructor(
    private modalService: NgbModal,
    private pdsService: PDSService,
    private usersService: UsersService) {
  }

  async ngOnInit(): Promise<void> {
    this.allRegions = this.usersService.allRegions;
    this.allCompPlans = this.usersService.allCompPlans;
    this.filterCompPlans();
    this.filterAdjustmentList();
    this.getCompAdjustments();
    this.selectedSecurityGroupOid = this.selectedUser.securityGroupOid;
    this.nameEl.nativeElement.focus();
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  filterCompPlans(): void {
    this.compPlans = this.allCompPlans.map(comp => {
      const newExt: CompPlanExtended = <CompPlanExtended>{ ...comp };
      const isExists: CompPlanInfo = this.selectedUser.compPlans.find(cp => { return cp.oid === comp.oid });
      newExt.isChecked = isExists ? true : false;
      return newExt;
    });
  }

  async getCompAdjustments(): Promise<void> {
    this.compAdjustments = await this.pdsService.getCompAdjustmentsByEntityOid(this.selectedUser.oid).toPromise();
    // console.log(this.compAdjustments, 'comp adjustments')
    this.masterCompAdjustments = JSON.parse(JSON.stringify(this.compAdjustments));
  }

  async removeUserBonuseRegion(index, regionOid?) {
    if (regionOid) {
      const confirmDelete = await this.confirmRemovalOfRegion(regionOid);
      if (confirmDelete) {
        const res = await this.pdsService.removeBonusRegion(this.selectedUser.oid, regionOid).toPromise();
        this.refreshUserInfo();
        this.selectedUser.regions.splice(index, 1);
      } else {

      }
    } else {
      this.selectedUser.regions.splice(index, 1);
    }
  }

  async confirmRemovalOfRegion(regionOid?): Promise<boolean> {
    const response = await SwalDialog.show({
      title: 'Are You Sure?',
      text: 'Are you sure you want to remove this region?',
      icon: 'warning',
      dangerMode: false,
      buttons: {
        test1: { text: 'Cancel', value: CONSTANTS.SWAL_RESPONSE.NO},
        test2: { text: 'Remove', value: CONSTANTS.SWAL_RESPONSE.YES },
      }
    });
    if (response === CONSTANTS.SWAL_RESPONSE.YES) {
      return true;
    } else {
      return false;
    }
  }

  addNewRegion() {
    const oidList = this.selectedUser.regions.map(sr => sr.oid);
    this.availableRegions = this.allRegions.filter(r => {
      if (oidList.indexOf(r.oid) === -1) {
        return r;
      }
    });
    const newRegion: UserRegionInfo = new UserRegionInfo();
    newRegion.name = '';
    newRegion.bonusPercentage = 0;
    this.selectedUser.regions.push(newRegion);
  }

  selectRegion(index, region) {
    const foundRegion: RegionDTO = this.allRegions.find(r => +r.oid === +region.oid);
    if (foundRegion) {
      const newRegion: UserRegionInfo = new UserRegionInfo();
      newRegion.oid = foundRegion.oid;
      newRegion.name = foundRegion.name;
      if(region && region.bonusPercentage) {
        newRegion.bonusPercentage = region.bonusPercentage;
      } else {
        newRegion.bonusPercentage = 0;
      }
      this.selectedUser.regions[index] = newRegion;
    }
  }

  selectCompPlan(object) {
    let foundIndex: number;
    for (let i = 0; i < this.selectedUser.compPlans.length; i++) {
      if (this.selectedUser.compPlans[i].oid === object.oid) {
        foundIndex = i;
        break;
      }
    }
    if (foundIndex || foundIndex === 0) {
      this.selectedUser.compPlans.splice(foundIndex, 1);
    } else {
      const newComp: CompPlanInfo = new CompPlanInfo();
      newComp.oid = object.oid;
      newComp.planName = object.name;
      this.selectedUser.compPlans.push(newComp);
    }
  }

  getTotalByColumn(list: any[], columnName: string) {
    return AppUtilities.addByColumn(list, columnName);
  }

  selectUserGroup() {
    if (this.selectedUser && this.selectedUser.securityGroupName === this.constSuper) {
      this.selectedUser.securityGroupOid = +this.selectedSecurityGroupOid;
      this.changeGroup.next(this.selectedUser.securityGroupOid);
    } else {
      if (+this.selectedSecurityGroupOid === CONSTANTS.SECURITY_GROUPING_OID.SUPERUSER) {
        this.selectedSecurityGroupOid = this.selectedUser.securityGroupOid;
        SwalDialog.show({
          title: 'Unable To Select',
          text: 'Only a super user has the ability to change the security group to a super user.',
          icon: 'warning',
          dangerMode: true,
        });
      }  else {
        this.selectedUser.securityGroupOid = +this.selectedSecurityGroupOid;
        this.changeGroup.next(this.selectedUser.securityGroupOid);
      }
    }
  }

  onEmailBlur(){
    this.emailBlur.next();
  }

  onGenerateRandomPassword(){
    this.generateRandomPassword.next();
  }

  async addAdjustment() {
    const adjustmentsModal: NgbModalRef = this.modalService.open(AddEditNutritionistAdjustmentsComponent, { backdrop: 'static' });
    adjustmentsModal.componentInstance.nutritionistOid = this.selectedUser.oid;
    adjustmentsModal.result.then((result) => { }, (reason) => {
      if (reason) {
        this.getCompAdjustments();
      }
    });
  }

  async editAdjustment(comp) {
    const adjustmentsModal: NgbModalRef = this.modalService.open(AddEditNutritionistAdjustmentsComponent, { backdrop: 'static' });
    adjustmentsModal.componentInstance.nutritionistOid = this.selectedUser.oid;
    adjustmentsModal.componentInstance.adjustment = JSON.parse(JSON.stringify(comp));
    adjustmentsModal.result.then((result) => { }, (reason) => {
      if (reason) {
        this.getCompAdjustments();
      }
    });
  }

  canAddLineItem(): boolean {
    if (this.viewOnly) {
      return false;
    }
    return true;
  }

  processAdjustmentModalResponse(reason, index?: number) {
    if (reason) {
      if (reason.oid) {
        if (index) this.compAdjustments[index] = reason;
      } else {
        const newComp = <CompAdjustment>{ ...reason }
        this.compAdjustments.push(newComp);
      }
    }
  }

  refreshUserInfo() {
    this.userInfo.next(this.selectedUser);
  }

  filterAdjustmentList() {
   if(this.radioPreference === 'Inactive') {
     this.compAdjustments = this.masterCompAdjustments.filter(comp => {
       return !comp.isActive
     });
   } else if(this.radioPreference === 'Active') {
     this.compAdjustments = this.masterCompAdjustments.filter(comp => {
       return comp.isActive
     });
   } else {
     this.compAdjustments = this.masterCompAdjustments;
   }
  }

  showRadioPreferenceChange(event): void {
    this.radioPreference = event;
    this.filterAdjustmentList();
  }

}

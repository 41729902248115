import {Injectable} from '@angular/core';
import { CONSTANTS } from './constants';

@Injectable()
export class JWTService {

  /**
   * Checks to see if they have a valid auth token
   * @returns {boolean}
   */
  public checkToken(): boolean {
    const authToken = localStorage.getItem(CONSTANTS.LOCAL_STORAGE.AUTH_TOKEN);
    if (!authToken || authToken === '') {
      return false;
    }
    const userInfo = this.parseJwt(authToken);
    const expireDate = userInfo.exp;
    if (expireDate && ((expireDate * 1000) < new Date().getTime())) {
      return false;
    } else {
      return true;
    }
  }

  parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};

  setToken(token: string): void {
    localStorage.setItem(CONSTANTS.LOCAL_STORAGE.AUTH_TOKEN, token);
  }

  removeToken(): void {
    localStorage.removeItem(CONSTANTS.LOCAL_STORAGE.AUTH_TOKEN);
  }

}

import {Component, EventEmitter, Input, OnInit, Output, ElementRef, ViewChild, OnDestroy} from '@angular/core';
import {UserDTO, Entity, PDSService} from '../../../api/generated-api';
import { SecurityGroupObject } from '../users.service';
import { Subscription } from 'rxjs';
import { CONSTANTS } from 'src/app/lib/constants';
import {SwalDialog} from "../../../lib/swal-dialog";

@Component({
  selector: 'standard-user',
  templateUrl: './standard-user.component.html',
  styleUrls: ['./standard-user.component.css']
})
export class StandardUserComponent implements OnInit, OnDestroy {

  @Input() public viewOnly: boolean;
  @Input() public selectedUser: UserDTO;
  @Input() public loggedInEntity: Entity;

  @Output() changeGroup: EventEmitter<any> = new EventEmitter();
  @Output() emailBlur: EventEmitter<any> = new EventEmitter();
  @Output() generateRandomPassword: EventEmitter<any> = new EventEmitter();

  public securityGroups: SecurityGroupObject[] = CONSTANTS.SECURITY_GROUP_DROPDOWN_LIST;
  public userSubscription: Subscription;
  public constSuper: string = CONSTANTS.SECURITY_GROUPING.SUPERUSER;
  public selectedSecurityGroupOid: number;

  @ViewChild("firstName", null) nameEl: ElementRef;

  constructor(private pdsService: PDSService ) {
  }

  async ngOnInit() {
    this.selectedSecurityGroupOid = this.selectedUser.securityGroupOid;
    this.nameEl.nativeElement.focus();
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  selectUserGroup() {
    if (this.selectedUser && this.selectedUser.securityGroupName === this.constSuper) {
      this.selectedUser.securityGroupOid = +this.selectedSecurityGroupOid;
      this.changeGroup.next(this.selectedUser.securityGroupOid);
    } else {
      if (+this.selectedSecurityGroupOid === CONSTANTS.SECURITY_GROUPING_OID.SUPERUSER) {
        this.selectedSecurityGroupOid = this.selectedUser.securityGroupOid;
        SwalDialog.show({
          title: 'Unable To Select',
          text: 'Only a super user has the ability to change the security group to a super user.',
          icon: 'warning',
          dangerMode: true,
        });
      }  else {
        this.selectedUser.securityGroupOid = +this.selectedSecurityGroupOid;
        this.changeGroup.next(this.selectedUser.securityGroupOid);
      }
    }
  }

  onEmailBlur(){
    this.emailBlur.next();
  }

  onGenerateRandomPassword(){
    this.generateRandomPassword.next();
  }


}

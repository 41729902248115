import { Component, OnInit } from '@angular/core';
import {
  Entity, MonthCloseState, MonthEndService, NutritionistProductionGrid,
  PDSService
} from '../../api/generated-api';
import {CONSTANTS} from '../../lib/constants';
import {AppUtilities} from '../../lib/appUtilities.service';
import {UserService} from "../../services/user.service";

@Component({
  selector: 'monthly-nutritionist-production',
  templateUrl: './monthly-nutritionist-production.component.html',
  styleUrls: ['./monthly-nutritionist-production.component.css']
})
export class MonthlyNutritionistProductionComponent implements OnInit {

  public nutritionistData: NutritionistProductionGrid[] = [];
  public monthCloseState: MonthCloseState = <MonthCloseState>{};
  public months: Array<{ nMonth: number, sMonth: string }> = CONSTANTS.MONTHS_SELECT_LIST;
  public years: Array<number> = CONSTANTS.YEARS;
  public selectedMonth: number = new Date().getMonth() + 1;
  public selectedYear: number = new Date().getFullYear();
  public appUtilities: AppUtilities = new AppUtilities();
  public monthString: string = '';
  public pageLoading: boolean = false;
  public loggedInEntity: Entity = <Entity>{};
  public constSuper: string = CONSTANTS.SECURITY_GROUPING.SUPERUSER;
  public constAdmin: string = CONSTANTS.SECURITY_GROUPING.ADMIN;
  public constNutritionist: string = CONSTANTS.SECURITY_GROUPING.NUTRITIONIST;

  constructor(private monthEnd: MonthEndService, private pdsService: PDSService, private userService: UserService) { }

  ngOnInit(): void {
	this.pageLoading = true;
    this.initiate();
	this.pageLoading = false;
  }

  async initiate(): Promise<void> {
    await this.setClosedState();
    await this.getLoggedInUser();
    this.getProductionData();

  }

  async getLoggedInUser(): Promise<void> {
    this.loggedInEntity = await this.userService.getLoggedInEntityUser();
  }

  async setClosedState() {
    this.monthCloseState = await this.monthEnd.getMonthCloseState().toPromise();
    this.selectedMonth = this.monthCloseState.currentMonth;
    this.selectedYear = this.monthCloseState.currentYear;
    this.setMonthString();
  }

  async getProductionData() {
    const res = await this.pdsService.getMonthlyNutritionistProductionByMonthAndYear(this.selectedMonth, this.selectedYear).toPromise();
    if(res.hasErrors) {
      this.nutritionistData = [];
      await this.appUtilities.showServerErrorSwal(res.errorMessage);
    } else {
      this.nutritionistData = res.data;
    }
  }

  getTotalByColumn(columnName: string) {
    return AppUtilities.addByColumn(this.nutritionistData, columnName);
  }

  selectDate() {
    this.setMonthString();
    this.getProductionData();
  }

  setMonthString() {
    this.monthString = this.months.find(month => +month.nMonth === +this.selectedMonth).sMonth;
  }

  public isHiddenFromNonShareholder() {
    if(this.loggedInEntity.securityGroupName === this.constSuper || (this.loggedInEntity.securityGroupName === this.constNutritionist && this.loggedInEntity.isShareholder) ) {
      return true;
    } else {
      return false;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import {CONSTANTS} from '../../lib/constants';
import {DairyProductionGrid, Entity, MonthCloseState, MonthEndService, ValuePair, PDSService} from '../../api/generated-api';
import {AppUtilities} from '../../lib/appUtilities.service';
import {UserService} from "../../services/user.service";

@Component({
  selector: 'view-by-dairy',
  templateUrl: './view-by-dairy.component.html',
  styleUrls: ['./view-by-dairy.component.css']
})
export class ViewByDairyComponent implements OnInit {

  public dairies: ValuePair[] = [];
  public regions: ValuePair[] = [];
  public dairyData: DairyProductionGrid[] = [];
  public monthCloseState: MonthCloseState = <MonthCloseState>{};
  public months: Array<{ nMonth: number, sMonth: string }> = CONSTANTS.MONTHS_SELECT_LIST;
  public years: Array<number> = CONSTANTS.YEARS;
  public selectedRegion: number = 0;
  public selectedMonth: number = new Date().getMonth() + 1;
  public selectedYear: number = new Date().getFullYear();
  public loggedInEntity: Entity = <Entity>{};
  public monthString: string = '';
  public constSuper: string = CONSTANTS.SECURITY_GROUPING.SUPERUSER;
  public constAdmin: string = CONSTANTS.SECURITY_GROUPING.ADMIN;
  public constNutritionist: string = CONSTANTS.SECURITY_GROUPING.NUTRITIONIST;
  public appUtilities: AppUtilities = new AppUtilities();
  public pdfLoading: boolean = false;

  constructor(private monthEnd: MonthEndService, private userService: UserService,private pdsService: PDSService) { }

  async ngOnInit(): Promise<void> {
    await this.getLoggedInUser();
    this.initiate();
  }

  async initiate(): Promise<void> {
    await this.setClosedState();
    this.getAllRegions();
    this.getProductionData();
  }

  async getAllRegions(): Promise<void> {
    this.regions = await this.pdsService.getAllRegionsList(true).toPromise();
  }

  async getLoggedInUser(): Promise<void> {
    this.loggedInEntity = await this.userService.getLoggedInEntityUser();
  }

  async setClosedState() {
    this.monthCloseState = await this.monthEnd.getMonthCloseState().toPromise();
    this.selectedMonth = this.monthCloseState.currentMonth;
    this.selectedYear = this.monthCloseState.currentYear;
    this.setMonthString();
  }

  async getProductionData() {
    const res = await this.monthEnd.getDairyProductionGridByLoggedInUsersOidByMonthAndYear(this.selectedMonth, this.selectedYear, this.selectedRegion).toPromise(); // tslint:disable-line
    if(res.hasErrors) {
      this.dairyData = [];
      await this.appUtilities.showServerErrorSwal(res.errorMessage);
    } else {
      this.dairyData = res.data.dairies;
    }
  }

  getTotalByColumn(columnName: string, data?) {
    return AppUtilities.addByColumn(data.deliveries, columnName);
  }

  selectRegion() {
    this.getProductionData();
  }

  selectDairy() {
    this.getProductionData();
  }

  selectDate() {
    this.setMonthString();
    this.getProductionData();
  }

  getParentTotalyByColumn(columnName: string) {
    return AppUtilities.addByColumn(this.dairyData, columnName);
  }

  setMonthString() {
    this.monthString = this.months.find(month => +month.nMonth === +this.selectedMonth).sMonth;
  }

  public isHiddenFromNonShareholder() {
    if(this.loggedInEntity.securityGroupName === this.constSuper || (this.loggedInEntity.securityGroupName === this.constNutritionist && this.loggedInEntity.isShareholder) ) {
      return true;
    } else {
      return false;
    }
  }

  async downloadPdf() {
    this.pdfLoading = true;
   const res = await this.monthEnd.generateDairyProductionPDF( this.selectedMonth, this.selectedYear, this.selectedRegion).toPromise();
   if(res && res.data && res.data != "") {
     this.openFileInNewTab(res.data);
   } else {
     //await this.appUtil.showServerErrorSwal(res.errorMessage);
   }
   this.pdfLoading = false;
  }

  openFileInNewTab(url): void {
    window.open(url);
  }

}

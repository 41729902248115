import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import {CONSTANTS} from '../../lib/constants';
import {DairyDTO, Entity, PDSService, ProductionGridView, Notification} from '../../api/generated-api';
import {AppUtilities} from '../../lib/appUtilities.service';
import {Router} from "@angular/router";
import { UrlObfuscationService } from 'src/app/lib/obfuscate.service';
import {SwalDialog} from "../../lib/swal-dialog";

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  loading: boolean = false;
  public loggedInUser: Entity = <Entity>{};
  public constMill: string = CONSTANTS.SECURITY_GROUPING.MILL;
  public constNutritionist: string = CONSTANTS.SECURITY_GROUPING.NUTRITIONIST;
  public constSuper: string = CONSTANTS.SECURITY_GROUPING.SUPERUSER;
  public constAdmin: string = CONSTANTS.SECURITY_GROUPING.ADMIN;
  public feedDeliveries: ProductionGridView[] = [];
  public currentMonth: number;
  public currentYear: number;
  public notifications: Notification[] = [];
  public dairies: DairyDTO[] = [];

  constructor( private userService: UserService, private pdsService: PDSService, private router: Router) {
  }

  async ngOnInit() {
    await this.getLoggedInUser();
    await this.getNotifications();
    await this.getDairies();
    // await this.getCurrentMonthState();
    this.loading = true;
    this.loading = false;
    // if (this.loggedInUser.securityGroupName === this.constMill || this.loggedInUser.securityGroupName === this.constNutritionist) {
    //   this.getAllProductionGridViews();
    // }
  }

  getStatusClass(isRead: boolean): string {
    return isRead ? 'badge-primary' : 'badge-secondary';
  }


  async getNotifications() {
    this.notifications = await this.pdsService.getListOfNotifications().toPromise();
    const notificationOidsToMarkRead = [];
    this.notifications.forEach(n => {
      if (!n.isRead) {
        notificationOidsToMarkRead.push(n.oid);
      }
    });
    if (notificationOidsToMarkRead.length > 0) {
      this.pdsService.markNotificationsAsRead(notificationOidsToMarkRead).toPromise();
    }
  }

  async getDairies() {
    const res = await this.pdsService.getRecentlyAddedDairies().toPromise();
    this.dairies = res.data;
  }

  async getCurrentMonthState() {
    const monthState = await this.userService.getMonthCloseState();
    this.currentMonth = monthState.currentMonth;
    this.currentYear = monthState.currentYear;
  }

  async getLoggedInUser() {
    const entity = await this.userService.getLoggedInEntityUser();
    if (entity) {
      this.loggedInUser = entity;
    }
  }

  async getAllProductionGridViews() {
    const res = await this.pdsService.getAllProductionGridViews(this.currentMonth, this.currentYear).toPromise();
    this.feedDeliveries = res.data;
  }

  getMonthDelivered(deliveryMonth: number): string {
    return CONSTANTS.MONTHS[deliveryMonth];
  }

  selectMonth() {
    this.getAllProductionGridViews();
  }

  getTotalByColumn(columnName: string) {
    return AppUtilities.addByColumn(this.feedDeliveries, columnName);
  }

  async clearAllNotifications() {
    const response = await SwalDialog.show({
      title: 'Are You Sure?',
      text: 'Are you sure you want to clear all of your notifications? Once removed they cannot be retrieved.',
      icon: 'warning',
      dangerMode: false,
      buttons: {
        test1: { text: 'No', value: CONSTANTS.SWAL_RESPONSE.NO},
        test2: { text: 'Yes', value: CONSTANTS.SWAL_RESPONSE.YES },
      }
    });
    if (response === CONSTANTS.SWAL_RESPONSE.YES) {
      const res = await this.pdsService.clearNotifications().toPromise();
      this.notifications = await this.pdsService.getListOfNotifications().toPromise();
    } else {
      return;
    }

  }

  async hideNotification(obj) {
    this.pdsService.hideNotification(obj).toPromise();
    await this.getNotifications();
  }

  navigateToNotification(url) {
    const parsedArray: string[] = url.split('/');
    // tslint:disable-next-line
    if (!isNaN(+parsedArray[parsedArray.length -1])) {
      // tslint:disable-next-line
      parsedArray[parsedArray.length -1] = UrlObfuscationService.obfuscate(parsedArray[parsedArray.length -1]);
      let newUrl: string = '';
      parsedArray.forEach(s => {
        newUrl += s + '/';
      });
      this.router.navigate(['' + newUrl]);
    } else {
      this.router.navigate(['' + url]);
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {DairyDTO, PDSService} from '../../api/generated-api';

export interface dairyDTOExtended extends DairyDTO {isChecked: boolean;} // tslint:disable-line

@Component({
  selector: 'add-dairy-modal',
  templateUrl: './add-dairy-modal.component.html',
  styleUrls: ['./add-dairy-modal.component.scss']
})
export class AddDairyModalComponent implements OnInit {

  public existingDairies: DairyDTO[] = [];
  public dairies: dairyDTOExtended[] = [];
  public selectAllCheckbox: boolean = false;
  public pageLoading: boolean = false;

  constructor(public activeModal: NgbActiveModal, private pdsService: PDSService) { }

  ngOnInit(): void {
    this.pageLoading = true;
    this.getDairiesList();
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

  async getDairiesList(): Promise<void> {
    try {
      const initialResponse = await this.pdsService.getAllDairyDTOs().toPromise();
      this.dairies = initialResponse.map(dairy => {
        if (this.existingDairies && this.existingDairies.find(d => d.oid === dairy.oid)) {
          const newDairy: dairyDTOExtended = <dairyDTOExtended>{...dairy};
          newDairy.isChecked = true;
          return newDairy;
        } else {
          const newDairy: dairyDTOExtended = <dairyDTOExtended>{...dairy};
          newDairy.isChecked = false;
          return newDairy;
        }
      });
      this.pageLoading = false;
    } catch (error) {
    }
  }

  public onItemCheck(event): void {
    if (!event.srcElement.checked && this.selectAllCheckbox) {
      this.selectAllCheckbox = false;
    }
  }

  addDairyList() {
    const selectedDairies = this.dairies.filter(dairy => {
      return dairy.isChecked;
    });
    this.activeModal.dismiss(selectedDairies);
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {SwalDialog} from '../../lib/swal-dialog';
import {LookupService} from '../../services/lookup.service';
import {Lookup, Product, PDSService} from '../../api/generated-api';

@Component({
  selector: 'add-product-modal',
  templateUrl: './add-product-modal.component.html',
  styleUrls: ['./add-product-modal.component.css']
})
export class AddProductModalComponent implements OnInit, OnDestroy {

  public product: Product = <Product>{};
  public productTypes: Lookup[] = [];
  public isSubmitted: boolean = false;
  public isEdit: boolean = false;
  public products: Product[] = [];

  constructor(public activeModal: NgbActiveModal, private pdsService: PDSService, private lookups: LookupService) { }

  async ngOnInit(): Promise<void> {
    // tslint:disable-next-line
    // TODO "Product" property may need some default values. Create an "init" method that fills out the product property with any needed default values
    this.productTypes = this.lookups.findLookupByLookupName('ProductType');
  }

  ngOnDestroy(): void {
    this.clearProperties();
  }

  async save(): Promise<void> {
    const isInvalidString: string = this.isInvalid();
    if (isInvalidString && isInvalidString !== '') {
      this.isSubmitted = true;
      const myhtml = document.createElement('div');
      myhtml.innerHTML = isInvalidString;
      SwalDialog.show({
        title: 'Validation Failed',
        content: myhtml,
        icon: 'error',
      });
      return;
    } else {
      this.isSubmitted = true;
      if (this.isValid()) {
        const saveResponse = await this.pdsService.addUpdateProduct(this.product).toPromise();
        this.activeModal.dismiss(this.product);
      }
    }
  }

  async isValid(): Promise<boolean> {
    if (!this.product.name || !this.product.lkpProductTypeOid || this.product.lkpProductTypeOid < 0) {
      return false;
    }
    if (this.product && this.product.name && this.product.name !== '') {
      return true;
    } else {
      return false;
    }
  }

  clearProperties() {
    this.product = <Product>{};
    this.isSubmitted = false;
  }

  cancel(): void {
    this.clearProperties();
    this.activeModal.dismiss();
  }

  isInvalid(): string {
    let rString: string = '';
    if (!this.product.name || this.product.name === '' || this.product.name === undefined) {
      rString = rString.concat('Must create a product name  </br>');
    }
    if (!this.product.lkpProductTypeOid || this.product.lkpProductTypeOid < 0) {
      rString = rString.concat('Must select a Product Type  </br>');
    }
    return rString;
  }

}

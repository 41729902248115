export const CONSTANTS = {
  ATTACHMENTS: {
    IMG: 'img',
    PDF: 'pdf',
    CSV: 'csv',
    TXT: 'txt'
  },
  TARGET_TABLE: {
    ENTITY: 'Entity'
  },
  UNIVERSAL_INDIVIDUAL_FEEDS: {
    UNIVERSAL: 'Universal Feeds',
    INDIVIDUAL: 'All Feeds'
  },
  MAPPING_TABLE: {
    NUTRITIONISTS: 'Nutritionists',
    MILLS: 'Mills',
    FEEDS: 'Feeds',
    PRODUCTS: 'Products',
    REGIONS: 'Regions',
    DAIRIES: 'Dairies'
  },
  ALLOWED_FILE_TYPES: {
    IMAGE: ['png', 'jpg', 'jpeg', 'tiff', 'tif', 'gif', 'bmp'],
    // tslint:disable-next-line
    DOCUMENT: ['doc', 'docx', 'rtf', 'psd', 'ai', 'indd', 'odt', 'txt', 'csv', 'xls', 'xlsx', 'ppt', 'pptx', 'pub', 'pdf'], // Also miscellaneous
    VIDEO: ['mp4', 'mpg', 'ogg', 'mov', 'avi', 'wmv'],
    AUDIO: ['mp3', 'wav']
  },
  LOCAL_STORAGE: {
    STARTUP_PAYLOAD: 'User.StartupPayload',
    STARTUP_PAYLOAD_DATE: 'User.StartupPayloadDate',
    AUTH_TOKEN: 'User.AuthToken',
    PREFERENCES: 'User.Preferences',
    ENTITY: 'loggedInUser',
    MONTH_CLOSE_STATE: 'monthCloseState',
    SECURITY_GROUP: 'securityGroup',
    LOOKUPS: 'lookups'
  },
  SECURITY_GROUPING: {
    ADMIN: 'Admin',
    SUPERUSER: 'SuperUser',
    MILL: 'Mill',
    NUTRITIONIST: 'Nutritionist'
  },
  AMOUNT_TYPE_OID: {
    FLAT: 24,
    PERCENTAGE: 26
  },
  AMOUNT_TYPE: {
    PERCENTAGE: '% of Profit',
    FLAT: 'Flat Amt.',
    CALC: 'Calc\'d'
  },
  SECURITY_GROUPING_OID: {
    ADMIN: 2,
    SUPERUSER: 4,
    MILL: 3,
    NUTRITIONIST: 1
  },
  SECURITY_GROUP_DROPDOWN_LIST: [{ value: 'Nutritionist', oid: 1 }, { value: 'Admin', oid: 2 }, { value: 'Mill', oid: 3 }, { value: 'SuperUser', oid: 4 }],
  FILTERED_SECURITY_GROUP_DROPDOWN_LIST: [{ value: 'Nutritionist', oid: 1 }, { value: 'Admin', oid: 2 }, { value: 'Mill', oid: 3 }],
  SUCCESS: {

  },
  ERROR: {
    INVALID_DATA_ERROR: 'Error',
    UNSAVED_CHANGES_TITLE: 'Unsaved Changes',
    UNSAVED_CHANGES_TEXT: 'Navigating away will lose any unsaved changes. Would you like to save your changes before continuing?',
    SERVER_ERROR_TITLE: 'Server Error',
  },
  SWAL_RESPONSE: {
    YES: 'Yes',
    NO: 'No',
    CANCEL: 'Cancel',
    OK: 'OK'
  },
  MONTHS: [
    '', 'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ],
  YEARS: [
    2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029,
    2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039,
    2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049,
    2050, 2051, 2052, 2053, 2054, 2055
  ],
  LOWEST_YEAR: 2019,
  HIGHEST_YEAR: 2055,
  PRODUCTION_MONTHS_SELECT_LIST: [
    { nMonth: 1, sMonth: 'January' },
    { nMonth: 2, sMonth: 'February' },
    { nMonth: 3, sMonth: 'March' },
    { nMonth: 4, sMonth: 'April' },
    { nMonth: 5, sMonth: 'May' },
    { nMonth: 6, sMonth: 'June' },
    { nMonth: 7, sMonth: 'July' },
    { nMonth: 8, sMonth: 'August' },
    { nMonth: 9, sMonth: 'September' },
    { nMonth: 10, sMonth: 'October' },
    { nMonth: 11, sMonth: 'November' },
    { nMonth: 12, sMonth: 'December' },
    { nMonth: 13, sMonth: 'Last 3 Months' },
    { nMonth: 14, sMonth: 'Last 6 Months' },
    { nMonth: 15, sMonth: 'Last 12 Months' },
  ],
  MONTHS_SELECT_LIST: [
    { nMonth: 1, sMonth: 'January' },
    { nMonth: 2, sMonth: 'February' },
    { nMonth: 3, sMonth: 'March' },
    { nMonth: 4, sMonth: 'April' },
    { nMonth: 5, sMonth: 'May' },
    { nMonth: 6, sMonth: 'June' },
    { nMonth: 7, sMonth: 'July' },
    { nMonth: 8, sMonth: 'August' },
    { nMonth: 9, sMonth: 'September' },
    { nMonth: 10, sMonth: 'October' },
    { nMonth: 11, sMonth: 'November' },
    { nMonth: 12, sMonth: 'December' },
  ],


};

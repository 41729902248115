import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { JWTService } from './jwt.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private router: Router, private jwt: JWTService) { }

  /**
   * Returns true or false based on if they have a valid authtoken
   * @returns {boolean}
   */
  canActivate(): boolean {
    if (this.jwt.checkToken()) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}

import { Injectable } from '@angular/core';
import { Lookup } from '../api/generated-api';

@Injectable()
export class LookupService {

  public lookups: Lookup[] = [];

  constructor() { }

  /**
   * Updates lookups
   * @param newLookups {Lookup[]}
   */
  setLookups(newLookups: Lookup[]): void {
    this.lookups = newLookups;
  }

  /**
   * Gets lookups from localstorage
   */
  getLookups(): Lookup[] {
    return this.lookups;
  }

  /**
   * Finds lookups in lookup behavior subject by LookupName
   * @param name {string}
   * @returns {any[]}
   */
  findLookupByLookupName(name: string): Lookup[] {
    return this.getLookups().filter((lookup: Lookup) => lookup.lookupName === name);
  }

  /**
   * Finds lookup by constant value
   * @param constant {string}
   * @returns {any[]}
   */
  findLookupByConstantValue(constant: string): Lookup {
    return this.getLookups().find((lookup: Lookup) => lookup.constantValue === constant);
  }

  findLookupOidByConstantValue(constant: string): number {
      const lookupObj: Lookup = this.getLookups().find((lookup: Lookup) => lookup.constantValue === constant);
      return lookupObj ? lookupObj.oid : null;
  }

  /**
   * Find lookup by oid
   * @param oid {number}
   */
  findLookupByLookupOid(oid: number): Lookup {
    return this.getLookups().find((lookup: Lookup) => lookup.oid === oid);
  }

  /**
   * Takes in any array that might have lookup foreign keys
   * adds lookup objects for each lkpOid it finds, binding to the lkp name plus 'Object' and returns the list
   * @param list
   * @returns {any}
   */
  transformLookupToData(list) {
    list.forEach(item => {
      const keyNames = Object.keys(item);
      keyNames.forEach(key => {
        if (key.startsWith('lkp')) {
          const lkpOid = item[key];
          item[key + 'Object'] = this.findLookupByLookupOid(lkpOid);
        }
      });
    });
    return list;
  }

}

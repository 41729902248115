import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {FeedDelivery} from '../../api/generated-api';

@Component({
  selector: 'monthly-production',
  templateUrl: './monthly-production.component.html',
  styleUrls: ['./monthly-production.component.scss']
})
export class MonthlyProductionComponent implements OnInit {

  public fd: FeedDelivery = <FeedDelivery>{};

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  create(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { UserService } from './services/user.service';
import { MonthEndService, MonthCloseState } from './api/generated-api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private monthEndService: MonthEndService,
    private userService: UserService) {
  }

  async ngOnInit(){
    const monthCloseState: MonthCloseState = await this.monthEndService.getMonthCloseState().toPromise();
    this.userService.setMonthCloseState(monthCloseState);
  }

  async appStartup() {

  }

}

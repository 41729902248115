import { Component, OnInit } from '@angular/core';
import {PDSService, Product} from '../../api/generated-api';

@Component({
  selector: 'view-products',
  templateUrl: './view-products.component.html',
  styleUrls: ['./view-products.component.css']
})
export class ViewProductsComponent implements OnInit {

  public products: Product[] = [];
  public masterProductsList: Product[] = [];
  public showPreference: string = 'All';

  constructor(private pds: PDSService) { }

  ngOnInit(): void {
    this.initiate();
  }

  async initiate(): Promise<void> {
      this.products = await this.pds.getAllProducts().toPromise();
      this.masterProductsList = JSON.parse(JSON.stringify(this.products));
  }

  showPreferenceChange(event): void {
    if (!this.masterProductsList.length) {
      this.masterProductsList = this.products;
    }
    this.showPreference = event;
    if (this.showPreference === 'Active') {
      this.products = this.masterProductsList.filter(item => {
        return item.isActive;
      });
    } else if (this.showPreference === 'Inactive') {
      this.products = this.masterProductsList.filter(item => {
        return !item.isActive;
      });
    } else {
      this.products = JSON.parse(JSON.stringify(this.masterProductsList));
    }
  }




}

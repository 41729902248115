import {Component, ContentChild, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {CwColumnDefinition, CwColumnDefinitionLookup} from './cw-grid-common';

@Component({
  selector: 'cw-column',
  template: ''
})
export class CwColumnComponent implements CwColumnDefinition {

    @Input() public name: string;

    @Input() public width?: number;

    @Input() public type: undefined | 'string' | 'number' | 'date' | 'html' | 'lookup' | 'dateRange' | 'file' | 'boolean';

    @Input() public filterable: boolean = true;

    @Input() public canEdit: boolean = false;

    @Input() public style?: string;

    @Input() public class?: string;

    @Input() public lookup?: CwColumnDefinitionLookup;

    @Input() public isBulkEditable?: boolean;

    @Output() public onBulkUpdate?: EventEmitter<any> = new EventEmitter<any>(); // tslint:disable-line

    @Input() public shouldDisplayNotAvailable?: boolean;

    @Input() public dateStartKey?: string;

    @Input() public dateEndKey?: string;

    @Input() public dataKey?: string;

    @Input() public notAvailableLabel?: string;

    @Input() public hideDuplicate?: boolean;
    
    @Input() public isHidden?: boolean;

    @Input() public sortOrder?: number;

    @ContentChild('headerTemplate', null) headerTemplate: TemplateRef<any>;

    @ContentChild('cellTemplate', null) cellTemplate: TemplateRef<any>;

    @ContentChild('editTemplate', null) editTemplate: TemplateRef<any>;

    @ContentChild('footerTemplate', null) footerTemplate: TemplateRef<any>;

}

import { Injectable } from '@angular/core';

@Injectable()
export class UrlObfuscationService {

  static obfuscate(string: string | number): string {
    const obfuscatedString: string = btoa(string + '');
    return obfuscatedString.replace(/=/g, '');
  }

  static deobfuscate(string: string) {
    return atob(string);
  }

  constructor() { }
}

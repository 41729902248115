import { Component, OnInit } from '@angular/core';
import {PDSService} from '../../api/generated-api';
import {Router} from '@angular/router';
import {UrlObfuscationService} from '../../lib/obfuscate.service';

@Component({
  selector: 'incomplete-setup',
  templateUrl: './incomplete-setup.component.html',
  styleUrls: ['./incomplete-setup.component.css']
})
export class IncompleteSetupComponent implements OnInit {

  public isSaving: boolean = false;
  public masterList;
  public pageLoading: boolean = false;


  constructor(private pds: PDSService, private router: Router) {}

  ngOnInit(): void {
    this.pageLoading = true;
    this.initiate();
  }



  async initiate() {
    this.masterList = await this.pds.getIncompleteAccountSetup().toPromise();
    // console.log(this.masterList, 'master list')
    this.pageLoading = false;
  }

  navToProfile(context, oid?) {
    if(context === 'mapping') {
      this.router.navigate([`/mapping` + `/${UrlObfuscationService.obfuscate(oid)}`], { queryParams: { mappingTab: 'Mill Mapping'} })
    } else {
      // console.log(context, 'context');
      // console.log(oid, 'oid');
      if (oid) {
        this.router.navigate([`/` + context + `/${UrlObfuscationService.obfuscate(oid)}`]);
      } else {
        this.router.navigate([`/` + context]);
      }
    }
  }

}

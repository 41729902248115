import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { MainComponent } from './components/main/main.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { RegisterComponent } from './pages/register/register.component';
import { FeedsComponent } from './pages/feeds/feeds.component';
import { DairiesComponent } from './pages/dairies/dairies.component';
import { MillsComponent } from './pages/mills/mills.component';
import { MonthlyFeedProductionComponent } from './pages/monthly-feed-production/monthly-feed-production.component'; // tslint:disable-line
import { NutritionistWorksheetComponent } from './pages/nutritionist-worksheet/nutritionist-worksheet.component';
import { ProductsComponent } from './pages/products/products.component';
import { RegionsComponent } from './pages/regions/regions.component';
import { UsersComponent } from './pages/users/users.component';
import { MonthlyProductionComponent } from './pages/monthly-production/monthly-production.component';
import { AuthGuardService } from './lib/authguard.service';
import { CanDeactivateGuard } from './lib/canDeactivate.service';
import { FeedDeliveryEntryComponent } from './pages/feed-delivery-entry/feed-delivery-entry.component';
import {MonthEndComponent} from './pages/month-end/month-end.component';
import {NotificationsComponent} from './pages/notifications/notifications.component';
import {ViewByNutritionistComponent} from './pages/view-by-nutritionist/view-by-nutritionist.component';
import {ViewByMillComponent} from './pages/view-by-mill/view-by-mill.component';
import {ViewByDairyComponent} from './pages/view-by-dairy/view-by-dairy.component';
import {MappingComponent} from './pages/mapping/mapping.component';
import {IncompleteSetupComponent} from './pages/incomplete-setup/incomplete-setup.component';
import {NutritionistPayablesComponent} from './pages/nutritionist-payables/nutritionist-payables.component';
import {PdsGrossProfitComponent} from './pages/pds-gross-profit/pds-gross-profit.component';
import {ViewProductsComponent} from './pages/view-products/view-products.component';
import {YtdMtdComponent} from "./pages/ytd-mtd/ytd-mtd.component";
import {CowCountComponent} from "./pages/cow-count/cow-count.component";
import {MonthlyDairyProductionComponent} from "./pages/monthly-dairy-production/monthly-dairy-production.component";
import {MonthlyNutritionistProductionComponent} from "./pages/monthly-nutritionist-production/monthly-nutritionist-production.component";
import {MonthlyMillProductionComponent} from "./pages/monthly-mill-production/monthly-mill-production.component";
import {NutritionistDeliveryDetailComponent} from "./pages/nutritionist-delivery-detail/nutritionist-delivery-detail.component";
import {MonthlyDairyProductionVsPlanComponent} from "./pages/monthly-dairy-production-vs-plan/monthly-dairy-production-vs-plan.component";
import {NutritionistProductionSummaryComponent} from "./pages/nutritionist-production-summary/nutritionist-production-summary.component";
import {CowCountByMonthComponent} from "./pages/cow-count-by-month/cow-count-by-month.component";
import {MillDeliveryDetailComponent} from "./pages/mill-delivery-detail/mill-delivery-detail.component";
import {ProductUtilizationComponent} from "./pages/ProductUtilization/productUtilization.component";



const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '', component: MainComponent, children: [
    { path: 'home', component: HomeComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'feeds', component: FeedsComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'feeds/:id', component: FeedsComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'dairies', component: DairiesComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'dairies/:id', component: DairiesComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'mills', component: MillsComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'mills/:id', component: MillsComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'monthly-feed-production-delivery', component: MonthlyFeedProductionComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] }, // tslint:disable-line
    { path: 'monthly-production', component: MonthlyProductionComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] }, // tslint:disable-line
    { path: 'feed-delivery-entry', component: FeedDeliveryEntryComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] }, // tslint:disable-line
    { path: 'cow-count', component: CowCountComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] }, // tslint:disable-line
    { path: 'cow-count-by-month', component: CowCountByMonthComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] }, // tslint:disable-line
    { path: 'nutritionist-worksheet', component: NutritionistWorksheetComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] }, // tslint:disable-line
    { path: 'nutritionist-worksheet/:id', component: NutritionistWorksheetComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] }, // tslint:disable-line
    { path: 'month-end', component: MonthEndComponent, canActivate: [AuthGuardService] },
    { path: 'all-products', component: ProductsComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'mapping', component: MappingComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'mapping/:id', component: MappingComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'nutritionist-delivery-detail', component: NutritionistDeliveryDetailComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'nutritionist-delivery-detail/:id', component: NutritionistDeliveryDetailComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'mill-delivery-detail', component: MillDeliveryDetailComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'mill-delivery-detail/:id', component: MillDeliveryDetailComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },


    { path: 'product-utilization', component: ProductUtilizationComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'regions', component: RegionsComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'regions/:id', component: RegionsComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'production-view-by-dairy', component: ViewByDairyComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },// tslint:disable-line
    { path: 'monthly-dairy-production-vs-plan', component: MonthlyDairyProductionVsPlanComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },// tslint:disable-line
    { path: 'monthly-dairy-production', component: MonthlyDairyProductionComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },// tslint:disable-line
    { path: 'monthly-mill-production', component: MonthlyMillProductionComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },// tslint:disable-line
    { path: 'monthly-nutritionist-production', component: MonthlyNutritionistProductionComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },// tslint:disable-line
    { path: 'nutritionist-production-summary', component: NutritionistProductionSummaryComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },// tslint:disable-line
    { path: 'production-view-by-mill', component: ViewByMillComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },// tslint:disable-line
    { path: 'production-view-by-nutritionist', component: ViewByNutritionistComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },// tslint:disable-line
    { path: 'users/:id', component: UsersComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'users', component: UsersComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'view-products', component: ViewProductsComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'incomplete-setup', component: IncompleteSetupComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'gross-profit', component: PdsGrossProfitComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'ytd-mtd', component: YtdMtdComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },
    { path: 'nutritionist-payables', component: NutritionistPayablesComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard] },// tslint:disable-line
  ]},
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'register', component: RegisterComponent },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

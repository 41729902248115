import { Component, OnInit } from '@angular/core';
import {CONSTANTS} from '../../lib/constants';
import {ClientResult, MonthEndService, NutritionistPayableRecord, PDSService} from '../../api/generated-api';
import {UrlObfuscationService} from "../../lib/obfuscate.service";
import {Router} from "@angular/router";
import {AppUtilities} from "../../lib/appUtilities.service";

@Component({
  selector: 'nutritionist-payables',
  templateUrl: './nutritionist-payables.component.html',
  styleUrls: ['./nutritionist-payables.component.css']
})
export class NutritionistPayablesComponent implements OnInit {

  public months: Array<{ nMonth: number, sMonth: string }> = CONSTANTS.MONTHS_SELECT_LIST;
  public years: Array<number> = CONSTANTS.YEARS;
  public selectedMonth: number;
  public selectedYear: number;
  public payables: NutritionistPayableRecord[] = [];
  public masterPayables: NutritionistPayableRecord[] = [];
  public monthString: string = '';
  public showWithPay: string = 'true';
  public appUtilities: AppUtilities = new AppUtilities();

  constructor(private pds: PDSService,
              private monthEnd: MonthEndService,
              private router: Router) { }

  ngOnInit() {
    this.initiate();
  }

  async initiate() {
    await this.setClosedState();
    await this.getPayablesList();
    this.changePreference(this.showWithPay);
  }

  async setClosedState() {
    const monthCloseState = await this.monthEnd.getMonthCloseState().toPromise();
    this.selectedMonth = monthCloseState.currentMonth;
    this.selectedYear = monthCloseState.currentYear;
    this.setMonthString();
  }

  async getPayablesList() {
    const res = await this.pds.getNutritionistPayableGridByMonthAndYear(+this.selectedMonth, +this.selectedYear).toPromise();
    if(res.hasErrors) {
      this.masterPayables = [];
      await this.appUtilities.showServerErrorSwal(res.errorMessage);
    } else {
      this.payables = res.data;
      this.masterPayables = this.payables;
    }

  }

  selectDate() {
    this.setMonthString();
    this.getPayablesList();
  }

  setMonthString() {
    this.monthString = this.months.find(month => +month.nMonth === +this.selectedMonth).sMonth;
  }

  changePreference(activeString: string) {
    this.showWithPay = activeString;
    if (this.showWithPay === 'true') {
      this.payables = this.masterPayables.filter(payable => {
        return payable.payable != 0
      })
    } else {
      this.payables = this.masterPayables;
    }

  }

  navToWorksheet(oid) {
        this.router.navigate([`/nutritionist-worksheet`  + `/${UrlObfuscationService.obfuscate(oid)}`]);
  }

}

import { CwGridDefinition } from './cw-grid-common';

export const CwGridDefinitions: { [key: string]: CwGridDefinition } = {
  gridPreference: {
    columns: {
      isDefault: { type: 'string', columnTitle: 'Default', filterable: false, width: 80},
      viewName: { type: 'string', columnTitle: 'View Name', filterable: false},
      action: { type: 'string', columnTitle: '', filterable: false, width: 150},
      deleteLayout: { type: 'string', columnTitle: 'Delete', filterable: false}
    }
  },
  home: {
    columns: {
      id: { type: 'number', columnTitle: 'ID', filterable: true, width: 80},
      firstName: { type: 'string', columnTitle: 'First Name', filterable: true, width: 100},
      lastName: { type: 'string', columnTitle: 'Last Name', filterable: true, width: 100},
      email: { type: 'string', columnTitle: 'Email', filterable: true, width: 100},
      phoneNumber: { type: 'string', columnTitle: 'Phone Number', filterable: true, width: 100},
    }
  },

  dairies: {
    tableName: 'Associated Dairies',
    columns: {
      companyName: { type: 'string', columnTitle: 'Name', filterable: true, width: 80},
      city: { type: 'string', columnTitle: 'City', filterable: true, width: 100},
      state: { type: 'string', columnTitle: 'State', filterable: true, width: 100},
      nutritionist: { type: 'string', columnTitle: 'Nutritionist', filterable: true, width: 100},
      phone: { type: 'string', columnTitle: 'Phone', filterable: true, width: 100},
      email: { type: 'string', columnTitle: 'Email', filterable: true, width: 100},
      dairyStatus: { type: 'string', columnTitle: 'Active/Inactive', filterable: true, width: 80}
    }
  },
  recentlyAddedDairies: {
    tableName: 'Recently Added Dairies',
    columns: {
      companyName: { type: 'string', columnTitle: 'Dairy Name', filterable: true, width: 80},
      fullAddress: { type: 'string', columnTitle: 'Address', filterable: true, width: 100},
      startDate: { type: 'string', columnTitle: 'Start Date', filterable: true, width: 100}
    }
  },
  productUtilization: {
    columns: {
      millName: { type: 'string', columnTitle: 'Mill', filterable: true, width: 100},
      productName: { type: 'string', columnTitle: 'Product', filterable: true, width: 100},
      lbs: { type: 'number', columnTitle: 'Lbs', filterable: true, width: 100},
      tons: { type: 'number', columnTitle: 'Tons', filterable: true, width: 100},
      month: { type: 'number', columnTitle: 'Month', filterable: true, width: 100},
      year: { type: 'number', columnTitle: 'Year', filterable: true, width: 100}
    }
  },
  products: {
    columns: {
      name: { type: 'string', columnTitle: 'Product Name', filterable: true, width: 80},
      productType: { type: 'string', columnTitle: 'Product Type', filterable: true, width: 100},
      description: { type: 'string', columnTitle: 'Description', filterable: true, width: 100},
      profitPerPound: { type: 'number', columnTitle: 'Profit Per Lb.', filterable: true, width: 100},
      action: { type: 'string', columnTitle: 'Active on/off', filterable: false, width: 30, },
      isActive: { type: 'string', columnTitle: 'Active', filterable: false, width: 20, },
      lkpProductTypeOid: { type: 'number', columnTitle: 'Product Type ID'}
    }
  },
  dairyFeedPlans: {
    columns: {
      feedName: { type: 'string', columnTitle: 'Feed', },
      poundsPerDay: { type: 'number', columnTitle: 'Lbs. Per Day', },
      numberOfCows: { type: 'number', columnTitle: '# of Cows', },
      monthlyTotal: { type: 'number', columnTitle: 'Lbs. Per Month', },
      action: { type: 'string', columnTitle: '', filterable: false, width: 80, },
    }
  },
  feedDeliveryEntry: {
    columns: {
      nutritionistName: { type: 'string', columnTitle: 'Nutritionist', },
      millName: { type: 'string', columnTitle: 'Mill',  width: 150},
      dairyName: { type: 'string', columnTitle: 'Dairy',  width: 200},
      feedName: { type: 'string', columnTitle: 'Feed',  width: 200},
      deliveryMonth: { type: 'number', columnTitle: 'Month Delivered',  width: 125},
      deliveryYear: { type: 'date', columnTitle: 'Year Delivered',  width: 125},
      tons: { type: 'number', columnTitle: 'Tons', width: 100, isShowFooter: true},
      productLbs: { type: 'number', columnTitle: 'Product (lbs)', },
      grossProfit: { type: 'number', columnTitle: 'Profit', },
      action: { type: 'string', columnTitle: '', filterable: false, width: 90, },
      recipe: { type: 'string', columnTitle: 'Feed Recipe', filterable: false, width: 400, },
      popupAction: { type: 'string', filterable: false, width: 30 },
    }
  },

  profitBreakdownGrid: {
    columns: {
      productName: { type: 'string', columnTitle: 'Product', width: 250 },
      profitPerPound: { type: 'string', columnTitle: 'Prof. per Lb.', width: 100},
      lbsPerTon: { type: 'string', columnTitle: 'Lbs per Ton', width: 100},
      totalPounds: { type: 'string', columnTitle: 'Total Lbs', width: 100},
      productProfit: { type: 'string', columnTitle: 'Profit', width: 100},
    }
  },


  monthlyFeedProduction: {
    columns: {
      mill: { type: 'string', columnTitle: 'Mill', },
      nutritionist: { type: 'string', columnTitle: 'Nutritionist', },
      nutritionist_Share: { type: 'number', columnTitle: 'Share %', },
      dairy: { type: 'string', columnTitle: 'Dairy', },
      feed: { type: 'string', columnTitle: 'Feed', },
      deliveryMonth: { type: 'string', columnTitle: 'Month',  width: 125},
      deliveryYear: { type: 'string', columnTitle: 'Year',  width: 125},
      nutritionist_Tons: { type: 'number', columnTitle: 'Tons', isShowFooter: true, isDecimal: true},
      product: { type: 'string', columnTitle: 'Product', width: 300},
      lbsPerTon: { type: 'number', columnTitle: 'LBS Per Ton', },
      nutritionist_TotalLbs: { type: 'number', columnTitle: 'Total LBS', },
      nutritionist_Payable: { type: 'number', columnTitle: 'Earnings', isShowFooter: true, isDecimal: true},
      dairy_retainer: { type: 'number', columnTitle: 'Retainer', },
      nutritionist_Commission: { type: 'number', columnTitle: 'Commission', },
      nutritionist_Profit: { type: 'number', columnTitle: 'Total Profit',},
      net: { type: 'number', columnTitle: 'Nutr. Profit', },
      nutritionist_GrossProfit: { type: 'number', columnTitle: 'Feed Profit', isShowFooter: true, isDecimal: true},
      createdBy: { type: 'string', columnTitle: 'Entered By'},
      fd_Oid: {type: 'string', columnTitle: 'Delivery ID'}
    }
  },
  monthlyFeedProductionMill: {
    columns: {
      dairy: { type: 'string', columnTitle: 'Dairy', },
      fd_Oid: {type: 'string', columnTitle: 'Delivery ID'},
      feed: { type: 'string', columnTitle: 'Feed', },
      deliveryMonth: { type: 'string', columnTitle: 'Month Delivered', },
      deliveryYear: { type: 'string', columnTitle: 'Year Delivered', },
      nutritionist_Tons: { type: 'number', columnTitle: 'Tons', isShowFooter: true, isDecimal: true},
      createdBy: { type: 'string', columnTitle: 'Entered By', },
    }
  },
  monthlyFeedProductionNutritionist: {
    columns: {
      mill: { type: 'string', columnTitle: 'Mill', },
      dairy: { type: 'string', columnTitle: 'Dairy', },
      fd_Oid: {type: 'string', columnTitle: 'Delivery ID'},
      feed: { type: 'string', columnTitle: 'Feed', },
      deliveryMonth: { type: 'string', columnTitle: 'Month Delivered', },
      deliveryYear: { type: 'string', columnTitle: 'Year Delivered', },
      nutritionist_Tons: { type: 'number', columnTitle: 'Tons', isShowFooter: true, isDecimal: true},
      product: { type: 'string', columnTitle: 'Product', width: 300},
      lbsPerTon: { type: 'number', columnTitle: 'LBS Per Ton', },
      nutritionist_TotalLbs: { type: 'number', columnTitle: 'Total LBS', },
      nutritionist_GrossProfit: { type: 'number', columnTitle: 'Feed Profit', isShowFooter: true, isDecimal: true},
      nutritionist_Share: { type: 'number', columnTitle: 'Share %', },
      nutritionist_Payable: { type: 'number', columnTitle: 'Earnings', isShowFooter: true, isDecimal: true},
      createdBy: { type: 'string', columnTitle: 'Entered By', },
      dairy_retainer: { type: 'number', columnTitle: 'Retainer', },
      nutritionist_Commission: { type: 'number', columnTitle: 'Commission', },
      totalProfit: { type: 'number', columnTitle: 'Total Profit', },
    }
  },
  nutritionistProductionWorksheet: {
    tableName: 'Worksheet',
    columns: {
      millName: { type: 'string', columnTitle: 'Mill' },
      dairyName: { type: 'string', columnTitle: 'Dairy', width: 250},
      feedName: { type: 'string', columnTitle: 'Feed' },
      date: { type: 'date', columnTitle: 'Date' },
      tons: { type: 'number', columnTitle: 'Tons' },
      product: { type: 'string', columnTitle: 'Product' },
      lbsPerTon: { type: 'number', columnTitle: 'LBS Per Ton' },
      totalLbs: { type: 'number', columnTitle: 'Total LBS' },
      nutritionist_Profit: { type: 'number', columnTitle: 'Profit' },
      retainer: { type: 'number', columnTitle: 'Retainer', width: 100, isShowFooter: true, isDecimal: true, isShowCurrencySign: true },
      nutritionistRetainer: { type: 'number', columnTitle: 'Nutr. Retainer', width: 100 },
      nutritionistFeedProfit: { type: 'number', columnTitle: 'Nutr. Feed Profit', width: 100 },
      percentage: { type: 'number', columnTitle: '%' },
      payable: { type: 'number', columnTitle: 'Earnings' },
      createdBy: { type: 'string', columnTitle: 'Entered By' },
      numberOfCows: { type: 'number', columnTitle: 'Cows', width: 100, isShowFooter: true },
      monthlyRetainer: { type: 'number', columnTitle: 'Retainer Fee', width: 100 },
      nutritionistName: { type: 'string', columnTitle: 'Nutritionist' },
      nutritionistShare: { type: 'number', columnTitle: 'Nutritionist Share', width: 100 },
      grossProfit: { type: 'number', columnTitle: 'Feed Profit', width: 100 },
      totalProfit: { type: 'number', columnTitle: 'Gross Margin', width: 150, isShowFooter: true, isDecimal: true, isShowCurrencySign: true },
      totalProfit_Nutritionist: { type: 'number', columnTitle: 'Gross Margin', width: 150, isShowFooter: true, isDecimal: true, isShowCurrencySign: true},
      feedProfit: { type: 'number', columnTitle: 'Gross Margin', width: 150 },
      nutritionistPay: {type: 'number', columnTitle: 'Nutr. Pay', width: 100, isShowFooter: true, isDecimal: true, isShowCurrencySign: true}
    }
  },
  nutritionistDeliveries: {
    columns: {
      millName: { type: 'string', columnTitle: 'Mill',  width: 200},
      dairyName: { type: 'string', columnTitle: 'Dairy', width: 300},
      feedName: { type: 'string', columnTitle: 'Feed', width: 250 },
      productName: { type: 'string', columnTitle: 'Product',  width: 100},
      lbsOfProduct: { type: 'number', columnTitle: 'Usage',  width: 105, isShowFooter: true, isDecimal: true},
      productLbsPerTon: { type: 'number', columnTitle: 'Rate',  width: 105, isDecimal: true},
      feedTonsDelivered: { type: 'number', columnTitle: 'Tons',  width: 100, isShowFooter: true, isDecimal: true},
      profitPerPound: { type: 'number', columnTitle: 'Premix $',  width: 105},
      lineItemProfit: { type: 'number', columnTitle: 'Gross Margin',  width: 115, isShowFooter: true, isDecimal: true, isShowCurrencySign: true },
      nutritionistPay: { type: 'number', columnTitle: 'Nutritionist Pay',  width: 120},
      nutritionistCommission: { type: 'number', columnTitle: 'Commission',  width: 125},
      nutritionistShare: { type: 'number', columnTitle: 'Share %',  width: 125},
      grossProfit_Nutritionist: { type: 'number', columnTitle: 'Nutr. Profit',  width: 125},
      numberOfCows: { type: 'number', columnTitle: 'Cows',  width: 125},
      feedProfit: { type: 'number', columnTitle: 'Gross Margin',  width: 125, isShowFooter: true, isDecimal: true},
    }
  },
  viewNutritionistAdjustments: {
    tableName: 'Adjustments',
    columns: {
      name: { type: 'string', columnTitle: 'Name', },
      type: { type: 'string', columnTitle: 'Type', },
      amount: { type: 'number', columnTitle: 'Amount', },
      total: { type: 'number', columnTitle: 'Total', },
    }
  },
  nutritionistAdjustments: {
    columns: {
      description: { type: 'string', columnTitle: 'Name', },
      amountType: { type: 'string', columnTitle: 'Type', width: 170 },
      grossPay: { type: 'number', columnTitle: 'Nutr. Pay', width: 170, isShowFooter: true, isDecimal: true, isShowCurrencySign: true },
      // profit: { type: 'number', columnTitle: 'Production', width: 170 },
      amount: { type: 'number', columnTitle: 'Amount', width: 170 },
      total: { type: 'number', columnTitle: 'Total', width: 170 },
      action: { type: 'string', columnTitle: '', filterable: false, width: 80, },
    }
  },
  compAdjustments: {
    tableName: 'Monthly Comp Adjustments',
    columns: {
      description: { type: 'string', columnTitle: 'Description'},
      ytdThreshhold: { type: 'number', columnTitle: 'YTD Threshold', width: 120},
      mtdThreshhold: { type: 'number', columnTitle: 'MTD Threshold', width: 120},
      percentage: { type: 'number', columnTitle: '% Profit', width: 100},
      flatAmount: { type: 'number', columnTitle: 'Flat Amount', width: 100},
      isActive: { type: 'string', columnTitle: 'Is Active', width: 80},
      isOneTime: { type: 'string', columnTitle: 'Adjust 1 Time', width: 80},
      action: { type: 'string', columnTitle: '', filterable: false, width: 80, },
    }
  },
  dairyNutritionists: {
    columns: {
      name: { type: 'string', columnTitle: 'Name'},
      percentageAllocated: { type: 'number', columnTitle: '%'},
      phoneNumber: { type: 'string', columnTitle: 'Phone'},
      email: { type: 'string', columnTitle: 'Email'},
      action: { type: 'string', columnTitle: '', filterable: false, width: 80, },
    }
  },
  millProd: {
    tableName: 'Mill Production',
    columns: {
      name: { type: 'string', columnTitle: 'Mill', },
      d1: { type: 'number', columnTitle: 'Tons', },
      d2: { type: 'number', columnTitle: 'Profit', }
    }
  },
  monthEndNutritionistView: {
    tableName: 'Nutritionist Production',
    columns: {
      nutritionistName: { type: 'string', columnTitle: 'Nutritionist', },
      numberOfCows: { type: 'number', columnTitle: 'Cows', isShowFooter: true},
      retainer: { type: 'number', columnTitle: 'Retainer', isShowFooter: true, isDecimal: true },
      feedProfit: { type: 'number', columnTitle: 'Gross Margin', isShowFooter: true, isDecimal: true},
      grossProfit: { type: 'number', columnTitle: 'Total Profit ', isShowFooter: true, isDecimal: true }
    }
  },
  nutritionistProd: {
    tableName: 'Nutritionist Production',
    columns: {
      name: { type: 'string', columnTitle: 'Nutritionist', },
      d1: { type: 'number', columnTitle: 'Tons', },
      d2: { type: 'number', columnTitle: 'Feed Profit', }
    }
  },
  dairyProd: {
    tableName: 'Dairy Production',
    columns: {
      name: { type: 'string', columnTitle: 'Dairy', },
      d1: { type: 'number', columnTitle: 'Tons', },
      d2: { type: 'number', columnTitle: 'Profit', }
    }
  },
  prodPlan: {
    tableName: 'Production Plan (LBS) Vs. Actual',
    columns: {
      name: { type: 'string', columnTitle: 'Dairy', },
      d1: { type: 'number', columnTitle: 'Plan (LBS)', isShowFooter: true, isDecimal: true },
      d2: { type: 'number', columnTitle: 'Actual (LBS)', isShowFooter: true, isDecimal: true }
    }
  },
  notifications: {
    tableName: 'Notifications',
    columns: {
      status: { type: 'string', columnTitle: 'Status',  width: 100},
      title: { type: 'string', columnTitle: 'Title',  width: 150},
      message: { type: 'string', columnTitle: 'Description', },
      createdOnDate: { type: 'date', columnTitle: 'Date',  width: 120},
      action: {  width: 50},
    }
  },
  availableProducts: {
    tableName: 'Available Products',
    columns: {
      name: { type: 'string', columnTitle: 'Product Name',  width: 100},
      isChecked: { width: 20},
    }
  },
  compPlans: {
    tableName: 'Comp Plan',
    columns: {
      name: { type: 'string', columnTitle: 'Comp Plan',  width: 50},
      description: { type: 'string', columnTitle: 'Description',  width: 100},
      isChecked: { width: 10},
    }
  },
  availableManagers: {
    tableName: 'Available Managers',
    columns: {
      name: { type: 'string', columnTitle: 'Nutritionist',  width: 100},
      percentage: { type: 'string', columnTitle: 'Percentage',  width: 50},
      isChecked: { width: 20},
    }
  },
  availableDairies: {
    tableName: 'Available Dairies',
    columns: {
      name: { type: 'string', columnTitle: 'Dairy Name',  width: 100},
      isChecked: { width: 20},
    }
  },
  availableNutritionists: {
    tableName: 'Available Nutritionists',
    columns: {
      name: { type: 'string', columnTitle: 'Nutritionist',  width: 100},
      isChecked: { width: 20},
    }
  },
  availableFeeds: {
    tableName: 'Available Feeds',
    columns: {
      fullName: { type: 'string', columnTitle: 'Feed Name',  width: 100},
      isChecked: { width: 20},
    }
  },
  availableMills: {
    tableName: 'Available Mills',
    columns: {
      name: { type: 'string', columnTitle: 'Mill Name',  width: 100},
      isChecked: { width: 20},
    }
  },
  availableRegions: {
    tableName: 'Available Regions',
    columns: {
      name: { type: 'string', columnTitle: 'Region Name',  width: 100},
      isChecked: { width: 20},
    }
  },
  viewByDairy: {
    tableName: 'View By Dairy',
    columns: {
      status: { type: 'string', columnTitle: 'Status',  width: 100},
      title: { type: 'string', columnTitle: 'Title',  width: 150},
      message: { type: 'string', columnTitle: 'Description', },
      createdOnDate: { type: 'date', columnTitle: 'Date',  width: 120},
      action: {  width: 50},
    }
  },
  viewByMill: {
    tableName: 'View By Mill',
    columns: {
      status: { type: 'string', columnTitle: 'Status',  width: 100},
      title: { type: 'string', columnTitle: 'Title',  width: 150},
      message: { type: 'string', columnTitle: 'Description', },
      createdOnDate: { type: 'date', columnTitle: 'Date',  width: 120},
      action: {  width: 50},
    }
  },
  viewByNutritionist: {
    tableName: 'View By Nutritionist',
    columns: {
      status: { type: 'string', columnTitle: 'Status',  width: 100},
      title: { type: 'string', columnTitle: 'Title',  width: 150},
      message: { type: 'string', columnTitle: 'Description', },
      createdOnDate: { type: 'date', columnTitle: 'Date',  width: 120},
      action: {  width: 50},
    }
  },
  viewByGrid: {
    columns: {
      millName: { type: 'string', columnTitle: 'Mill', canEdit: false},
      dairyName: { type: 'string', columnTitle: 'Dairy', canEdit: false},
      feedName: { type: 'string', columnTitle: 'Feed', canEdit: false},
      date: { type: 'date', columnTitle: 'Date', canEdit: false},
      tons: { type: 'number', columnTitle: 'Tons', canEdit: false},
      product: { type: 'string', columnTitle: 'Product', canEdit: false},
      lbsPerTon: { type: 'number', columnTitle: 'LBS Per Ton', canEdit: false},
      totalLbs: { type: 'number', columnTitle: 'Total LBS', canEdit: false},
      profit: { type: 'number', columnTitle: 'Profit', canEdit: false},
      percentage: { type: 'number', columnTitle: '%', canEdit: false},
      payable: { type: 'number', columnTitle: 'Earnings', canEdit: false},
      createdBy: { type: 'string', columnTitle: 'Entered By', canEdit: false},
      numberOfCows: { type: 'number', columnTitle: 'Cows', canEdit: false, isShowFooter: true},
      retainer: { type: 'number', columnTitle: 'Retainer', canEdit: false, isShowFooter: true, isDecimal: true},
      numberOfCows_Nutritionist: { type: 'number', columnTitle: 'Cows', canEdit: false},
      monthlyRetainer_Nutritionist: { type: 'number', columnTitle: 'Retainer', canEdit: false},
      nutritionistName: { type: 'string', columnTitle: 'Nutritionist', canEdit: false},
      nutritionistShare: { type: 'number', columnTitle: 'Nutritionist Share', canEdit: false},
      feedProfit: { type: 'number', columnTitle: 'Feed Profit', canEdit: false, isShowFooter: true, isDecimal: true},
      totalProfit: { type: 'number', columnTitle: 'Total Profit', canEdit: false, isShowFooter: true, isDecimal: true},
      grossProfit: { type: 'number', columnTitle: 'Total Profit', canEdit: false, isShowFooter: true, isDecimal: true},
    }
  },
  payablesGrid: {
    columns: {
      name: { type: 'string', columnTitle: 'Nutritionist',  width: 100},
      payable: { type: 'number', columnTitle: 'Payable Amount',  width: 150, filterable: false},
    }
  },
  profitGrid: {
    columns: {
      mill: { type: 'string', columnTitle: 'Mill',  width: 100},
      dairy: { type: 'string', columnTitle: 'Dairy',  width: 100},
      grossProfit: { type: 'number', columnTitle: 'Gross Margin',  width: 150},
      monthAsString: { type: 'string', columnTitle: 'Month',  width: 150},
      reportYear: { type: 'string', columnTitle: 'Year',  width: 150},
    }
  },
  nutritionistsGrid: {
    columns: {
      name: { type: 'string', columnTitle: 'Nutritionist',  width: 100},
      oid: { type: 'number', columnTitle: 'Payable Amount',  width: 150, filterable: false},
    }
  },
  dairiesGrid: {
    columns: {
      name: { type: 'string', columnTitle: 'Dairy',  width: 100},
      oid: { type: 'number', columnTitle: 'Payable Amount',  width: 150, filterable: false},
    }
  },
  millsGrid: {
    columns: {
      name: { type: 'string', columnTitle: 'Mill',  width: 100},
      oid: { type: 'number', columnTitle: 'Payable Amount',  width: 150, filterable: false},
    }
  },
  productProfitGrid: {
    columns: {
      name: { type: 'string', columnTitle: 'Product',  width: 100},
      oid: { type: 'number', columnTitle: 'Payable Amount',  width: 150, filterable: false},
    }
  },
  feedsGrid: {
    columns: {
      name: { type: 'string', columnTitle: 'Feed',  width: 100},
      oid: { type: 'number', columnTitle: '',  width: 150, filterable: false},
    }
  },
  ytdMtdGrid: {
    columns: {
      name: { type: 'string', columnTitle: 'Nutritionist'},
      ytdFeedProfit: { type: 'number', columnTitle: 'YTD Feed Profit', isShowFooter: true, isDecimal: true},
      ytdTotalProfit: { type: 'number', columnTitle: 'YTD Total Profit', isShowFooter: true, isDecimal: true},
      ytdRetainerProfit: { type: 'number', columnTitle: 'YTD Retainer Profit', width: 150, isShowFooter: true, isDecimal: true},
      ytdEarnings: { type: 'number', columnTitle: 'YTD Earnings', isShowFooter: true, isDecimal: true},
      mtdFeedProfit: { type: 'number', columnTitle: 'MTD Feed Profit', isShowFooter: true, isDecimal: true},
      mtdTotalProfit: { type: 'number', columnTitle: 'MTD Total Profit', isShowFooter: true, isDecimal: true},
      mtdRetainerProfit: { type: 'number', columnTitle: 'MTD Retainer Profit', width: 150, isShowFooter: true, isDecimal: true},
      mtdDairiesServed: { type: 'number', columnTitle: 'MTD Dairies Served', width: 150, isShowFooter: true},
      mtdCowsServed: { type: 'number', columnTitle: 'MTD Cows Served', isShowFooter: true},
      mtdEarnings: { type: 'number', columnTitle: 'MTD Earnings', isShowFooter: true, isDecimal: true},
    }
  },
  millGrossProfits: {
    tableName: 'Mill Gross Margin',
    columns: {
      name: { type: 'string', columnTitle: 'Mill'},
      feedProfit: { type: 'number', columnTitle: 'Feed Profit', isShowFooter: true, isDecimal: true},
    }
  },
  cowCount: {
    columns: {
      dairyName: { type: 'string', columnTitle: 'Dairy'},
      totalCows: { type: 'number', columnTitle: 'Total Cows', isShowFooter: true, isDecimal: true},
      totalProfit: { type: 'number', columnTitle: 'Total Profit', isShowFooter: true, isDecimal: true},
    }
  },
  cowCountByYear: {
    columns: {
      dairyName: { type: 'string', columnTitle: 'Dairy'},
      totalCows: { type: 'number', columnTitle: 'Total Cows'},
      totalProfit: { type: 'number', columnTitle: 'Total Profit'},
      year: { type: 'string', columnTitle: 'Year'},
      january: { type: 'number', columnTitle: 'January'},
      february: { type: 'number', columnTitle: 'February'},
      march: { type: 'number', columnTitle: 'March'},
      april: { type: 'number', columnTitle: 'April'},
      may: { type: 'number', columnTitle: 'May'},
      june: { type: 'number', columnTitle: 'June'},
      july: { type: 'number', columnTitle: 'July'},
      august: { type: 'number', columnTitle: 'August'},
      september: { type: 'number', columnTitle: 'September'},
      october: { type: 'number', columnTitle: 'October'},
      november: { type: 'number', columnTitle: 'November'},
      december: { type: 'number', columnTitle: 'December'},
    }
  },
  nutritionistGrossProfits: {
    tableName: 'Nutritionist Gross Margin',
    columns: {
      name: { type: 'string', columnTitle: 'Nutritionist'},
      feedProfit: { type: 'number', columnTitle: 'Feed Profit', isShowFooter: true, isDecimal: true},
      retainer: { type: 'number', columnTitle: 'Retainer', isShowFooter: true, isDecimal: true},
      totalProfit: { type: 'number', columnTitle: 'Total Profit', isShowFooter: true, isDecimal: true},
    }
  },
  dairyGrossProfits: {
    tableName: 'Dairy Gross Margin',
    columns: {
      name: { type: 'string', columnTitle: 'Dairy'},
      feedProfit: { type: 'number', columnTitle: 'Feed Profit', isShowFooter: true, isDecimal: true},
      retainer: { type: 'number', columnTitle: 'Retainer', isShowFooter: true, isDecimal: true},
      totalProfit: { type: 'number', columnTitle: 'Total Profit', isShowFooter: true, isDecimal: true},
    }
  },
  monthlyMillProd: {
    columns: {
      millName: { type: 'string', columnTitle: 'Mill'},
      dairyName: { type: 'string', columnTitle: 'Dairy Name'},
      feedName: { type: 'string', columnTitle: 'Feed'},
      retainer: { type: 'number', columnTitle: 'Retainer'},
      tons: { type: 'number', columnTitle: 'Tons', isShowFooter: true, isDecimal: true},
      feedProfit: { type: 'number', columnTitle: 'Feed Profit', isShowFooter: true, isDecimal: true},
      grossProfit: { type: 'number', columnTitle: 'Total Profit', isShowFooter: true, isDecimal: true},
    }
  },
  monthlyNutritionistProd: {
    columns: {
      nutritionistName: { type: 'string', columnTitle: 'Nutritionist'},
      dairyName: { type: 'string', columnTitle: 'Dairy'},
      retainer: { type: 'number', columnTitle: 'Retainer', isShowFooter: true, isDecimal: true},
      retainer_Nutritionist: { type: 'number', columnTitle: 'Nutr. Retainer', isShowFooter: true, isDecimal: true},
      percentageAllocated: { type: 'number', columnTitle: 'Share'},
      feedName: { type: 'string', columnTitle: 'Feed'},
      tons: { type: 'number', columnTitle: 'Tons', isShowFooter: true, isDecimal: true},
      feedProfit: { type: 'number', columnTitle: 'Feed Profit', isShowFooter: true, isDecimal: true},
      grossProfit: { type: 'number', columnTitle: 'Total Profit', isShowFooter: true, isDecimal: true},
      numberOfCows_Nutritionist: { type: 'number', columnTitle: 'Nutr. Cows', isShowFooter: true},
      numberOfCows: { type: 'number', columnTitle: 'Cows', isShowFooter: true},
    }
  },
  nutritionistDeliveryDetailsShareholder: {
    columns: {
      nutritionistName: { type: 'string', columnTitle: 'Nutritionist'},
      dairyName: { type: 'string', columnTitle: 'Dairy Name'},
      numberOfCows: { type: 'number', columnTitle: 'Cows', isShowFooter: true},
      retainer: { type: 'number', columnTitle: 'Retainer', isShowFooter: true, isDecimal: true},
      feedName: { type: 'string', columnTitle: 'Feed'},
      tons: { type: 'number', columnTitle: 'Tons', isShowFooter: true, isDecimal: true},
      productName: { type: 'string', columnTitle: 'Product'},
      productLbsPerTon: { type: 'number', columnTitle: 'Lbs Per Ton', isShowFooter: true, isDecimal: true},
      profitPerPound: { type: 'number', columnTitle: 'Profit Per Lb', isShowFooter: true, isDecimal: true},
      percentageAllocated: { type: 'number', columnTitle: 'Nutr. Share'},
      numberOfCows_Nutritionist: { type: 'number', columnTitle: 'Nutr. Cows', isShowFooter: true},
      retainer_Nutritionist: { type: 'number', columnTitle: 'Nutr. Retainer', isShowFooter: true, isDecimal: true},
      grossProfit_Nutritionist: { type: 'number', columnTitle: 'Total Gross Margin', isShowFooter: true, isDecimal: true},
      nutritionist_Pay: { type: 'number', columnTitle: 'Nutr. Pay', isShowFooter: true, isDecimal: true},
    }
  },
  nutritionistDeliveryDetailsNonShareholder: {
    columns: {
      nutritionistName: { type: 'string', columnTitle: 'Nutritionist'},
      dairyName: { type: 'string', columnTitle: 'Dairy Name'},
      numberOfCows: { type: 'number', columnTitle: 'Cows', isShowFooter: true},
      retainer: { type: 'number', columnTitle: 'Retainer', isShowFooter: true, isDecimal: true},
      feedName: { type: 'string', columnTitle: 'Feed'},
      tons: { type: 'number', columnTitle: 'Tons', isShowFooter: true, isDecimal: true},
      productName: { type: 'string', columnTitle: 'Product'},
      productLbsPerTon: { type: 'number', columnTitle: 'Lbs Per Ton', isShowFooter: true, isDecimal: true},
      percentageAllocated: { type: 'number', columnTitle: 'Nutr. Share'},
      numberOfCows_Nutritionist: { type: 'number', columnTitle: 'Nutr. Cows', isShowFooter: true},
      nutritionist_Pay: { type: 'number', columnTitle: 'Nutr. Pay', isShowFooter: true, isDecimal: true},
    }
  },
  millDeliveryDetail: {
    columns: {
      millName: { type: 'string', columnTitle: 'Mill'},
      dairyName: { type: 'string', columnTitle: 'Dairy'},
      feedName: { type: 'string', columnTitle: 'Feed'},
      tons: { type: 'number', columnTitle: 'Tons', isShowFooter: true, isDecimal: true},
      productName: { type: 'string', columnTitle: 'Product'},
      productLbsPerTon: { type: 'number', columnTitle: 'Lbs Per Ton', isShowFooter: true, isDecimal: true},
    }
  },
  nutritionistProdSummary: {
    columns: {
      nutritionistName: { type: 'string', columnTitle: 'Nutritionist'},
      productName: { type: 'string', columnTitle: 'Product'},
      dairyName: { type: 'string', columnTitle: 'Dairy Name'},
      feedName: { type: 'string', columnTitle: 'Feed'},
      retainer: { type: 'number', columnTitle: 'Retainer', isShowFooter: true, isDecimal: true},
      productLbsPerTon: { type: 'number', columnTitle: 'Product Lbs Per Ton'},
      lbsPerUsed: { type: 'number', columnTitle: 'Pounds'},
      productProfitPerLb: { type: 'number', columnTitle: 'Rate'},
      tons: { type: 'number', columnTitle: 'Tons', isShowFooter: true, isDecimal: true},
      numberOfCows: { type: 'number', columnTitle: 'Cows', isShowFooter: true},
      grossProfit: { type: 'number', columnTitle: 'Gross Margin', isShowFooter: true, isDecimal: true},
      nutritionistShare: { type: 'number', columnTitle: 'Nut. Share'},
      nutritionistNumberOfCows: { type: 'number', columnTitle: 'Cows', isShowFooter: true},
      nutritionistRetainer: { type: 'number', columnTitle: 'Retainer', isShowFooter: true, isDecimal: true},
      nutritionistTons: { type: 'number', columnTitle: 'Tons', isShowFooter: true, isDecimal: true},
      nutritionistProfit: { type: 'number', columnTitle: 'Feed Profit', isShowFooter: true, isDecimal: true},
      nutritionistTotalProfit: { type: 'number', columnTitle: 'Total Profit', isShowFooter: true, isDecimal: true},
      nutritionistCommission: { type: 'number', columnTitle: 'Comm. Rate'},
      nutritionistEarnings: { type: 'number', columnTitle: 'Earnings', isShowFooter: true, isDecimal: true},
    }
  }
};

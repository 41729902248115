import { Component, OnInit } from '@angular/core';
// import {AnalyticsService} from '../../api/generated-api';
import {CONSTANTS} from '../../lib/constants';
import {AppUtilities} from "../../lib/appUtilities.service";
import {AnalyticsService, MonthCloseState, PDSService, RptMonthlyProfit} from "../../api/generated-api";
import {UserService} from "../../services/user.service";

@Component({
  selector: 'pds-gross-profit',
  templateUrl: './pds-gross-profit.component.html',
  styleUrls: ['./pds-gross-profit.component.css']
})
export class PdsGrossProfitComponent implements OnInit {

  public millData: RptMonthlyProfit[] = [];
  public nutritionistData: RptMonthlyProfit[] = [];
  public dairyData: RptMonthlyProfit[] = [];
  public selectedMonth: number;
  public currentMonth: number;
  public currentYear: number;
  public selectedYear: number;
  public monthString: string = '';
  public monthList: Array<{ nMonth: number, sMonth: string }> = CONSTANTS.MONTHS_SELECT_LIST;
  public years: number[] = CONSTANTS.YEARS;
  public monthCloseState: MonthCloseState;

  constructor(private pds: PDSService, private userService: UserService) {
  }
  //
  async ngOnInit(): Promise<void> {
    await this.getCurrentMonthYear();
    this.getAllProfits();
  }

  async getAllProfits() {
    const response = await this.pds.getGrossProfitCollectionReport(this.selectedMonth, this.selectedYear).toPromise();
    this.dairyData = response.dairyView;
    this.nutritionistData = response.nutritionistView;
    this.millData = response.millView;
    // this.profits = response.millView;
  }

  getTotalByColumn(columnName: string, data?) {
    return AppUtilities.addByColumn(data, columnName);
  }

  selectDate() {
    this.setMonthString();
    this.getAllProfits();
  }

  setMonthString() {
    this.monthString = this.monthList.find(month => +month.nMonth === +this.selectedMonth).sMonth;
  }

  async getCurrentMonthYear(): Promise<void> {
    const response = await this.userService.getMonthCloseState();
    this.selectedMonth = response.currentMonth;
    this.currentMonth = response.currentMonth;
    this.selectedYear = response.currentYear;
    this.currentYear = response.currentYear;
    this.setMonthString();
  }

}

import { Injectable } from '@angular/core';

@Injectable()
export class DateUtilities {

    constructor() {

    }

    public static convertDate(date: string): string { // tslint:disable-line
        return date ? date.toString().split('T')[0] : null;
    }

    public static convertToDate(date: string): Date { // tslint:disable-line
        return date ? new Date(date) : null;
    }

}

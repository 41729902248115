import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { JWTService } from 'src/app/lib/jwt.service';
import { UserService } from 'src/app/services/user.service';
import {ToastrService} from 'ngx-toastr';
import { ApplicationStartupDTO, AuthenticationReturnPayload, PDSService, LoginRequest, AuthenticationService } from '../../api/generated-api'; // tslint:disable-line
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { ChangepasswordModal } from 'src/app/components/change-password-modal/change-password-modal.component';
import {SwalDialog} from "../../lib/swal-dialog";
import { AppUtilities } from 'src/app/lib/appUtilities.service';
@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  IsShowForgotPwdSpinner: string = "false";

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private jwtService: JWTService,
    private pdsService: PDSService,
    private userService: UserService,
    private modalService: NgbModal,
    private appUtilities: AppUtilities,
    private toastrService: ToastrService ) {
    this.loginForm = this.formBuilder.group({
      loginName: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {

  }


  async onSubmit(): Promise<void> {
    try {
      const loginRequest: LoginRequest = <LoginRequest>{};
      loginRequest.loginName = this.loginForm.get('loginName').value;
      loginRequest.password = this.loginForm.get('password').value;
      const loginRes: AuthenticationReturnPayload = await this.authenticationService.login(loginRequest).toPromise();
      if (loginRes) {
        this.jwtService.setToken(loginRes.authToken);
        if(this.IsPasswordChangeRequired(loginRes)){
          this.LaunchPasswordChangeModal(loginRes);
        } else {
          this.SetStartupAndNavigateHome(loginRes);
        }
      }
    } catch (error) {
      this.toastrService.error('Login Name or password are incorrect. Please try again.');
    }
  }

  public async SetStartupAndNavigateHome(toPayload: AuthenticationReturnPayload){
    const res: ApplicationStartupDTO = await this.pdsService.getPDSApplicationStartupData().toPromise();
    if (res) {
      this.userService.setStartUpPayload(res);
    } else {
      this.toastrService.error('Error retrieving StartupPayload. Please login again. If this issue persists contact service provider');
    }
    this.router.navigate(['/home']);
  }

  public IsPasswordChangeRequired(toPayload: AuthenticationReturnPayload): boolean {
    if(toPayload.userMustChangePassword){
      return true;
    } else {
      return false;
    }
  }

  async forgotMyPassword() {
    const loginName = this.loginForm.get('loginName').value.toString();
    if(loginName != null &&  loginName != '') {
    this.IsShowForgotPwdSpinner = "true";
      const response: string = await this.pdsService.forgotPassword(loginName).toPromise();
      console.log(response, 'response to forgot password');
      if(response === 'Success') {
          this.IsShowForgotPwdSpinner = "false";
         SwalDialog.show({
          title: 'Email Sent',
          text: 'A new temporary password has been generated and sent to your registered email.',
          icon: 'success',
          dangerMode: false,
        });
      } else {
        this.IsShowForgotPwdSpinner = "false";
        await this.appUtilities.showServerErrorSwal(response);
      }
    } else {
      SwalDialog.show({
        title: 'Login Name Required',
        text: 'Enter your login name then press "Forgot Password" again.  If your account is verified a new temporary password will be sent to your email.',
        icon: 'warning',
        dangerMode: false,
      });
    }
  }

  public async LaunchPasswordChangeModal(toPayload: AuthenticationReturnPayload): Promise<void>{
    // TODO: add ngbOptions keyboard and backdrop to modals to remove static
    const resetPWModal: NgbModalRef = this.modalService.open(ChangepasswordModal, {backdrop: 'static'});
    resetPWModal.result.then((result) => { }, (reason) => {
      if(reason && reason === true){
        this.SetStartupAndNavigateHome(toPayload);
      }
    });
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {LookupService} from '../../services/lookup.service';
import {Lookup, Product, PDSService, FeedDTO, ValuePair} from '../../api/generated-api';
import {SwalDialog} from "../../lib/swal-dialog";

@Component({
  selector: 'clone-feed-modal',
  templateUrl: './clone-feed-modal.component.html',
  styleUrls: ['./clone-feed-modal.component.css']
})
export class CloneFeedModalComponent implements OnInit, OnDestroy {

  public currentFeed: FeedDTO = <FeedDTO>{};
  // public feed: FeedDTO = <FeedDTO>{};
  public dairies: ValuePair[] = [];
  public newFeedName: string = '';
  public selectedDairyOid: number;
  public assignPreference: string = 'dairy';
  public pageLoading: boolean = false;
  public isSaving: boolean = false;


  constructor(public activeModal: NgbActiveModal,
              private pdsService: PDSService,
              private lookups: LookupService) {
  }

  async ngOnInit(): Promise<void> {
    this.pageLoading = true;
    await this.getDairiesList();
    this.setDefaultValues();
    if(this.currentFeed && this.currentFeed.entityOid_Dairy) {
      if(this.currentFeed.entityOid_Dairy === 706) {
        this.assignPreference = 'universal'
      } else {
        this.selectedDairyOid = this.currentFeed.entityOid_Dairy;
      }
    }
    this.pageLoading = false;
  }

  ngOnDestroy(): void {
  }

  assignPreferenceChange(event): void {
    this.assignPreference = event;
  }

  setDefaultValues() {
    this.selectedDairyOid = -1;
  }

  async getDairiesList() {
    this.dairies = await this.pdsService.getAllDairiesList(true).toPromise();
  }

  async clone() {
    this.isSaving = true;
    const isInvalidString: string = this.isInvalid();
    if (isInvalidString && isInvalidString !== '') {
      const myhtml = document.createElement('div');
      myhtml.innerHTML = isInvalidString;
      SwalDialog.show({
        title: 'Validation Failed',
        content: myhtml,
        icon: 'error',
      });
      this.isSaving = false;
      return;
    } else {
      const response = await this.pdsService.cloneFeed(this.currentFeed.oid, this.selectedDairyOid, this.newFeedName).toPromise();
      this.isSaving = false;
      this.close()
    }
  }


  isInvalid(): string {
    let rString: string = '';
    if (!this.newFeedName || this.newFeedName === '') {
      rString = rString.concat('Must have a name for new feed </br>');
    }
    if (!this.selectedDairyOid || this.selectedDairyOid <= 0) {
      rString = rString.concat('Must assign a dairy to the new feed </br>');
    }
    return rString;
  }

  close(): void {
    this.resetProperties();
    this.activeModal.dismiss();
  }

  resetProperties() {
    this.currentFeed = <FeedDTO>{};
    this.dairies = [];
    this.selectedDairyOid = null;
    this.newFeedName = '';
    this.pageLoading = false;
    this.isSaving = false;

  }

}

import { Injectable } from '@angular/core';
import { JWTService } from '../lib/jwt.service';
import { LookupService } from './lookup.service';
import { CONSTANTS } from '../lib/constants';
import { Entity, SecurityGroup, ApplicationStartupDTO, EntityService, MonthCloseState, MonthEndService } from '../api/generated-api';

@Injectable()
export class UserService {

    public entityUser: Entity = {} as Entity;
    public monthCloseState: MonthCloseState = {} as MonthCloseState;
    public securityGroup: SecurityGroup = {} as SecurityGroup;

    constructor(
        private readonly jwtService: JWTService,
        private readonly lookupsService: LookupService,
        private readonly entityService: EntityService,
        private readonly monthEndService: MonthEndService
    ) {
        const payload = UserService.getCachedStartupPayload();
        if (payload) { this.setStartUpPayload(payload); }
    }

    private static setCachedStartupPayload(payload: ApplicationStartupDTO): void {
        localStorage.setItem(CONSTANTS.LOCAL_STORAGE.STARTUP_PAYLOAD, JSON.stringify(payload));
    }

    private static getCachedStartupPayload(): ApplicationStartupDTO {
        return JSON.parse(localStorage.getItem(CONSTANTS.LOCAL_STORAGE.STARTUP_PAYLOAD));
    }

    private static async updateCachedStartupPayload(propertyName: string, propertyValue): Promise<any> {
        let payload = UserService.getCachedStartupPayload();
        if (!payload) { payload = {} as ApplicationStartupDTO; }
        payload[propertyName] = propertyValue;
        UserService.setCachedStartupPayload(payload);
    }

    public async setEntityUser(entity: Entity): Promise<any> {
        this.entityUser = entity;
        UserService.updateCachedStartupPayload(CONSTANTS.LOCAL_STORAGE.ENTITY, entity);
    }

    public async setMonthCloseState(monthCloseState: MonthCloseState): Promise<any> {
        this.monthCloseState = monthCloseState;
    }

    public async setSecurityGroup(group: SecurityGroup): Promise<any> {
        this.securityGroup = group;
        UserService.updateCachedStartupPayload(CONSTANTS.LOCAL_STORAGE.SECURITY_GROUP, group);
    }

    public async updateCachedLookups(lookups): Promise<any> {
        this.lookupsService.setLookups(lookups);
        await UserService.updateCachedStartupPayload(CONSTANTS.LOCAL_STORAGE.LOOKUPS, lookups);
    }

    public async setStartUpPayload(payload: ApplicationStartupDTO): Promise<any> {
        if (payload.lookups) { await this.updateCachedLookups(payload.lookups); }
        if (payload.loggedInUser) { await this.setEntityUser(payload.loggedInUser); }
    }

    public clearUserSession(): void {
        localStorage.clear();
        this.entityUser = {} as Entity;
        this.monthCloseState = {} as MonthCloseState;
        this.securityGroup = {} as SecurityGroup;
        this.jwtService.removeToken();
    }

    public async getLoggedInEntityUser(update = false): Promise<Entity> {
        if (this.entityUser && !update) {
            return this.entityUser;
        } else {
            const user = await this.entityService.getLoggedInUser().toPromise();
            this.entityUser = user;
            return this.entityUser;
        }
    }

    public async getMonthCloseState(update = false): Promise<MonthCloseState> {
        if (this.monthCloseState && JSON.stringify(this.monthCloseState) !== "{}" && !update) {
            return this.monthCloseState;
        } else {
            const monthEnd = await this.monthEndService.getMonthCloseState().toPromise();
            this.monthCloseState = monthEnd;
            return this.monthCloseState;
        }
    }

    public async getLoggedInSecurityGoup(update = false): Promise<SecurityGroup> {
        if (this.securityGroup && !update) {
            return this.securityGroup;
        } else {
            const group = await this.entityService.getSecurityGroupForLoggedInUser().toPromise();
            this.securityGroup = group;
            return this.securityGroup;
        }
    }

}

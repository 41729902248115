import { AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, ContentChild, ContentChildren, DoCheck, EventEmitter, Input, IterableDiffer, IterableDiffers, OnDestroy, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core'; // tslint:disable-line
import { ColumnComponent, ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DataStateChangeEvent, GridComponent, GroupableSettings, PagerSettings, SelectableSettings, SelectionEvent, SortSettings, CellClickEvent, RowClassArgs, DomEventsService } from '@progress/kendo-angular-grid'; // tslint:disable-line
import { DataResult, FilterDescriptor, process, SortDescriptor, State, GroupDescriptor, GroupResult } from '@progress/kendo-data-query';
import { CwColumnComponent } from './cw-column.component';
import { CwColumnDefinition, CwGridDefinition } from './cw-grid-common';
import { CwGridDefinitions } from './cw-grid-definitions';
import { CwGridPreferenceComponent } from './cw-grid-preference.component';
import { ColumnNameWidthPair, GridPreferenceJsonData } from './grid-preference-json-data';
import { GridPreferenceService } from './grid-preference.service';
import { LookupCompositeFilterDescriptor } from './multi-select-filter/multi-select-filter.component';
import { BatchUpdateRowComponent } from './batch-update-row/batch-update-row.component';
import { Subscription } from 'rxjs';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { AppUtilities } from '../../lib/appUtilities.service';
import { FormGroupConverter } from './form-group-converter';
import { GridPreference } from '../../api/generated-api';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'cw-grid',
    templateUrl: './cw-grid.component.html',
    styleUrls: ['./cw-grid.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class CwGridComponent implements OnInit, AfterViewInit, DoCheck, OnDestroy {

    private static readonly DEFAULT_PAGE_SIZE = 30;
    private static readonly MAX_PAGE_SIZE = 9999; // TODO: Need a better way to handle large data sets

    private static readonly DEFAULT_GROUPABLE: GroupableSettings = {
        enabled: true,
        emptyText: 'Drag a column header and drop it here to group by that column',
        showFooter: false,
    };

    @Input() definition: string;
    @Input() hideRowExpandButton: boolean = false;
    @Input() showExpandCollapseAll: boolean = false;
    @Input() public exportHeader: any;
    @Input() public hideFilterRow: boolean = false;
    @Input() public hideRearrange: boolean = false;
    @Input() public showTableName: boolean = true;
    @Input() public showOnlyExport: boolean = false;
    @Input() public canAddLineItem: boolean = false;
    @Input() public showAddBtn: boolean = false;
    @Input() public customPageSize: number;
    @Input() public isSortable: boolean = true;
    @Input() public showExcel: boolean = false;
	@Input() public exportCellClick: boolean = false;

    @Input() public showGridViewHeader: boolean = true;
    @Input() public showGearEditIcon: boolean = true;


    @Input() data: any[] = [];
    @Input() isExporting: boolean = false;
    @Input() customStyles: any = null;

    @Output() selectionChange = new EventEmitter();
    @Output() addInitiated = new EventEmitter();
    @Output() dataBound = new EventEmitter();
	@Output() cellClickInitiated = new EventEmitter();

    @ViewChild('grid', null) grid: GridComponent;

    @ContentChildren(CwColumnComponent) columnQuery: QueryList<CwColumnComponent>;
    @ContentChild('detailTemplate', null) detailTemplate: TemplateRef<any>;

    public gridDef: CwGridDefinition;

    public masterColumnList: CwColumnComponent[];
    public columns;
    private exportFlag: boolean = false;
    public showBulkUpdateToggle: boolean = false;
    public showColumnMenuPopup: boolean = false;
    public hiddenColumns: string[] = [];
    public columnMenu: boolean = false;
    public navigable: boolean = true;
    public filterable: boolean = false;
    public reorderable: boolean = true;
    public resizable: boolean = true;
    public groupable: GroupableSettings | boolean = false;
    public selectable: SelectableSettings | boolean = true;
    public sortable: SortSettings = { allowUnsort: true, mode: 'multiple' };
    public pageable: PagerSettings | boolean = {
        buttonCount: 6,
        info: true,
    };
    public activeGridPreference: GridPreference = <GridPreference>{};
    public subGridTotalExclusionColumns: string[] = ['percentage', 'nutritionist_Share', 'nutritionistShare', 'nutritionist_Commission', 'nutritionistCommission', 'lbsPerTon'];
    public dataView: DataResult;
    public cachedSort: SortDescriptor[] = [];
    public isGridInitialized: boolean = false;
    public originalCheckSumProduct: string = '';

    public formGroup: FormGroup;
    private editDataItem: any = null;
    private editRowIndex: number = null;

    public state: State = {
        skip: 0,
        take: CwGridComponent.DEFAULT_PAGE_SIZE,
        group: [],
        sort: [],
        filter: {
            logic: 'and',
            filters: [],
        }
    };

    private prevGroups: GroupDescriptor[] = []; // used for checking if grouping has changed

    private readonly differ: IterableDiffer<any>;

    private batchUpdateRowComponentInstance: ComponentRef<BatchUpdateRowComponent>;
    private onBatchUpdateSubscription: Subscription;

    constructor(
        private readonly prefService: GridPreferenceService,
        differs: IterableDiffers,
        private modalService: NgbModal,
        private readonly _viewContainerRef: ViewContainerRef,
        private readonly _componentFactoryResolver: ComponentFactoryResolver,
        private _toastr: ToastrService
    ) {
        this.getDataToExport = this.getDataToExport.bind(this);
        this.differ = differs.find(this.data).create();
    }

    async ngOnInit(): Promise<void> {
        this.gridDef = CwGridDefinitions[this.definition];
    }

    ngAfterViewInit(): void {
        this.performGridInitialization();
    }

    private assignColumnSortOrderByPosition(){
        let counter: number = 0;
        this.columns.forEach(column => {
            column.sortOrder = counter;
            counter++;
        })
    }

    columnReorder(event) {
        const columnToMove: CwColumnComponent = this.columns[event.oldIndex];
        this.columns.splice(event.oldIndex, 1);
        this.columns.splice(event.newIndex, 0, columnToMove);
        this.assignColumnSortOrderByPosition();
        // const newIndexSort = JSON.parse(JSON.stringify(this.columns[event.newIndex].sortOrder));
        // if (event.oldIndex < event.newIndex) {
        //     //Moved right. Adjust downward
        //     for (let i = 0; i < this.columns.length; i++) {
        //         if (i > event.oldIndex && i <= event.newIndex) {
        //                 this.columns[i].sortOrder = this.columns[i].sortOrder - 1;
        //         }
        //     }
        // } else if (event.oldIndex > event.newIndex) {
        //     //Moved left. Adjust upward
        //     for (let i = 0; i < this.columns.length; i++) {
        //         if (i < event.oldIndex && i >= event.newIndex) {
        //             this.columns[i].sortOrder = this.columns[i].sortOrder + 1;
        //         }
        //     }
        // }
        // const foundColumn: CwColumnComponent = this.columns.find(c => c.name === event.column.field);
        // if(foundColumn){
        //     foundColumn.sortOrder = newIndexSort;
        // }
        // this.columns = this.columns.sort((a, b) => a.sortOrder - b.sortOrder);
    }

    ngDoCheck(): void {
        const changes = this.differ.diff(this.data);
        if (changes) {
            this.dataView = process(this.data, this.state);
        }
    }

	getFooterTotalByColumn(columnName: string) {
		const newState = {
			skip: 0,
			take: CwGridComponent.MAX_PAGE_SIZE,
			group: [],
			sort: [],
			filter: this.state.filter
		};
		var datasource = process(this.data, newState);
		return AppUtilities.addByColumn(datasource.data, columnName);
	}


    getGroupedTotalByColumn(items: any[], columnName: string) {
        let dValue: number = 0;
        for (const i of items) {
            dValue += this.getGroupedTotalByColumn2(i, columnName);
        }
        return dValue;
    }

    getGroupedTotalByColumn2(item: any, columnName: string): number {
        let dValue: number = 0;
        if (item.items && item.items.length > 0) {
            for (const i of item.items) {
                dValue += this.getGroupedTotalByColumn2(i, columnName);
            }
        } else {
            if(!isNaN(item[columnName])){
                dValue += Number(item[columnName]);
            }
        }

        return dValue;
    }

    subGridFooterColumnIsAllowed(columnName: string): boolean {
        return this.subGridTotalExclusionColumns.indexOf(columnName) === -1;
    }

    ngOnDestroy(): void {
        if (this.onBatchUpdateSubscription) {
            this.onBatchUpdateSubscription.unsubscribe();
        }
        if (this.batchUpdateRowComponentInstance) {
            this.batchUpdateRowComponentInstance.destroy();
        }
    }

    // region Lookup Multi-Select Filter

    /**
     * Event from the MultiSelectFilterComponent
     * @param filter
     */
    public onLookupFilter(filter: LookupCompositeFilterDescriptor): void {
        // Find a matching CompositeFilterDescriptor
        const matchingCompositeFilterDescriptor = this.state.filter.filters.find(f => f['lookupName'] && f['lookupName'] === filter.lookupName) as LookupCompositeFilterDescriptor; // tslint:disable-line
        if (matchingCompositeFilterDescriptor) {
            if (filter.filters.length) {
                // If it already exists and the new filter has values replace the filters
                matchingCompositeFilterDescriptor.filters = filter.filters;
            } else {
                // If the filter is empty, remove it
                const index = this.state.filter.filters.indexOf(matchingCompositeFilterDescriptor);
                this.state.filter.filters.splice(index, 1);
            }
        } else {
            // The filter wasn't found, just push it to the existing array
            this.state.filter.filters.push(filter);
        }

        this.recalculateDataView();
    }

    /**
     * Gets the active filter for the passed in lookupName
     * @param lookupName
     */
    public getCurrentLookupFilter(lookupName: string): LookupCompositeFilterDescriptor {
        if (this.state.filter && this.state.filter.filters) {
            return this.state.filter.filters.find(f => f['lookupName'] && f['lookupName'] === lookupName) as LookupCompositeFilterDescriptor; // tslint:disable-line
        } else {
            return <LookupCompositeFilterDescriptor>{};
        }
    }

    // endregion

    // region Date Range Filter

    public onDateRangeFilter(filters: FilterDescriptor[], startKey: string, endKey: string): void {
        // Clears out the existing filters for the specified columns
        this.state.filter.filters = this.state.filter.filters.filter((f: FilterDescriptor) => f.field !== startKey && f.field !== endKey) as FilterDescriptor[]; // tslint:disable-line
        // Adds the new ones if they are specified
        if (filters && filters.length) {
            this.state.filter.filters = [...this.state.filter.filters, ...filters];
        }

        this.recalculateDataView();
    }

    expandAllParentGrids() {
        for (let i = 0; i < this.data.length; i++) {
            this.grid.expandRow(i);
        }
    }

    collapseAllParentGrids() {
        for (let i = 0; i < this.data.length; i++) {
            this.grid.collapseRow(i);
        }
    }

    public getCurrentDateRangeFilters(startKey: string, endKey: string): FilterDescriptor[] {
        if (this.state.filter && this.state.filter.filters) {
            return this.state.filter.filters.filter((f: FilterDescriptor) => f.field === startKey || f.field === endKey) as FilterDescriptor[]; // tslint:disable-line
        } else {
            return [];
        }
    }

    // endregion

    private async performGridInitialization() {
        const defaultPref = await this.prefService.loadDefault(this.definition);
        if (defaultPref) {
            this.applyPreference(defaultPref);
        } else {
            this.activeGridPreference = <GridPreference>{};
            this.configureColumns();
            this.recalculateDataView();
        }
        setTimeout(() => {
            this.isGridInitialized = true;
            this.dataBound.emit(true);
        }, 0);
    }

    /**
     * Maps the available keys from the CwColumnDefinition into the CwColumnComponent
     */
     public configureColumns(orderOfColumns?: string[], columnWidths?: ColumnNameWidthPair[]) {
        this.columns = this.columnQuery.toArray();
        if (this.gridDef) {
            const list = this.columns.map(column => {
                const columnDefinition = this.getColumnDefinitionByCwColumnComponent(column);
                Object.keys(columnDefinition).forEach(key => column[key] = columnDefinition[key]);
                return column;
            });
        this.columns = list.filter(column => {
            return this.hiddenColumns.indexOf(column.name) == -1;
        });
        this.masterColumnList = list;
        this.masterColumnList.map(column => {
            column.isHidden = this.hiddenColumns.indexOf(column.name) > -1;
        })
        }
        if(columnWidths && columnWidths.length > 0){
            for(const c of columnWidths){
                const found = this.columns.find(column => column.columnTitle === c.Name);
                if(found){
                    found.width = Number(c.Width);
                }
            }
        }
        if (orderOfColumns && orderOfColumns.length > 0) {
            let counter = 0;
            for (const name of orderOfColumns) {
                const found = this.columns.find(column => column.name === name);
                if (found) {
                    found.sortOrder = counter;
                    counter++;
                }
            }
            for (const column of this.columns) {
                if (column.sortOrder < 0) {
                    column.sortOrder = counter;
                    counter++;
                }
            }
            this.columns.sort((a, b) => a.sortOrder - b.sortOrder);
        } else {
            this.assignColumnSortOrderByPosition();
        }
    }

    private generateColumnWidthJson(): ColumnNameWidthPair[] {
        const NameValueList: ColumnNameWidthPair[] = [];
        const columns = this.grid.columns;
        columns.forEach((column) => {
            NameValueList.push({Name: column.title, Width: column.width.toString()});
        })
        return NameValueList;
    }

    public dataStateChange(state: DataStateChangeEvent): void {
        this.state = state;
        this.state.sort = this.cachedSort; // only allow the sortChange method to change sorting
        this.recalculateDataView();
    }

    public sortChange(sort: SortDescriptor[]): void {
        if(this.isSortable){
            this.cachedSort = sort;
            this.state.sort = this.cachedSort;
            this.recalculateDataView();
        }
    }

    public setData(newData: any[]): void {
        this.data = newData;
        this.dataView = process(this.data, this.state);
    }

    public recalculateDataView(): void {

        const hasGroupChanges = this.state.group.length !== this.prevGroups.length;

        this.recalculatePageSize();

        this.hideOrShowAddedGroupColumns(this.prevGroups, this.state.group);

        this.prevGroups = this.state.group; // update grid state for subsequent calls
        this.dataView = process(this.data, this.state);

        if (hasGroupChanges) {
            this.expandOrCollapseGroups();
        }

    }

    /**
     * Automatically hides or shows a column when it is groupped by (requested by Lee).
     * @param oldGroups The previous list of active groups.
     * @param newGroups The current list of active groups.
     */
    private hideOrShowAddedGroupColumns(oldGroups: GroupDescriptor[], newGroups: GroupDescriptor[]): void {
        const addedGroups = newGroups.filter(newGroup => !oldGroups.find(oldGroup => oldGroup.field === newGroup.field));
        const removedGroups = oldGroups.filter(oldGroup => !newGroups.find(newGroup => newGroup.field === oldGroup.field));
        addedGroups.forEach(addedGroup => {
            if (this.hiddenColumns.indexOf(addedGroup.field) === -1) {
                this.hiddenColumns.push(addedGroup.field);
            }
        });
        removedGroups.forEach(removedGroup => {
            const hiddenColumnIndex = this.hiddenColumns.indexOf(removedGroup.field);
            if (hiddenColumnIndex !== -1) {
                this.hiddenColumns.splice(hiddenColumnIndex, 1);
            }
        });
    }

    private expandOrCollapseGroups(): void {
        if (!(this.grid) ||
            !(this.dataView) ||
            !(this.dataView.data) ||
            !(this.dataView.data.length) ||
            !(this.dataView.data.length > 0) ||
            !(this.dataView.data[0].hasOwnProperty('aggregates'))) {
            return;
        }
        const groups = this.dataView.data as CountedGroupResult[];
        this.recursiveExpandCollapse(groups);
    }

    /**
     * Based on information provided in forum thread:
     * https://www.telerik.com/forums/collapse-all-grid-rows-cbeb2927bdf4
     */
    private recursiveExpandCollapse(groups: CountedGroupResult[], groupPrefix: string = ''): void {
        if (!(groups) ||
            !(groups.length) ||
            !(groups.length > 0)) {
            return;
        }

        const hasSubGroup = this.hasSubGroup(groups[0]);

        let nextPrefix = groupPrefix;
        if (groupPrefix.length !== 0) {
            nextPrefix += '_';
        }

        if (hasSubGroup) {
            for (let i = 0; i < groups.length; i++) {
                const groupIndex = nextPrefix + i.toString();
                const children = groups[i].items as CountedGroupResult[];
                this.recursiveExpandCollapse(children, groupIndex);
                this.grid.expandGroup(groupIndex);
                groups[i].count = children.map(c => c.count).reduce((prev, cur) => prev + cur);
            }
        } else {
            for (let i = 0; i < groups.length; i++) {
                const groupIndex = nextPrefix + i.toString();
                this.grid.collapseGroup(groupIndex);
                groups[i].count = groups[i].items.length;
            }
        }
    }

    private hasSubGroup(group: GroupResult): boolean {
        if (!(group.items) ||
            !(group.items.length) ||
            !(group.items.length > 0) ||
            !(group.items[0].hasOwnProperty('aggregates'))) {
            return false;
        }
        return true;
    }

    private recalculatePageSize(): void {
        if (this.state.group && this.state.group.length > 0) {
            this.state.take = CwGridComponent.MAX_PAGE_SIZE;
        } else {
            if (this.customPageSize) {
                this.state.take = this.customPageSize;
            } else {
                this.state.take = CwGridComponent.DEFAULT_PAGE_SIZE;
            }
        }
    }

    public toggleGrouping() {
        if (this.groupable) {
            this.groupable = false;
        } else {
            this.groupable = CwGridComponent.DEFAULT_GROUPABLE;
        }
    }

    public openPreferenceModal() {
        const preferenceModal: NgbModalRef = this.modalService.open(CwGridPreferenceComponent, { backdrop: 'static', size: 'lg' });
        preferenceModal.componentInstance.grid = this;
        preferenceModal.result.then((result) => { }, (reason) => {
            if (reason && reason.oid) {
                this.applyPreference(reason);
            }
        });
    }

    public kendoSelectionChange(evt: SelectionEvent) {
        this.selectionChange.emit(evt);
    }

    public expandRow(rowIndex: number) {
        this.grid.expandRow(rowIndex);
    }

    getFileExtension(text: string): string {
        if (text && text.length) {
            const split = text.toUpperCase().split('.');
            return split[split.length - 1];
        }
    }

    openMedia(url): void {
        window.open(url);
    }

    public applyPreference(preference: GridPreference) {
        this.activeGridPreference = preference;
        const jsonObj = JSON.parse(preference.json) as GridPreferenceJsonData;
        this.filterable = jsonObj.filterable;
        this.groupable = jsonObj.groupable;
        this.state.filter = jsonObj.filter;
        this.state.group = jsonObj.group;
        this.state.sort = jsonObj.sort;
        this.cachedSort = jsonObj.sort;
        this.hiddenColumns = jsonObj.hiddenColumns;
        this.configureColumns(jsonObj.columnOrder, jsonObj.columnWidths);
        this.recalculateDataView();
    }

    public clearPreference() {
        this.filterable = false;
        this.groupable = false;
        this.state.filter = { logic: 'and', filters: [] };
        this.state.group = [];
        this.state.sort = [];
        this.hiddenColumns = [];
        this.resetHiddenColumns();
        this.recalculateDataView();
    }

    // endregion
    public resetHiddenColumns(): void {
        this.columns.forEach(column => {
            column.isHidden = false;
        })
        this.hiddenColumns.length = 0;
        this.showColumnMenuPopup = false;
    }

    public applyHiddenColumns(): void {
        this.showColumnMenuPopup = false;
    }

    public toggleHideColumn(column: CwColumnComponent){
        column.isHidden = !column.isHidden;
        if(column.isHidden){
            this.hiddenColumns.push(column.name);
            this.columns.splice (this.columns.indexOf(column), 1);
            this.assignColumnSortOrderByPosition();
        } else {
            this.hiddenColumns.splice(this.hiddenColumns.indexOf(column.name),1);
            this.columns.push(column);
            this.assignColumnSortOrderByPosition();
        }
    }

    private getColumnDefinitionByCwColumnComponent(cwColumnComponent: CwColumnComponent): CwColumnDefinition {
        if (cwColumnComponent.name) {
            const columnDefinition = this.gridDef.columns[cwColumnComponent.name];
            if (columnDefinition) {
                return columnDefinition;
            }
        }
        return {};
    }

    public exportToExcel(): void {
        const actionColumn: CwColumnComponent = this.columns.find(column => column.name === "action");
        const isActiveColumn: CwColumnComponent = this.columns.find(column => column.name === "isActive");
        if(actionColumn || isActiveColumn){
            if(this.hiddenColumns && this.hiddenColumns.indexOf("action") === -1 || this.hiddenColumns.indexOf("isActive") === -1){
                if(actionColumn) this.columns.splice(this.columns.indexOf(actionColumn), 1);
                if(isActiveColumn) this.columns.splice(this.columns.indexOf(isActiveColumn), 1);
                const thisVariable = this;
                setTimeout(function () {
                    thisVariable.grid.saveAsExcel();
                    }, 100);
                setTimeout(function () {
                    if(actionColumn) thisVariable.columns.push(actionColumn);
                    if(isActiveColumn) thisVariable.columns.push(isActiveColumn);
                }, 200);
            } else {
                this.grid.saveAsExcel();
            }
        } else {
            this.grid.saveAsExcel();
        }
    }

    public onExcelExport(e: any): void {
        const sheet = e.workbook.sheets[0];
        sheet.rows.forEach(item => {
            if (item.type === 'footer') {
                item.cells.forEach(cell => {
                    if (cell.value) {
                        var index = cell.value.search(/\d/);
                        if(Number(index) > -1 ){
                            cell.value = cell.value.substring(Number(index), cell.value.length);
                        }
                    }
                });
            }
            if (item.type === 'group-footer') {
                item.cells.forEach(cell => {
                    if (cell.value) {
                        var index = cell.value.search(/\d/);
                        if(Number(index) > -1 ){
                            cell.value = cell.value.substring(Number(index), cell.value.length);
                        } else {
                            cell.value = null;
                        }
                    }
                });
            }
            if(item.type === 'group-header'){
                item.cells.forEach(cell => {
                    var newValue: string = "";
                    if (cell.value) {
                        var indexSemiColon = cell.value.indexOf(":");
                        if(Number(indexSemiColon) > -1 ){
                            newValue = cell.value.substring(0, (Number(indexSemiColon) + 1));
                        }
                        var lastIndex = this.getLastIndex(cell.value, "}");
                        cell.value = cell.value.substring((Number(lastIndex) + 1), (cell.value.length - 3));
                        cell.value.replace("()", "");
                        cell.value = newValue + " " + cell.value;
                        // var numbers = cell.value.match(/\d+/g);
                        // numbers.forEach(n => {newNumber += n});
                        // var x = newNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        // cell.value = newValue + " " + newNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                });
            }
        });
        if (this.exportHeader) {
            sheet.rows.unshift(this.exportHeader);
        }
    }

    public getLastIndex(tsString: string, tsValueToMatch: string) {
        var index = 0, i = -1;
        while ((i = tsString.indexOf(tsValueToMatch, i+1)) != -1){
            index = i;
        }
        return index;
    }

    public getDataToExport(): ExcelExportData {
        const exportState: State = {
            group: this.state.group,
            sort: this.state.sort,
            filter: this.state.filter,
        };
        return {
            data: process(this.data, exportState).data,
            group: exportState.group,
        };
    }

    public toggleBatchUpdate(): void {
        const findElementByClass = (elementList: HTMLCollection, targetClass: string): Element => {
            return Array.from(elementList).find(f => Array.from(f['classList']).includes(targetClass));
        };

        const findElementByTagName = (elementList: HTMLCollection, targetTag: string): Element => {
            return Array.from(elementList).find(f => f.tagName === targetTag.toUpperCase());
        };

        // The goal is to consistently select the correct element that the BatchUpdateRowComponent will be appended
        const gridAriaRoot = findElementByClass(this.grid.wrapper.nativeElement.children, 'k-grid-aria-root');

        const gridHeader = findElementByClass(gridAriaRoot.children, 'k-grid-header');

        const gridHeaderWrap = findElementByClass(gridHeader.children, 'k-grid-header-wrap');

        const table = findElementByTagName(gridHeaderWrap.children, 'TABLE');

        const thead = findElementByTagName(table.children, 'THEAD');

        if (!this.batchUpdateRowComponentInstance) {
            const componentFactory = this._componentFactoryResolver.resolveComponentFactory(BatchUpdateRowComponent);
            this.batchUpdateRowComponentInstance = this._viewContainerRef.createComponent(componentFactory);

            const batchUpdateRowComponentElement = this.batchUpdateRowComponentInstance.location.nativeElement;
            thead.appendChild(batchUpdateRowComponentElement);

            this.batchUpdateRowComponentInstance.instance.columns = this.columns;
            this.batchUpdateRowComponentInstance.instance.hiddenColumns = this.hiddenColumns;

            this.initBatchUpdateSubscription();
        } else {
            // Destroy THEN set to null for state management
            this.batchUpdateRowComponentInstance.destroy();
            this.batchUpdateRowComponentInstance = null;
        }
    }

    async updateGridPreference() {
        const jsonObj = {
            filterable: this.filterable,
            groupable: this.groupable,
            filter: this.state.filter,
            group: this.state.group,
            sort: this.state.sort,
            columnOrder: this.columns.map(c => { return c.name }),
            columnWidths: this.generateColumnWidthJson(),
            hiddenColumns: this.hiddenColumns
        };
        const pref: GridPreference = JSON.parse(JSON.stringify(this.activeGridPreference));
        pref.json = JSON.stringify(jsonObj);
        const res = await this.prefService.save(pref);
        if (res) {
            this._toastr.success("Grid Preference Updated");
        }
    }

    private initBatchUpdateSubscription(): void {
        this.onBatchUpdateSubscription = this.batchUpdateRowComponentInstance.instance.onBatchUpdate.subscribe(update => {
            const filteredOids = this.dataView.data.reduce((acc, item) => [...acc, item.oid], []); // tslint:disable-line
            // Every record should have an Oid, if it doesn't oops️
            if (update.key && update.value) {
                this.state.filter.filters.forEach((f: any) => {
                    if (f.field === update.key) {
                        f.value = update.value;
                    }
                });
                this.setData(this.data.map(item => {
                    if (filteredOids.includes(item.oid)) {
                        item[update.key] = update.value;
                    }
                    return item;
                }));
            }
        });
    }

    cellClickHandler(event: CellClickEvent) {
        if (event.column.editable) {
            if (this.editDataItem) {
                this.closeEditor();
            } else {
                this.editDataItem = event.dataItem;
                this.editRowIndex = event.rowIndex;
                this.formGroup = FormGroupConverter.convertToForm(this.gridDef, event.dataItem);
                this.grid.editRow(event.rowIndex, this.formGroup);
            }
        }
		else if(this.exportCellClick){
            this.cellClickInitiated.emit(event);
        }
    }

    private closeEditor(): void {
        if (this.formGroup.dirty) {
            FormGroupConverter.updateDataItem(this.editDataItem, this.formGroup);
            this.editDataItem._isDirty = true;
        }
        this.grid.closeRow(this.editRowIndex);
        this.editDataItem = null;
        this.editRowIndex = null;
        this.formGroup = null;
    }

    calculateRowClass = (context: RowClassArgs) => {
      if (context && context.dataItem && context.dataItem.isDairySubtotal) {
        return 'pds-blue-background'
      }
      if (context && context.dataItem && context.dataItem.isNutritionistSubTotal) {
        return 'pds-yellow-background'
      }
        // if (context.dataItem && context.dataItem._isDirty) {
        //     return { 'cw-grid-modified-row': true };
        // }
        // return null;
    }

    public addHandler(event) {
        this.addInitiated.emit(event);
    }

    hideDuplicateCellValue(column: CwColumnComponent, dataItem: any, rowIndex: number): boolean {
        const ri: number = rowIndex - this.state.skip;
        if (column.hideDuplicate && ri !== 0) {
            const fieldName = column.name;
            const prevDataItem = this.dataView.data[ri - 1];
            if(dataItem && prevDataItem){
                if (dataItem[fieldName] === prevDataItem[fieldName]) {
                    return true;
                }
            }
        }
        return false;
    }

}

/**
 * An extension of the GroupResult that includes
 * additional count property which should contain
 * the count for all items that the group contains.
 */
interface CountedGroupResult extends GroupResult {
    count: number;
}

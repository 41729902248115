import { Component, OnInit } from '@angular/core';
import {CONSTANTS} from '../../lib/constants';
import {
  MonthEndService, PDSService,
  Entity, MillDelivery
} from '../../api/generated-api';
import {Observable, Subscription} from "rxjs/index";
import {UrlObfuscationService} from "../../lib/obfuscate.service";
import {ActivatedRoute, Router} from '@angular/router';
import {AppUtilities} from "../../lib/appUtilities.service";
import {UserService} from "../../services/user.service";

@Component({
  selector: 'mill-delivery-detail',
  templateUrl: './mill-delivery-detail.component.html',
  styleUrls: ['./mill-delivery-detail.component.css']
})
export class MillDeliveryDetailComponent implements OnInit {

  public details: Array<MillDelivery> = [];
  public monthString: string = '';
  public months: Array<{ nMonth: number, sMonth: string }> = CONSTANTS.MONTHS_SELECT_LIST;
  public years: Array<number> = CONSTANTS.YEARS;
  public selectedMonth: number;
  public selectedYear: number;
  public userSubscription: Subscription;
  public activeNutritionistOid: number;
  public constNutritionist: string = CONSTANTS.SECURITY_GROUPING.NUTRITIONIST;
  public constSuper: string = CONSTANTS.SECURITY_GROUPING.SUPERUSER;
  public constAdmin: string = CONSTANTS.SECURITY_GROUPING.ADMIN;
  public constMill: string = CONSTANTS.SECURITY_GROUPING.MILL;
  public loggedInEntity: Entity = <Entity>{};



  constructor(private pds: PDSService,
              private monthEnd: MonthEndService,
              private activatedRoute: ActivatedRoute,
              private userService: UserService,) { }

  ngOnInit() {
    this.getParamSubscription();
    this.initiate();
  }

  async initiate() {
    await this.getLoggedInEntity();
    await this.setClosedState();
    await this.getDeliveryDetail();
  }

  async getLoggedInEntity(): Promise<void> {
    this.loggedInEntity = await this.userService.getLoggedInEntityUser();
  }

  async getParamSubscription(): Promise<void> {
    this.userSubscription = this.activatedRoute.params.subscribe(async (param: {id: string}) => {
      if (param.id) {
        const foundNutritionist = +UrlObfuscationService.deobfuscate(param.id);
        this.activeNutritionistOid = foundNutritionist;
      }
    });
  }

  async setClosedState() {
    const response = await this.userService.getMonthCloseState();
      this.selectedMonth = response.currentMonth;
      this.selectedYear = response.currentYear;
    this.setMonthString();
  }

  isNotAdmin() {
    if(this.loggedInEntity.securityGroupName != this.constSuper || this.loggedInEntity.securityGroupName != this.constAdmin) {
      return true;
    }
  }

  selectDate() {
    this.setMonthString();
    this.getDeliveryDetail();
  }

  setMonthString() {
    this.monthString = this.months.find(month => +month.nMonth === +this.selectedMonth).sMonth;
  }

  async getDeliveryDetail() {
    // console.log('look here')
    const response = await this.pds.getMillDeliveriesByMonthAndYear(this.loggedInEntity.entityOid_Master, this.selectedMonth, this.selectedYear).toPromise();
    this.details = response;
  }

  getTotalByColumn(columnName: string) {
    return AppUtilities.addByColumn(this.details, columnName);
  }

  public isHiddenFromMill() {
    if(this.loggedInEntity.securityGroupName === this.constMill ) {
      return true;
    } else {
      return false;
    }
  }


}

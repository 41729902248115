import {NgModule} from '@angular/core';
import {GridModule, ExcelModule, RowFilterModule, FilterMenuModule} from '@progress/kendo-angular-grid';
import {PopupModule} from '@progress/kendo-angular-popup';
import {DateInputModule, DatePickerModule, DateRangeModule} from '@progress/kendo-angular-dateinputs';
import {DropDownListModule, MultiSelectModule} from '@progress/kendo-angular-dropdowns';
import {DialogModule} from '@progress/kendo-angular-dialog';
import {CommonModule} from '@angular/common';
import {CwGridComponent} from './cw-grid.component';
import {CwColumnComponent} from './cw-column.component';
import {CwGridPreferenceComponent} from './cw-grid-preference.component';
import {FormsModule} from '@angular/forms';

import {GridPreferenceService} from './grid-preference.service';
import {ExcelExportModule} from '@progress/kendo-angular-excel-export';
import {MultiSelectFilterComponent} from './multi-select-filter/multi-select-filter.component';
import {BatchUpdateRowComponent} from './batch-update-row/batch-update-row.component';
import {DateRangeFilterComponent} from './date-range-filter/date-range-filter.component';

@NgModule({
  declarations: [
    CwGridComponent,
    CwColumnComponent,
    CwGridPreferenceComponent,
    MultiSelectFilterComponent,
    DateRangeFilterComponent,
    BatchUpdateRowComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    GridModule,
    PopupModule,
    DatePickerModule,
    DropDownListModule,
    DialogModule,
    ExcelModule,
    ExcelExportModule,
    RowFilterModule,
    FilterMenuModule,
    MultiSelectModule,
    DateRangeModule,
    DateInputModule
  ],
  exports: [
    CwGridComponent,
    CwColumnComponent
  ],
  providers: [
    GridPreferenceService
  ],
  entryComponents: [
    BatchUpdateRowComponent
  ]
})
export class CwGridModule { }

import { Component, OnInit } from '@angular/core';
import {Notification, PDSService} from '../../api/generated-api';
import {Router} from '@angular/router';
import { parse } from 'querystring';
import { UrlObfuscationService } from 'src/app/lib/obfuscate.service';
import {SwalDialog} from "../../lib/swal-dialog";
import {CONSTANTS} from "../../lib/constants";

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  public notifications: Notification[] = [];
  public isLoading: boolean = false;

  constructor(private pds: PDSService, private router: Router) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.initiate();
  }

  getStatusClass(isRead: boolean): string {
    return isRead ? 'badge-primary' : 'badge-secondary';
  }

  async initiate() {
    this.notifications = await this.pds.getListOfNotifications().toPromise();
    this.isLoading = false;
    const notificationOidsToMarkRead = [];
    this.notifications.forEach(n => {
      if (!n.isRead) {
       notificationOidsToMarkRead.push(n.oid);
      }
    });
    if (notificationOidsToMarkRead.length > 0) {
      this.pds.markNotificationsAsRead(notificationOidsToMarkRead).toPromise();
    }
  }

  navigateToNotification(url) {
    const parsedArray: string[] = url.split('/');
    // tslint:disable-next-line
    if (!isNaN(+parsedArray[parsedArray.length -1])) {
      // tslint:disable-next-line
      parsedArray[parsedArray.length -1] = UrlObfuscationService.obfuscate(parsedArray[parsedArray.length -1]);
      let newUrl: string = '';
      parsedArray.forEach(s => {
        newUrl += s + '/';
      });
      this.router.navigate(['' + newUrl]);
    } else {
      this.router.navigate(['' + url]);
    }
  }

  hideNotification(obj) {
    this.pds.hideNotification(obj).toPromise();
    this.initiate();
  }

  async clearAllNotifications() {
    const response = await SwalDialog.show({
      title: 'Are You Sure?',
      text: 'Are you sure you want to clear all of your notifications? Once removed they cannot be retrieved.',
      icon: 'warning',
      dangerMode: false,
      buttons: {
        test1: { text: 'No', value: CONSTANTS.SWAL_RESPONSE.NO},
        test2: { text: 'Yes', value: CONSTANTS.SWAL_RESPONSE.YES },
      }
    });
    if (response === CONSTANTS.SWAL_RESPONSE.YES) {
      const res = await this.pds.clearNotifications().toPromise();
      this.notifications = await this.pds.getListOfNotifications().toPromise();
    } else {
      return;
    }

  }

}

import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { UserService } from '../../services/user.service';
import { AppUtilities } from '../../lib/appUtilities.service';
import { CONSTANTS } from '../../lib/constants';
import { ToastrService } from 'ngx-toastr';
import { UserDTO, PDSService, Entity, ValuePair } from '../../api/generated-api';
import { SwalDialog } from "../../lib/swal-dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { UrlObfuscationService } from "../../lib/obfuscate.service";
import { Subscription } from "rxjs/index";
import { UsersService, SecurityGroupObject } from './users.service';
import { CompPlanExtended } from './nutritionist-user/nutritionist-user.component';
import {SideBarListComponent} from "../../components/side-bar-list/side-bar-list.component";

@Component({ selector: 'users', templateUrl: './users.component.html', styleUrls: ['./users.component.scss'] })

export class UsersComponent implements OnInit, OnDestroy {

  public isSaving: boolean = false;
  public securityGroups: SecurityGroupObject[] = CONSTANTS.SECURITY_GROUP_DROPDOWN_LIST;
  public users: ValuePair[] = [];
  public masterUsersList: ValuePair[] = [];
  public activeUser: UserDTO = <UserDTO>{};
  public loggedInEntity: Entity = <Entity>{};
  public isAdminView: boolean = false;
  public pageLoading: boolean = true;
  public originalCheckSum: string = '';
  public showPreference: string = 'Active';
  public constMillOid: number = CONSTANTS.SECURITY_GROUPING_OID.MILL;
  public constNutritionistOid: number = CONSTANTS.SECURITY_GROUPING_OID.NUTRITIONIST;
  public constSuperOid: number = CONSTANTS.SECURITY_GROUPING_OID.SUPERUSER;
  public constAdminOid: number = CONSTANTS.SECURITY_GROUPING_OID.ADMIN;
  public constAdmin: string = CONSTANTS.SECURITY_GROUPING.ADMIN;
  public constSuper: string = CONSTANTS.SECURITY_GROUPING.SUPERUSER;
  public userSubscription: Subscription;
  public userTypeFilter: string = 'All';

  public isFirstTimeOnPage: boolean = true;

  @ViewChild('sideBarList', null) sideBarListRef: SideBarListComponent;

  constructor(private pdsService: PDSService,
    private userService: UserService,
    private appUtilities: AppUtilities,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private usersService: UsersService) {
  }

  async ngOnInit(): Promise<void> {
    // console.log("User component init");
    await this.initiate();
    this.getParamSubscription();
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  async initiate(): Promise<void> {
    await this.getLoggedInUser();
    await this.getUsersList();
    await this.usersService.getAllRegions();
    await this.usersService.getAllCompPlans();
    await this.usersService.getAllMills();
    this.filterUserList();
  }

  async getLoggedInUser() {
    this.loggedInEntity = await this.userService.getLoggedInEntityUser();
    if (this.loggedInEntity && this.loggedInEntity.securityGroupName && (this.loggedInEntity.securityGroupName === CONSTANTS.SECURITY_GROUPING.ADMIN || this.loggedInEntity.securityGroupName === CONSTANTS.SECURITY_GROUPING.SUPERUSER)) {
      this.isAdminView = true;
    }
  }

  filterUserList(){
    if (!this.masterUsersList.length) {
      this.masterUsersList = this.users;
    }
    if (this.showPreference === 'Active') {
      this.users = this.masterUsersList.filter(item => {
        return item.isActive;
      });
    } else if (this.showPreference === 'Inactive') {
      this.users = this.masterUsersList.filter(item => {
        return !item.isActive;
      });
    } else {
      this.users = JSON.parse(JSON.stringify(this.masterUsersList));
    }
  }

  getParamSubscription(): void {
    this.userSubscription = this.activatedRoute.params.subscribe(async (param: { id: string }) => {
      this.pageLoading = true;
      if (param.id) {
        const foundActiveUser = this.users.find(user => user.oid === +UrlObfuscationService.deobfuscate(param.id));
        if (foundActiveUser) {
          this.activeUser = await this.pdsService.getUserDTOByOid(foundActiveUser.oid).toPromise();
          this.originalCheckSum = this.appUtilities.createCheckSum(this.activeUser);
        } else {
          const newPair = this.createDefaultUserValuePair();
          this.users.push(newPair);
          const newUser = this.createDefaultUser();
          this.activeUser = newUser;
          this.originalCheckSum = this.appUtilities.createCheckSum(this.activeUser);
        }
      } else {
        this.changeActiveUser(this.users[0].oid);
      }
      this.pageLoading = false;
    });
  }

  async getUsersList(): Promise<void> {
    try {
      this.users = await this.pdsService.getUserListByLoggedInUser().toPromise();
      this.masterUsersList = JSON.parse(JSON.stringify(this.users));
    } catch (error) {
      this.appUtilities.showServerErrorSwal(error.message);
    }
  }

  async setActiveUser(oid: number): Promise<void> {
    this.changeActiveUser(this.users[0].oid);
  }

  changeActiveUser(oid: number): void {
    this.router.navigate([`/users/${UrlObfuscationService.obfuscate(oid)}`]);
  }

  createDefaultUser(): UserDTO {
    const newUserDTO: UserDTO = <UserDTO>{};
    newUserDTO.firstName = '';
    newUserDTO.dairies = [];
    newUserDTO.regions = [];
    newUserDTO.compPlans = this.usersService.allCompPlans.map(plan => {
      const newExt: CompPlanExtended = <CompPlanExtended>{ ...plan };
      newExt.isChecked = true;
      return newExt;
    });
    newUserDTO.isActive = true;
    newUserDTO.securityGroupOid = -1;
    newUserDTO.oid = -1;
    return newUserDTO;
  }

  createDefaultUserValuePair(): ValuePair {
    const newUserValuePair: ValuePair = <ValuePair>{};
    newUserValuePair.isChecked = false;
    newUserValuePair.isActive = false;
    newUserValuePair.oid = -1;
    newUserValuePair.name = '';
    return newUserValuePair;
  }

  async canDeactivate(): Promise<boolean> {
    let bReturn = false;
    if (this.activeUser && ((+this.activeUser.oid === -1) || (this.originalCheckSum !== '' && this.appUtilities.createCheckSum(this.activeUser) !== this.originalCheckSum))) {
      const res: string = await this.appUtilities.showUnsavedChangesSwal();
      if (res === CONSTANTS.SWAL_RESPONSE.YES) {
        await this.saveUpdateUser(true);
        bReturn = true;
      } else if (res === CONSTANTS.SWAL_RESPONSE.NO) {
        this.users = JSON.parse(JSON.stringify(this.masterUsersList));
        bReturn = true;
      } else {
        bReturn = false;
      }
    } else {
      bReturn = true;
    }
    return bReturn;
  }

  isInvalid(): string {
    let rString: string = '';
    if (!this.activeUser.firstName || this.activeUser.firstName === '') {
      rString = rString.concat('User must have a first name </br>');
    }
    if (!this.activeUser.lastName || this.activeUser.lastName === '') {
      rString = rString.concat('User must have a last name </br>');
    }
    if (!this.activeUser.email || this.activeUser.email === '') {
      rString = rString.concat('User must have a valid email </br>');
    }
    if (!this.activeUser.loginName || this.activeUser.loginName === '') {
      rString = rString.concat('User must have a valid login name </br>');
    }
    if (this.isRegionError()) {
      rString = rString.concat('All regions must have a name selected from the dropdown list </br>');
    }
    if (this.isMillMasterError()) {
      rString = rString.concat('Must assign user to a Company from the dropdown list </br>');
    }
    return rString;
  }

  async saveUpdateUser(isFromNavigate: boolean = false): Promise<void> {
    const isInvalidString: string = this.isInvalid();
    if (isInvalidString && isInvalidString !== '') {
      const myhtml = document.createElement('div');
      myhtml.innerHTML = isInvalidString;
      SwalDialog.show({
        title: 'Validation Failed',
        content: myhtml,
        icon: 'error',
      });
      return;
    } else {
      this.isSaving = true;
      let isNew: boolean = false;
      if (!this.activeUser.oid || +this.activeUser.oid <=0) {
        this.activeUser.oid = null;
        isNew = true;
      }
      try {
        if(this.activeUser.tempPassword && this.activeUser.oid !== null){
          await this.pdsService.reset2temporaryPassword(this.activeUser.oid, this.activeUser.tempPassword).toPromise();
        }
        const response = await this.pdsService.addUpdateUserDTO(this.activeUser).toPromise();
        this.toastr.success('Successfully Saved');
        this.originalCheckSum = this.appUtilities.createCheckSum(this.activeUser);
        await this.getUsersList();
        const savedOid: number = response.oid;

        if (!isFromNavigate && !isNew) {
          this.showPreferenceChange(this.showPreference);
        }
        if(isNew){
          this.activeUser = response;
          this.originalCheckSum = this.appUtilities.createCheckSum(this.activeUser);
          this.changeActiveUser(savedOid);
        }
        this.isSaving = false;
      } catch (error) {
        this.isSaving = false;
        this.appUtilities.showServerErrorSwal(error.message);
      }
    }
  }

  async generateRandomPassword(){
    const tempPassword: string = await this.pdsService.getTemporaryPassword().toPromise();
    this.activeUser.tempPassword = tempPassword;
  }

  isRegionError(): boolean {
    let rReturn: boolean;
    const foundErrorRegion = this.activeUser.regions.find(r => { return !r.oid || r.oid === -1 });
    foundErrorRegion ? rReturn = true : rReturn = false;
    return rReturn;
  }

  isMillMasterError(): boolean {
    let rReturn: boolean = false;
    if (+this.activeUser.securityGroupOid === this.constMillOid) {
      if (!this.activeUser.entityOid_Master || +this.activeUser.entityOid_Master === -1) {
        rReturn = true;
      }
    }
    return rReturn;
  }

  async cancel(): Promise<void> {
    if(this.activeUser.oid > 0 ) {
      this.activeUser = await this.pdsService.getUserDTOByOid(this.activeUser.oid).toPromise();
      this.originalCheckSum = this.appUtilities.createCheckSum(this.activeUser);
    } else{
      await this.getUsersList();
    }
  }

  async filterUsersList(event) {
    this.sideBarListRef.clearSearch();
    if (!this.masterUsersList.length) {
      this.masterUsersList = this.users;
    }
    this.userTypeFilter = event;
    if (this.userTypeFilter === 'Nutritionist') {
      this.users = this.masterUsersList.filter(item => {
        return item.securityGroup === 'Nutritionist';
      });
    } else if (this.userTypeFilter === 'Mill') {
      this.users = this.masterUsersList.filter(item => {
        return item.securityGroup === 'Mill';
      });
    } else if (this.userTypeFilter === 'Admin') {
      this.users = this.masterUsersList.filter(item => {
        return item.securityGroup === 'Admin' || item.securityGroup === 'SuperUser';
      });
    }  else {
      this.users = JSON.parse(JSON.stringify(this.masterUsersList));
    }
    const activeStillInList = this.users.find(user => user.oid === this.activeUser.oid);
    if(activeStillInList) {
      this.setActiveUser(activeStillInList.oid);
    } else {
      this.setActiveUser(this.users[0].oid);
    }
  }

  showPreferenceChange(event): void {
    this.showPreference = event;
    this.filterUserList();
    const activeStillInList = this.users.find(user => user.oid === this.activeUser.oid);
    if(!activeStillInList){
      this.changeActiveUser(this.users[0].oid);
    }
  }

  switchUserView(oid: string): void {
    this.activeUser.securityGroupOid = +oid;
    this.activeUser.securityGroupName = this.securityGroups.find(group => group.oid === +oid).value;
  }

  emailBlur(){
    // console.log("emailblur", this.activeUser.loginName, this.activeUser.email)
    if(this.activeUser.oid === null || this.activeUser.oid <= 0){
      // console.log("emailblur1", this.activeUser.loginName, this.activeUser.email)
      if(this.activeUser.loginName === null || this.activeUser.loginName === "" || this.activeUser.loginName === undefined){
      // console.log("emailblur2", this.activeUser.loginName, this.activeUser.email);
        this.activeUser.loginName = this.activeUser.email;
      }
    }
  }

  async addNewUser(): Promise<SwalDialog> {
    if (+this.activeUser.oid === -1) {
      return SwalDialog.show({
        title: 'Cannot Add New',
        text: 'There is already a new user creation in progress. Please cancel or save before adding another.',
        icon: 'warning',
        dangerMode: true,
      });
    } else {
      this.changeActiveUser(-1);
      const res = await this.pdsService.getTemporaryPassword().toPromise();
      if(res) {
        this.activeUser.tempPassword = res;
        // console.log("addnew", this.activeUser.tempPassword);
      }
    }
  }

  updateInfo(event) {
    this.activeUser = event;
    this.originalCheckSum = this.appUtilities.createCheckSum(this.activeUser);
    this.setActiveUser(this.activeUser.oid);
  }

}

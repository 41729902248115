import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { LookupService } from '../../../services/lookup.service';
import {UserDTO, Lookup, ValuePair, Entity, PDSService} from '../../../api/generated-api';
import { Subscription } from 'rxjs';
import { SecurityGroupObject, UsersService } from '../users.service';
import { CONSTANTS } from 'src/app/lib/constants';
import {SwalDialog} from "../../../lib/swal-dialog";
@Component({
  selector: 'mill-user',
  templateUrl: './mill-user.component.html',
  styleUrls: ['./mill-user.component.css']
})
export class MillUserComponent implements OnInit, OnDestroy {

  @Output() changeGroup: EventEmitter<any> = new EventEmitter();
  @Input() public viewOnly: boolean;
  @Input() public selectedUser: UserDTO;
  @Input() public loggedInEntity: Entity;

  @Output() emailBlur: EventEmitter<any> = new EventEmitter();
  @Output() generateRandomPassword: EventEmitter<any> = new EventEmitter();

  public securityGroups: SecurityGroupObject[] = CONSTANTS.SECURITY_GROUP_DROPDOWN_LIST;
  public states: Lookup[] = [];
  public millsList: ValuePair[] = [];
  public constSuper: string = CONSTANTS.SECURITY_GROUPING.SUPERUSER;
  public selectedSecurityGroupOid: number;
  public userSubscription: Subscription;

  @ViewChild("firstName", null) nameEl: ElementRef;

  constructor(private lookupService: LookupService,
    private usersService: UsersService,
              private pdsService: PDSService) {
    this.states = this.lookupService.findLookupByLookupName('State');
  }

  async ngOnInit() {
    this.millsList = this.usersService.allMills;
    this.setDefaultState();
    this.setDefaultMaster();
    this.selectedSecurityGroupOid = this.selectedUser.securityGroupOid;
    this.nameEl.nativeElement.focus();
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  setDefaultState() {
    if(this.selectedUser){
      if(!this.selectedUser.state){
        this.selectedUser.state = "-1";
      }
    }
  }

  setDefaultMaster() {
    if(this.selectedUser){
      if(!this.selectedUser.entityOid_Master){
        this.selectedUser.entityOid_Master = -1;
      }
    }
  }

  selectUserGroup() {
    if (this.selectedUser && this.selectedUser.securityGroupName === this.constSuper) {
      this.selectedUser.securityGroupOid = +this.selectedSecurityGroupOid;
      this.changeGroup.next(this.selectedUser.securityGroupOid);
    } else {
      if (+this.selectedSecurityGroupOid === CONSTANTS.SECURITY_GROUPING_OID.SUPERUSER) {
        this.selectedSecurityGroupOid = this.selectedUser.securityGroupOid;
        SwalDialog.show({
          title: 'Unable To Select',
          text: 'Only a super user has the ability to change the security group to a super user.',
          icon: 'warning',
          dangerMode: true,
        });
      }  else {
        this.selectedUser.securityGroupOid = +this.selectedSecurityGroupOid;
        this.changeGroup.next(this.selectedUser.securityGroupOid);
      }
    }
  }

  canEditCompanyName() {
    if ((!this.selectedUser.companyName || this.selectedUser.companyName === undefined) && !this.viewOnly) {
      return true;
    } else {
      return false;
    }
  }

   onEmailBlur(){
    this.emailBlur.next();
  }

  onGenerateRandomPassword(){
    this.generateRandomPassword.next();
  }

}

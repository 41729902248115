import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddNutritionistsModalComponent } from '../../components/add-nutritionists-modal/add-nutritionists-modal.component';
import {
  DairyDTO, Entity, Lookup, Association, DairyNutritionistDTO, Feed, PDSService,
  RegionDTO, NutritionistDTO, FeedPlanDTO, ValuePair
} from '../../api/generated-api';
import { UserService } from 'src/app/services/user.service';
import { CONSTANTS } from 'src/app/lib/constants';
import { AppUtilities } from 'src/app/lib/appUtilities.service';
import { LookupService } from 'src/app/services/lookup.service';
import { CwGridComponent } from '../../components/cw-grid/cw-grid.component';
import { AddFeedPlanModalComponent } from '../../components/add-feed-plan-modal/add-feed-plan-modal.component';
import { ToastrService } from 'ngx-toastr';
import { SwalDialog } from '../../lib/swal-dialog';
import { UrlObfuscationService } from '../../lib/obfuscate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/index';

@Component({
  selector: 'dairies',
  templateUrl: './dairies.component.html',
  styleUrls: ['./dairies.component.scss']
})
export class DairiesComponent implements OnInit {

  public isDataLoaded: boolean = false;

  public dairies: ValuePair[] = [];
  public masterDairiesList: ValuePair[] = [];
  public regions: ValuePair[] = [];
  public feeds: Feed[] = [];
  public activeDairy: DairyDTO = <DairyDTO>{};
  public loggedInEntity: Entity = <Entity>{};
  public originalCheckSumDairy: string = '';
  public showPreference: string = 'Active';
  public availableRegions = [];
  public availableNutritionists: NutritionistDTO[] = [];
  public nutritionists: NutritionistDTO[] = [];
  public constMill: string = CONSTANTS.SECURITY_GROUPING.MILL;
  public constNutritionist: string = CONSTANTS.SECURITY_GROUPING.NUTRITIONIST;
  public constSuper: string = CONSTANTS.SECURITY_GROUPING.SUPERUSER;
  public constAdmin: string = CONSTANTS.SECURITY_GROUPING.ADMIN;
  public percentInEdit: boolean = false;
  public userSubscription: Subscription;
  public isSubmitted: boolean = false;
  public securityGroup: string = '';
  public isNewDairy: boolean = false;

  @ViewChild('dairiesGrid', null) dairiesGrid: CwGridComponent;
  @ViewChild("dairyName", null) dairyNameEl: ElementRef;

  public lkpStates: Lookup[] = this.lookupService.findLookupByLookupName('State');
  constructor(private modalService: NgbModal,
              private pdsService: PDSService,
              private userService: UserService,
              private lookupService: LookupService,
              private appUtilities: AppUtilities,
              private toastr: ToastrService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  async ngOnInit(): Promise<void> {
    await this.getAllDairies();
    this.getParamSubscription();

    await this.initDairies();
    this.isDataLoaded = true;
    this.dairies = this.dairies.filter(dairy => {
      return dairy.isActive
    });
    //this.setActiveDairy(this.dairies[0].oid);
  }

  async getLoggedInUser(): Promise<void> {
    this.loggedInEntity = await this.userService.getLoggedInEntityUser();
    this.isAdminView();
  }

  async initDairies(): Promise<void> {
    await this.getLoggedInUser();
    await this.getAllRegions();
    await this.getAllFeeds();
    await this.getAllNutritionists();
  }

  getParamSubscription(): void {
    this.userSubscription = this.activatedRoute.params.subscribe(async (param: {id: string}) => {
      if (param.id) {
        const foundActiveDairy = this.dairies.find(dairy => dairy.oid === +UrlObfuscationService.deobfuscate(param.id));
        this.activeDairy = await this.pdsService.getDairyDTOByOid(foundActiveDairy.oid).toPromise();
        this.originalCheckSumDairy = this.appUtilities.createCheckSum(this.activeDairy);
      } else {
        if(this.dairies.length > 0){
          await this.router.navigate([`/dairies/${UrlObfuscationService.obfuscate(this.dairies[0].oid)}`]);
        }
      }
    });
  }

  async getAllRegions(): Promise<void> {
    this.regions = await this.pdsService.getAllRegionsList(true).toPromise();
  }

  async getAllNutritionists(): Promise<void> {
    const allNutritionists = await this.pdsService.getAllNutritionistDTOs().toPromise();
    this.nutritionists = allNutritionists.filter(n => {
      return n.isActive;
    });
  }

  async getAllDairies(): Promise<void> {
    try {
      this.dairies = await this.pdsService.getDairyListMapped2LoggedInUser(false).toPromise();
      this.masterDairiesList = JSON.parse(JSON.stringify(this.dairies));
    } catch (error) {
      await this.appUtilities.showServerErrorSwal(error);
    }
  }

  async getAllFeeds(): Promise<void> {
    this.feeds = await this.pdsService.getAllFeeds().toPromise();
  }

  async changeActiveDairy(oid: number): Promise<void> {
    this.router.navigate([`/dairies/${UrlObfuscationService.obfuscate(oid)}`]);
  }

  async setActiveDairy(oid: number, newDairy?): Promise<void> {
    if (oid !== -1) {
      this.activeDairy = await this.pdsService.getDairyDTOByOid(oid).toPromise();
    } else {
      this.activeDairy = newDairy;
    }
    this.originalCheckSumDairy = this.appUtilities.createCheckSum(this.activeDairy);
  }

  checkNutritionistsList() {
    this.activeDairy.nutritionists = this.activeDairy.nutritionists.filter(n => {
      if (this.loggedInEntity.securityGroupName === CONSTANTS.SECURITY_GROUPING.NUTRITIONIST) {
        return n.oid === this.loggedInEntity.oid;
      } else {
        return n;
      }
    });
  }

  createDefaultDairy(): DairyDTO {
    const newDairy: DairyDTO = <DairyDTO>{};
    newDairy.oid = -1;
    newDairy.companyName = '';
    newDairy.isActive = true;
    newDairy.regions = [];
    newDairy.feedPlanDTOs = [];
    newDairy.nutritionists = [];

    return newDairy;
  }

  createDefaultDairyValuePair(): ValuePair {
    const newDairyValue: ValuePair = <ValuePair>{};
    newDairyValue.oid = -1;
    newDairyValue.name = '';
    newDairyValue.isActive = true;
    newDairyValue.isChecked = false;

    return newDairyValue;
  }

  addDairy(): Promise<void> {
    this.isNewDairy = true;
    if (this.activeDairy.oid === -1) {
      return SwalDialog.show({
        title: 'Cannot Add New',
        text: 'There is already a new Dairy creation in progress. Please cancel or save before adding another.',
        icon: 'warning',
        dangerMode: true,
      });
    } else {
      const newDairy = this.createDefaultDairy();
      const newDairyValue = this.createDefaultDairyValuePair();
      this.dairies.push(newDairyValue);
      // this.activeDairy = newDairy;
      this.setActiveDairy(-1, newDairy);
      this.dairyNameEl.nativeElement.focus();
    }
  }

  addEditNutritionist(nutritionist?: DairyNutritionistDTO, index?: number): void {
    const nutritionistModal: NgbModalRef = this.modalService.open(AddNutritionistsModalComponent, { backdrop: 'static' });
    if (nutritionist) {
      nutritionistModal.componentInstance.nutritionist = nutritionist;
    }
    nutritionistModal.result.then((result) => { }, (reason) => {
      if (reason && reason.oid) {
        if (index) {
          this.activeDairy.nutritionists[index] = reason;
        } else {
          this.activeDairy.nutritionists.push(reason);
        }
      }
    });
  }

  addEditFeedPlan(feedPlan?: FeedPlanDTO): void {
    let isEditing: boolean = false;
    const feedPlanModal: NgbModalRef = this.modalService.open(AddFeedPlanModalComponent, { backdrop: 'static' });
    if (feedPlan) {
      isEditing = true;
      const existingFeedPlan: FeedPlanDTO = JSON.parse((JSON.stringify(feedPlan)));
      feedPlanModal.componentInstance.feedPlanDTO = existingFeedPlan;
      feedPlanModal.componentInstance.feedOid = existingFeedPlan.feedOid;
    }
    feedPlanModal.componentInstance.dairyOid = this.activeDairy.oid;
    feedPlanModal.result.then((result) => { }, (reason) => {
      if (reason && reason.feedOid && !isEditing) {
        this.activeDairy.feedPlanDTOs.push(reason);
      } else if (reason && reason.feedOid && isEditing) {
        this.activeDairy.feedPlanDTOs.find(f => {
          if (f.oid === feedPlan.oid) {
            f.numberOfCows = reason.numberOfCows;
            f.feedName = reason.feedName;
            f.poundsPerDay = reason.poundsPerDay;
          }
        });
      }
    });
  }

  removeFeedPlan(index: number) {
    this.activeDairy.feedPlanDTOs.splice(index, 1);
  }

  async save(isNavigationSave?: boolean): Promise<void> {
    try {
      if (this.activeDairy.oid === -1) {
        this.activeDairy.oid = null;
      }
      const isInvalidString: string = this.isInvalid();
      if (isInvalidString && isInvalidString !== '') {
        this.isSubmitted = true;
        const myhtml = document.createElement('div');
        myhtml.innerHTML = isInvalidString;
        SwalDialog.show({
          title: 'Validation Failed',
          content: myhtml,
          icon: 'error',
        });
        return;
      } else {
        this.isSubmitted = true;
        const res = await this.pdsService.addUpdateDairyDTO(this.activeDairy).toPromise();
        this.isNewDairy = false;
        this.percentInEdit = false;
        this.toastr.success('Save successful');
        const savedOid: number = res.oid;
        this.getAllDairies();
        this.showPreferenceChange(this.showPreference);
        const foundDairy = this.dairies.find(d => d.oid === savedOid);
        if (foundDairy) {
          this.setActiveDairy(foundDairy.oid);
          // this.activeFeed = foundFeed;
        } else {
          this.setActiveDairy(this.dairies[0].oid);
          // this.activeFeed = this.feedsList[0];
        }
        this.originalCheckSumDairy = this.appUtilities.createCheckSum(this.activeDairy);
      }
    } catch (error) {
      this.appUtilities.showServerErrorSwal(error.message);
    }
  }

  isInvalid(): string {
    let rString: string = '';
    if (!this.activeDairy.companyName || this.activeDairy.companyName === '') {
      rString = rString.concat('Must have a company name to save a Dairy </br>');
    }
    return rString;
  }

  async cancel(): Promise<void> {
    this.isSubmitted = false;
    const currentOid: number = this.activeDairy.oid;
    await this.getAllDairies();
    const foundDairy: ValuePair = this.dairies.find(d => d.oid === currentOid);
    if (foundDairy) {
      this.setActiveDairy(foundDairy.oid);
    } else {
      this.setActiveDairy(this.dairies[0].oid);
    }
    // const currentOid: number = this.activeDairy.oid;
    // await this.getAllDairies();
    // const foundDairy: DairyDTO = this.dairies.find(f => f.oid === currentOid);
    // if (foundDairy) {
    //   this.activeDairy = foundDairy;
    // } else {
    //   this.activeDairy = this.dairies[0];
    // }
    // this.originalCheckSumDairy = this.appUtilities.createCheckSum(this.activeDairy);
  }

  async canDeactivate(): Promise<boolean> {
    if (this.activeDairy.oid === -1 ||
      (this.activeDairy && this.originalCheckSumDairy !== '' && this.appUtilities.createCheckSum(this.activeDairy) !== this.originalCheckSumDairy) // tslint:disable-line
    ) {
      const res: string = await this.appUtilities.showUnsavedChangesSwal();
      if (res === CONSTANTS.SWAL_RESPONSE.YES) {
        await this.save(true);
        this.percentInEdit = false;
        return true;
      } else if (res === CONSTANTS.SWAL_RESPONSE.NO) {
        this.dairies = JSON.parse(JSON.stringify(this.masterDairiesList));
        this.percentInEdit = false;
        return true;
      } else {
        return false;
      }
    } else {
      this.percentInEdit = false;
      return true;
    }
  }

  showPreferenceChange(event): void {
    if (!this.masterDairiesList.length) {
      this.masterDairiesList = this.dairies;
    }
    this.showPreference = event;
    if (this.showPreference === 'Active') {
      this.dairies = this.masterDairiesList.filter(item => {
        return item.isActive;
      });
    } else if (this.showPreference === 'Inactive') {
      this.dairies = this.masterDairiesList.filter(item => {
        return !item.isActive;
      });
    } else if (this.showPreference === 'Pending') {
      this.dairies = this.masterDairiesList.filter(item => {
        return item.isPending;
      });
    } else {
      this.dairies = JSON.parse(JSON.stringify(this.masterDairiesList));
    }
    if (this.dairies.find(r => r.oid === this.activeDairy.oid)) {
      this.setActiveDairy(this.activeDairy.oid);
    } else {
      this.setActiveDairy(this.dairies[0].oid);
    }
  }

  getTotalByColumn(columnName: string) {
    return AppUtilities.addByColumn(this.activeDairy.nutritionists, columnName);
  }

  addRegionLineItem() {
    const oidList = this.activeDairy.regions.map(d => d.oid);
    this.availableRegions = this.regions.filter(region => {
      if (oidList.indexOf(region.oid) === -1) {
        return region;
      }
    });
    if (!this.availableRegions || this.availableRegions.length < 1) {
      const response = SwalDialog.show({
        title: 'No Available Regions',
        text: 'There are no available regions to add to this dairy.',
        icon: 'warning',
        dangerMode: true,
      });
      return;
    } else {
      const newRegion: RegionDTO = new RegionDTO();
      newRegion.oid = -1;
      this.activeDairy.regions.push(newRegion);
    }
  }

  selectRegion(index, valuePairOid): void {
    let newRegion: RegionDTO = new RegionDTO();
    const foundValuePair = this.regions.find(r => +r.oid === +valuePairOid);
    newRegion.oid = foundValuePair.oid;
    newRegion.name = foundValuePair.name;

    this.activeDairy.regions[index] = newRegion;
  }

  removeRegion(index) {
    this.activeDairy.regions.splice(index, 1);
  }

  addNutritionistLineItem() {
    const oidList = this.activeDairy.nutritionists.map(n => n.oid);
    this.availableNutritionists = this.nutritionists.filter(nutritionist => {
      if (oidList.indexOf(nutritionist.oid) === -1) {
        return nutritionist;
      }
    });
    if (!this.availableNutritionists || this.availableNutritionists.length < 1) {
      const response = SwalDialog.show({
        title: 'No Available Nutritionists',
        text: 'There are no available nutritionists to add to this dairy.',
        icon: 'warning',
        dangerMode: true,
      });
      return;
    } else {
      const newNutritionist: NutritionistDTO = <NutritionistDTO>{};
      newNutritionist.oid = -1;
      this.activeDairy.nutritionists.push(newNutritionist);
    }
  }

  removeNutritionist(index) {
    this.activeDairy.nutritionists.splice(index, 1);
  }

  selectNutritionist(index, oid) {
    let newNutritionist: NutritionistDTO = <NutritionistDTO>{};
    this.nutritionists.find(n => {
      if (+n.oid === +oid) {
        newNutritionist = n;
      }
    });
    if (this.activeDairy.nutritionists.length === 1) {
      newNutritionist.percentageAllocated = 100;
    }
    this.activeDairy.nutritionists[index] = newNutritionist;
  }

  mergeDairy() {
    // TODO: what does merge do?
    // this.pdsService.mergeDairy(this.activeDairy.oid).toPromise();
    // this.getAllDairies();
  }

  async activateDairy() {
    await this.pdsService.activateDairy(this.activeDairy.oid).toPromise();
     await this.getAllDairies();
    this.showPreferenceChange(this.showPreference);
  }

  async rejectDairy() {
    await this.pdsService.rejectDairy(this.activeDairy.oid).toPromise();
    await this.getAllDairies();
    this.showPreferenceChange(this.showPreference);
  }

  setPercentage(index, percentage) {
    this.activeDairy.nutritionists[index].percentageAllocated = percentage;
    this.percentInEdit = false;
  }

  getMonthTotal(obj) {
    const total = (obj.numberOfCows * obj.poundsPerDay * 30.4);
    return total;
  }

  navToNutritionist(nutritionist) {
    // TODO: Should they go to the nutritionist worksheet or the users page?
    this.router.navigate([`/users/${UrlObfuscationService.obfuscate(nutritionist.oid)}`]);
  }

  navToRegion(region) {
    this.router.navigate([`/regions/${UrlObfuscationService.obfuscate(region.oid)}`]);
  }

  navToFeed(feed) {
    this.router.navigate([`/feeds/${UrlObfuscationService.obfuscate(feed.oid)}`]);
  }

  navToMill(mill) {
    this.router.navigate([`/mills/${UrlObfuscationService.obfuscate(mill.oid)}`]);
  }

  isAdminView() {
    if (this.loggedInEntity && this.loggedInEntity.securityGroupName && (this.loggedInEntity.securityGroupName === CONSTANTS.SECURITY_GROUPING.SUPERUSER) || (this.loggedInEntity.securityGroupName === CONSTANTS.SECURITY_GROUPING.ADMIN)) { // tslint:disable-line
      return true;
    }
    return false;
  }

  viewOnlyAccess() {
    if(this.loggedInEntity.securityGroupName === this.constNutritionist) {
      return true;
    } else {
      return false;
    }
  }

  canEditNutritionist() {
    // console.log(this.isNewDairy, 'is this a new dairy?');
    if(this.loggedInEntity.securityGroupName === this.constSuper || this.loggedInEntity.securityGroupName === this.constAdmin || (this.loggedInEntity.securityGroupName === this.constNutritionist && this.isNewDairy)) {
      return true;
    } else {
      return false;
    }
  }

  hasAdminAccess() {
    if(this.loggedInEntity.securityGroupName === this.constSuper || this.loggedInEntity.securityGroupName === this.constAdmin) {
      return true;
    } else {
      return false;
    }
  }

  editPercent() {
    if(this.canEditNutritionist()) {
      this.percentInEdit = !this.percentInEdit;
    }
  }

  navToFeeds() {
    this.router.navigate([`/feeds`]);
  }

  navToMills() {
    this.router.navigate(['/mapping'], { queryParams: { mappingTab: "Mill Mapping"} })
  }

}

import { Component, OnInit } from '@angular/core';
import {
  AnalyticsService, Entity, MonthCloseState,
  RptActiveDairyCowsAndMonthlyProfitTotals
} from "../../api/generated-api";
import {CONSTANTS} from "../../lib/constants";
import {UserService} from "../../services/user.service";
import {AppUtilities} from "../../lib/appUtilities.service";


@Component({
  selector: 'cow-count',
  templateUrl: './cow-count.component.html',
  styleUrls: ['./cow-count.component.css']
})
export class CowCountComponent implements OnInit {

  public currentMonth: number;
  public currentMonthString: string = '';
  public currentYear: number;
  public years: number[] = CONSTANTS.YEARS;
  public monthList: Array<{ nMonth: number, sMonth: string }> = CONSTANTS.MONTHS_SELECT_LIST;
  public dairies: RptActiveDairyCowsAndMonthlyProfitTotals[] = [];
  public constAdmin: string = CONSTANTS.SECURITY_GROUPING.ADMIN;
  public constMill: string = CONSTANTS.SECURITY_GROUPING.MILL;
  public constSuper: string = CONSTANTS.SECURITY_GROUPING.SUPERUSER;
  public loggedInEntity: Entity = <Entity>{};
  public monthCloseState: MonthCloseState = <MonthCloseState>{};


  constructor(private analytics: AnalyticsService, private userService: UserService) {
  }

  async ngOnInit(): Promise<void> {
    this.loggedInEntity = await this.userService.getLoggedInEntityUser();
    this.monthCloseState = await this.userService.getMonthCloseState();
    this.currentDateSet();
    this.getCowCount();
  }

  currentDateSet() {
    this.currentYear = this.monthCloseState.currentYear;
    this.currentMonth = this.monthCloseState.currentMonth;
    this.currentMonthString = CONSTANTS.MONTHS[this.currentMonth];
  }

  async getCowCount() {
    const response = await this.analytics.getActiveDairyTotalCowsAndProfitByMonthAndYear(this.currentMonth,this.currentYear).toPromise();
    this.dairies = response.dairies;
  }

  public getTotalQty(columnName: string): number {
    return AppUtilities.addByColumn(this.dairies, columnName);
  }

  selectDate() {
    this.currentMonthString = CONSTANTS.MONTHS[this.currentMonth];
    this.getCowCount();
  }


}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {PDSService} from '../../api/generated-api';

@Component({
  selector: 'change-password-modal',
  templateUrl: './change-password-modal.component.html'
})
export class ChangepasswordModal implements OnInit, OnDestroy {

  public password: string;
  public confirmPassword: string;
  public isSubmitted: boolean = false;

  constructor(public activeModal: NgbActiveModal, private pdsService: PDSService) { }

  async ngOnInit(): Promise<void> {
  }

  ngOnDestroy(): void {
    this.clearProperties();
  }

  async save(): Promise<void> {
    this.isSubmitted = true;
    if(this.IsValid()){
      await this.pdsService.resetUserPassword(this.password).toPromise();
      this.activeModal.dismiss(true);
    }
  }

  public IsValid(): boolean {
    if(this.password !== null && this.password !== ""
    && this.confirmPassword !== null && this.confirmPassword !== ""
    && this.confirmPassword === this.password){
      return true;
    } else {
      return false;
    }
  }

  clearProperties() {
    this.password = null;
    this.confirmPassword = null;
  }

  cancel(): void {
    this.clearProperties();
    this.activeModal.dismiss(false);
  }

}

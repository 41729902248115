import { Component, OnInit } from '@angular/core';
import {AnalyticsService, PDSService, RptCowCountByYear} from "../../api/generated-api";
import {UserService} from "../../services/user.service";


@Component({
  selector: 'cow-count-by-month',
  templateUrl: './cow-count-by-month.component.html',
  styleUrls: ['./cow-count-by-month.component.css']
})
export class CowCountByMonthComponent implements OnInit {

  public data: Array<RptCowCountByYear> = [];



  constructor(private analytics: AnalyticsService, private userService: UserService, private pdsService: PDSService) {
  }

  async ngOnInit(): Promise<void> {
    await this.getCowCountData();

  }

  async getCowCountData() {
    this.data = await this.pdsService.getRptCowCountByYear().toPromise();
    // console.log(this.data, 'data');
  }


}

import { Component, OnInit } from '@angular/core';
import {
  PDSService, ProductionSnapShot, MonthCloseState, MonthClose, MonthEndService} from '../../api/generated-api';
import { AppUtilities } from 'src/app/lib/appUtilities.service';
import { CONSTANTS } from 'src/app/lib/constants';
import {SwalDialog} from '../../lib/swal-dialog';
import {Router} from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import {UrlObfuscationService} from "../../lib/obfuscate.service";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmSealModalComponent} from "../../components/confirm-seal-modal/confirm-seal-modal.component";

export interface MonthCloseExtended extends MonthClose {
  isActiveMonth: boolean;
}

@Component({
  selector: 'month-end',
  templateUrl: './month-end.component.html',
  styleUrls: ['./month-end.component.css']
})
export class MonthEndComponent implements OnInit {

  public lowestYearPossible: number = CONSTANTS.LOWEST_YEAR;
  public highestYearPossible: number = CONSTANTS.HIGHEST_YEAR;
  public isLoaded: boolean = false;
  public currentYear: number;
  public currentMonth: number;
  public currentMonthString: string = '';
  public activeYear: number;
  public editInProgress: boolean = false;
  public viewMonthsList: MonthCloseExtended[] = [];
  public activeMonth: MonthCloseExtended = <MonthCloseExtended>{};
  public monthProdData: ProductionSnapShot = <ProductionSnapShot>{
    mills: [],
    dairies: [],
    nutritionists: [],
  };
  public monthEndData: any[] = [];
  public monthCloseState: MonthCloseState;
  public saving: boolean = false;
  public  isSealing: boolean = false;

  constructor( private pds: PDSService,
    private monthEndService: MonthEndService,
    private router: Router,
    private userService: UserService,
               private modalService: NgbModal) {

  }

  async ngOnInit(): Promise<void> {
    await this.initiate();
    this.isLoaded = true;
  }

  async initiate() {
    await this.getMonthCloseState();
    this.editInProgress = this.monthCloseState.isCurrentCloseInProgress;
    this.activeYear = this.monthCloseState.currentYear;
    await this.getClosedDates();
    await this.getMonthProdData();
  }

  async getMonthCloseState() {
    this.monthCloseState = await this.monthEndService.getMonthCloseState().toPromise();
  }

  async getClosedDates() {
      this.currentYear = this.monthCloseState.currentYear;
      this.currentMonth = this.monthCloseState.currentMonth;
    const response = await this.monthEndService.getMonthCloseByYear(this.activeYear).toPromise();
    if(response && response.length > 0){
      this.currentMonthString = response.find(month => month.month === this.currentMonth).name;
      this.viewMonthsList = response.map(month => {
        const newExt: MonthCloseExtended = <MonthCloseExtended>{...month};
        newExt.isActiveMonth = false;
        return newExt;
      });
    }
    this.viewMonthsList.filter(month => {
      if (month.month === this.currentMonth) {
        this.activeMonth = month;
      }
    });
    if (this.viewMonthsList && this.viewMonthsList.length > 0 && !this.viewMonthsList[0].dateClosed) {
      this.activeMonth = this.viewMonthsList[0];
    }
  }

  async getMonthProdData(): Promise<any> {
      const result = await this.pds.getMonthlyNutritionistProductionTotalsByMonthAndYear(this.activeMonth.month,  this.activeYear).toPromise();
      this.monthEndData = result.data;
  }

  selectNewYear(context) {
    if (context === 'previous') {
      const newYear = this.activeYear - 1;
        this.activeYear = newYear;
    } else {
      const newYear = this.activeYear + 1;
        this.activeYear = newYear;
    }
    this.getClosedDates();
    this.getMonthProdData();
    // console.log(this.activeYear, this.currentYear, 'active and current')
  }

  selectMonth(month) {
    this.activeMonth = month;
    this.getMonthProdData();
  }

  getTotalByColumn(columnName: string) {
    return AppUtilities.addByColumn(this.monthEndData, columnName);
  }

  async collectCurrentMonthData() {
    this.saving = true;
    const res = await this.monthEndService.runMonthEnd().toPromise();
    const response = await SwalDialog.show({
      title: 'Month End Completed',
      text: 'Month end processing complete. To view results, advance to the Nutritionist Worksheet.',
      icon: 'success',
      dangerMode: false,
      buttons: {
        test1: { text: 'Close', value: 'close'},
        test2: { text: 'Advance To Nutritionist Worksheet', value: 'advance' },
      }
    });
    this.saving = false;
    if (response && response !== 'close') {
      this.router.navigate(['/nutritionist-worksheet']);
    }
  }

  async sealMonthEnd() {
    this.isSealing = true;
    const response = await this.monthEndService.sealMonthEnd().toPromise();
    const res = await this.monthEndService.getMonthCloseState().toPromise();
    this.userService.setMonthCloseState(res);
    this.isSealing = false;
    this.initiate();
  }

  async confirmSeal() {
    const confirmSealModal: NgbModalRef = this.modalService.open(ConfirmSealModalComponent, { backdrop: 'static' });
    confirmSealModal.result.then((result) => { }, (reason) => {
      // console.log(reason, 'reason');
      if (reason && reason === true) {
        this.sealMonthEnd();
      }
    });

  }

  isCurrentMonthYear(): boolean {
    if (this.activeMonth.month === this.currentMonth && this.activeYear === this.currentYear) {
      return true;
    } else {
      return false;
    }
  }

  canViewButtons(month): boolean {
    if (this.viewMonthsList && this.activeMonth && this.activeMonth.month  === month.month && this.currentMonth === month.month) {
      return true;
    } else {
      return false;
    }
  }

  viewDeliveryDetails(oid) {
    this.router.navigate(['nutritionist-delivery-detail' + '/' + UrlObfuscationService.obfuscate(oid)], { queryParams: { month: this.activeMonth.month, year: this.activeYear} });
  }

  async ToggleLockMonth(){
    await this.monthEndService.lockCurrentMonth().toPromise();
    this.getMonthCloseState();
  }

}

import { Component, OnInit } from '@angular/core';
import {CONSTANTS} from "../../lib/constants";
import {AnalyticsService, MonthEndService, MonthlyNutritionistProduction, PDSService, ReportsService, RptMonthlyNutritionistProduction} from "../../api/generated-api";
import {AppUtilities} from "../../lib/appUtilities.service";

@Component({
  selector: 'ytd-mtd',
  templateUrl: './ytd-mtd.component.html',
  styleUrls: ['./ytd-mtd.component.css']
})
export class YtdMtdComponent implements OnInit {

  public months: Array<{ nMonth: number, sMonth: string }> = CONSTANTS.MONTHS_SELECT_LIST;
  public years: Array<number> = CONSTANTS.YEARS;
  public selectedMonth: number;
  public selectedYear: number;
  public monthString: string = '';
  public masterInfo: MonthlyNutritionistProduction[] = [];
  public appUtilities: AppUtilities = new AppUtilities();

  constructor(private monthEnd: MonthEndService, private reports: ReportsService) { }

  ngOnInit(): void {
    this.initiate();
  }

  async initiate(): Promise<void> {
    await this.setClosedState();
    this.getMasterInfo()
  }

  async setClosedState() {
    const monthCloseState = await this.monthEnd.getMonthCloseState().toPromise();
    this.selectedMonth = monthCloseState.currentMonth;
    this.selectedYear = monthCloseState.currentYear;
    this.setMonthString();
  }

  async getMasterInfo() {
    const res = await this.reports.getMonthlyNutritionistProductionMtdYtd(this.selectedMonth, this.selectedYear).toPromise();
    if(res.hasErrors) {
      this.masterInfo = [];
      await this.appUtilities.showServerErrorSwal(res.errorMessage);
    } else {
      this.masterInfo = res.data.production;
    }
  }

  setMonthString() {
    this.monthString = this.months.find(month => +month.nMonth === +this.selectedMonth).sMonth;
  }

  selectDate() {
    this.setMonthString();
    this.getMasterInfo();
  }

  getTotalByColumn(columnName: string) {
    return AppUtilities.addByColumn(this.masterInfo, columnName);
  }

}

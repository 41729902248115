import { Component, OnInit } from '@angular/core';
import {
  Entity,
  MonthCloseState, MonthEndService, NutritionistDTO, NutritionistProductionGrid, PDSService,
  RptMonthlyNutritionistProductionWithCartesiansRemoved, SubTotalRow
} from '../../api/generated-api';
import {CONSTANTS} from '../../lib/constants';
import {AppUtilities} from '../../lib/appUtilities.service';
import {UserService} from "../../services/user.service";

@Component({
  selector: 'nutritionist-production-summary',
  templateUrl: './nutritionist-production-summary.component.html',
  styleUrls: ['./nutritionist-production-summary.component.css']
})
export class NutritionistProductionSummaryComponent implements OnInit {

  public nutritionistData: NutritionistProductionGrid[] = [];
  public masterData: RptMonthlyNutritionistProductionWithCartesiansRemoved[] = [];
  public monthCloseState: MonthCloseState = <MonthCloseState>{};
  public months: Array<{ nMonth: number, sMonth: string }> = CONSTANTS.MONTHS_SELECT_LIST;
  public years: Array<number> = CONSTANTS.YEARS;
  public selectedMonth: number = new Date().getMonth() + 1;
  public selectedYear: number = new Date().getFullYear();
  public monthString: string = '';
  public nutritionists: Array<NutritionistDTO> = [];
  public loggedInEntity: Entity = <Entity>{};
  public selectedNutritionist: NutritionistDTO = <NutritionistDTO>{};
  public pageLoading: boolean = false;
  public constSuper: string = CONSTANTS.SECURITY_GROUPING.SUPERUSER;
  public constAdmin: string = CONSTANTS.SECURITY_GROUPING.ADMIN;
  public constNutritionist: string = CONSTANTS.SECURITY_GROUPING.NUTRITIONIST;
  public footerTotals: SubTotalRow = <SubTotalRow>{
    grossProfit: null,
    tons: null,
    retainer: null,
    numberOfCows: null,
    nutritionistNumberOfCows: null,
    nutritionistRetainer: null,
    nutritionistTons: null,
    nutritionistFeedProfit: null,
    nutritionistEarnings: null
  };

  constructor(private monthEnd: MonthEndService, private pdsService: PDSService, private userService: UserService) { }

  ngOnInit(): void {
    this.pageLoading = true;
    this.initiate();
  }

  async initiate(): Promise<void> {
    await this.setClosedState();
    await this.getLoggedInUser();
    await this.getNutritionists();
    this.getProductionData();
    if (this.nutritionists[0] && this.loggedInEntity.securityGroupName === this.constSuper) {
      this.selectedNutritionist = this.nutritionists[0];
    } else if (this.nutritionists[0] && this.loggedInEntity.securityGroupName === this.constNutritionist) {
      this.selectedNutritionist = this.nutritionists.find(n => n.oid === this.loggedInEntity.oid);
    }
    this.pageLoading = false;
  }

  async getLoggedInUser(): Promise<void> {
    this.loggedInEntity = await this.userService.getLoggedInEntityUser();
  }

  async getNutritionists(): Promise<void> {
    this.nutritionists = await this.pdsService.getAllNutritionistDTOs().toPromise();
  }

  async setClosedState() {
    this.monthCloseState = await this.monthEnd.getMonthCloseState().toPromise();
    this.selectedMonth = this.monthCloseState.currentMonth;
    this.selectedYear = this.monthCloseState.currentYear;
    this.setMonthString();
  }

  async getProductionData(oid?: number) {
    const response = await this.monthEnd.getNutritionistProductionForTheMonthByLoggedInUserByMonthAndYearWithCartesiansRemoved(104,11, 2019).toPromise();
    this.masterData = response.data;
    this.footerTotals = response.totals;
    // console.log(this.masterData, 'master');
    // console.log(this.footerTotals, 'totals');
  }

  getTotalByColumn(columnName: string) {
    return AppUtilities.addByColumn(this.masterData, columnName);
  }

  selectDate() {
    this.setMonthString();
    this.getProductionData();
  }

  setMonthString() {
    this.monthString = this.months.find(month => +month.nMonth === +this.selectedMonth).sMonth;
  }

  isNutritionistAndCannotViewCurrent(): boolean {
    if (this.loggedInEntity
      && this.loggedInEntity.securityGroupName === this.constNutritionist
      && +this.selectedMonth === +this.monthCloseState.currentMonth
      && +this.selectedYear === +this.monthCloseState.currentYear
      && this.monthCloseState.isCurrentCloseInProgress) {
      return true;
    } else {
      return false;
    }
  }

  selectNutritionist(oid: number) {
    const foundNutritionist = this.nutritionists.find(f => f.oid === oid);
    this.selectedNutritionist = foundNutritionist;
    this.getProductionData(foundNutritionist.oid);
  }

}

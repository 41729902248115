import {Component, OnInit, ViewChild} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddFeedProductMixModalComponent } from '../../components/add-feed-product-mix-modal/add-feed-product-mix-modal.component';
import { AppUtilities } from 'src/app/lib/appUtilities.service';
import { CONSTANTS } from 'src/app/lib/constants';
import {FeedDTO, Product, PDSService, Entity, ValuePair, Feed} from '../../api/generated-api';
import {UserService} from '../../services/user.service';
import {SwalDialog} from '../../lib/swal-dialog';
import {UrlObfuscationService} from '../../lib/obfuscate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscribable, Subscription} from 'rxjs/index';
import {ToastrService} from 'ngx-toastr';
import {CloneFeedModalComponent} from "../../components/clone-feed-modal/clone-feed-modal.component";
import {SideBarListComponent} from "../../components/side-bar-list/side-bar-list.component";

@Component({
  selector: 'feeds',
  templateUrl: './feeds.component.html',
  styleUrls: ['./feeds.component.scss']
})
export class FeedsComponent implements OnInit {

  @ViewChild("sideBarComponent", null) sideBarComponent: SideBarListComponent;

  feeds: FeedDTO[] = [];
  public securityGroup: string = '';
  public masterFeedsList: Feed[] = [];
  public showPreference: string = 'Active';
  public assignPreference: string = 'dairy';
  public activeFeed: FeedDTO = <FeedDTO>{};
  public originalCheckSumFeed: string = '';
  public loggedInEntity: Entity = <Entity>{};
  public loggedInIsMill: boolean = false;
  public userSubscription: Subscription;
  public constAdmin: string = CONSTANTS.SECURITY_GROUPING.ADMIN;
  public constSuper: string = CONSTANTS.SECURITY_GROUPING.SUPERUSER;
  public feedsList: Feed[] = [];
  public hasNoFeed: boolean = false;
  public isSubmitted: boolean = false;
  public selectedDairyOid: number;
  public noFeed: boolean = false;
  public universalOid: number = 706;
  public currentOid: number;
  public dairies: Array<ValuePair> = [];
  public navTitles: Array<{ name: string, value: string }> = [
    { name: CONSTANTS.UNIVERSAL_INDIVIDUAL_FEEDS.INDIVIDUAL, value: 'individual' },
    { name: CONSTANTS.UNIVERSAL_INDIVIDUAL_FEEDS.UNIVERSAL, value: 'universal' },
  ];
  public activeTab: string = 'individual';
  public pageLoading: boolean = false;


  constructor(private modalService: NgbModal,
    private pdsService: PDSService,
    private appUtilities: AppUtilities,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toast: ToastrService) { }

  async ngOnInit(): Promise<void> {
    this.pageLoading = true;
    await this.init();
    this.getParamSubscription();
  }

  async init() {
    await this.getLoggedInUser();
    await this.getFeedsList();
    this.feedsList = this.feedsList.filter(feed => {
      return feed.isActive;
    });
    await this.getDairiesList();
    // this.setActiveFeed(this.feedsList[0].oid);
    this.pageLoading = false;
  }

  async getDairiesList() {
    this.dairies = await this.pdsService.getDairyListMapped2LoggedInUser(true).toPromise();
  }

  async getFeedsList() {
    try {
      this.feedsList = await this.pdsService.getAllFeeds().toPromise();
      this.masterFeedsList = JSON.parse(JSON.stringify(this.feedsList));
    } catch (error) {
      await this.appUtilities.showServerErrorSwal(error);
    }
  }


  // async getUniversalFeedsList() {
  //   try {
  //     this.feedsList = await this.pdsService.getFeedListMapped2UniversalDairy().toPromise();
  //     this.masterFeedsList = JSON.parse(JSON.stringify(this.feedsList));
  //   } catch (error) {
  //     await this.appUtilities.showServerErrorSwal(error);
  //   }
  // }

  async setActiveFeed(oid: number, newFeed?): Promise<void> {
    this.noFeed = false;
    if (oid !== -1) {
      this.activeFeed = await this.pdsService.getFeedDTOByOid(oid).toPromise();
    } else {
      this.activeFeed = newFeed;
    }
    this.currentOid = this.activeFeed.entityOid_Dairy;
    this.originalCheckSumFeed = this.appUtilities.createCheckSum(this.activeFeed);
    if(this.activeFeed.entityOid_Dairy === this.universalOid) {
      this.assignPreference = 'universal';
    } else {
      this.assignPreference = 'dairy'
    }
  }

  async getLoggedInUser(): Promise<void> {
    this.loggedInEntity = await this.userService.getLoggedInEntityUser();
    this.securityGroup = this.loggedInEntity.securityGroupName;
    // this.canAddNew();
  }

  async getParamSubscription(): Promise<void> {
    this.userSubscription = this.activatedRoute.params.subscribe(async (param: {id: string}) => {
      if (param.id) {
        const foundActiveFeed = this.feedsList.find(feed => feed.oid === +UrlObfuscationService.deobfuscate(param.id));
        this.activeFeed = await this.pdsService.getFeedDTOByOid(foundActiveFeed.oid).toPromise();
        this.originalCheckSumFeed = this.appUtilities.createCheckSum(this.activeFeed);
        this.currentOid = this.activeFeed.entityOid_Dairy
        if(this.activeFeed.entityOid_Dairy === this.universalOid) {
          this.assignPreference = 'universal';
        } else {
          this.assignPreference = 'dairy'
        }
      } else {
        if(this.feedsList.length > 0) {
          this.currentOid = this.feedsList[0].entityOid_Dairy;
          await this.router.navigate([`/feeds/${UrlObfuscationService.obfuscate(this.feedsList[0].oid)}`]);
        }
      }
    });
  }

  async openAddEditModal(product?: Product, index?: number): Promise<void> {
    // TODO: replace endpoint with get all products by mill oid. This one gets a singular product by the oid
     if (this.loggedInIsMill) {
       const millProducts = await this.pdsService.getProductsByEntityOidThroughProduct2EntityMap(this.loggedInEntity.entityOid_Master).toPromise(); // tslint:disable-line
       if (!millProducts.length) {
         SwalDialog.show({
           title: 'No Available Products',
           text: 'No products have been assigned for your mill. Contact Progressive Dairy Solutions to complete your account setup.',
           icon: 'warning',
           dangerMode: true,
         });
       } else {
         const feedModal: NgbModalRef = this.modalService.open(AddFeedProductMixModalComponent, {backdrop: 'static'});
         feedModal.componentInstance.loggedInIsMill = this.loggedInIsMill;
         feedModal.componentInstance.loggedInOid = this.loggedInEntity.entityOid_Master;
         if (product) {
           feedModal.componentInstance.product = product;
           feedModal.componentInstance.lbsPerTon = product.lbsPerTon;
           feedModal.componentInstance.isEdit = true;
         }
         feedModal.result.then((result) => { }, (reason) => {
           if (reason && reason.oid) {
             console.log(reason, 'reason on close')
             this.processModalResults(reason, index);
           }
         });
       }
     } else {
       const feedModal: NgbModalRef = this.modalService.open(AddFeedProductMixModalComponent, {backdrop: 'static'});
       feedModal.componentInstance.loggedInIsMill = this.loggedInIsMill;
       feedModal.componentInstance.loggedInOid = this.loggedInEntity.oid;
       if (product) {
         feedModal.componentInstance.product = product;
         feedModal.componentInstance.lbsPerTon = product.lbsPerTon;
         feedModal.componentInstance.isEdit = true;
       }
       feedModal.result.then((result) => { }, (reason) => {
         if (reason && reason.oid) {
           console.log(reason, 'reason on close 2');
           this.processModalResults(reason, index);
         }
       });
     }
  }

  async changeActiveFeed(oid: number): Promise<void> {
   this.router.navigate([`/feeds/${UrlObfuscationService.obfuscate(oid)}`]);
  }

  async addFeed() {
    if (this.activeFeed.oid === -1) {
      return SwalDialog.show({
        title: 'Cannot Add New',
        text: 'There is already a new Feed creation in progress. Please cancel or save before adding another.',
        icon: 'warning',
        dangerMode: true,
      });
    } else {
      const newFeedDTO: FeedDTO = <FeedDTO>{};
      if(this.activeTab === 'individual') {
        this.assignPreference = 'dairy'
      } else {
        this.assignPreference = 'universal';
        newFeedDTO.entityOid_Dairy = this.universalOid;
      }
      newFeedDTO.name = '';
      newFeedDTO.isActive = true;
      newFeedDTO.products = [];
      newFeedDTO.oid = -1;

      const newFeed: Feed = <Feed>{};
      newFeed.isActive = false;
      newFeed.oid = -1;
      newFeed.name = '';

      // const newFeedValuePair: ValuePair = <ValuePair>{};
      // newFeedValuePair.isChecked = false;
      // newFeedValuePair.isActive = false;
      // newFeedValuePair.oid = -1;
      // newFeedValuePair.name = '';
      this.feedsList.push(newFeed);
      this.setActiveFeed(-1, newFeedDTO);
    }
  }

  processModalResults(modalResults: Product, index?: number) {
    console.log(Product, 'modal results product', index, 'index');
    if (index || index === 0) {
      this.activeFeed.products[index] = modalResults;
    } else {
      this.activeFeed.products.push(modalResults);
    }
  }

  removeProduct(index: number) {
    this.activeFeed.products.splice(index, 1);
  }

  filterFeedsList() {
    this.feedsList = this.masterFeedsList.filter(feed => {
      return feed.entityOid_Dairy === this.universalOid;
    })
  }

  async save() {
    if(this.activeFeed.entityOid_Dairy === -1) {
      this.activeFeed.entityOid_Dairy = null;
    }
    // console.log(this.activeFeed, 'active feed');
    try {
      if (this.activeFeed.oid === -1) {
        this.activeFeed.oid = null;
      }
      const isInvalidString: string = this.isInvalid();
      if (isInvalidString && isInvalidString !== '') {
        this.isSubmitted = true;
        const myhtml = document.createElement('div');
        myhtml.innerHTML = isInvalidString;
        SwalDialog.show({
          title: 'Validation Failed',
          content: myhtml,
          icon: 'error',
        });
        return;
      } else {
        if(this.assignPreference === 'universal') {
          this.activeFeed.entityOid_Dairy = this.universalOid;
          this.activeFeed.isUniversalFeed = true;
        }
        this.isSubmitted = true;
        const res = await this.pdsService.addUpdateFeedDTO(this.activeFeed).toPromise();
        this.toast.success('Successfully Saved');
        const savedOid: number = res.oid;
        await this.getFeedsList();
        if(this.activeTab === 'universal') {
          this.filterFeedsList()
        }
        this.showPreferenceChange(this.showPreference);
        const foundFeed = this.feedsList.find(f => f.oid === savedOid);
        if (foundFeed) {
          await this.setActiveFeed(foundFeed.oid);
        } else {
          await this.setActiveFeed(this.feedsList[0].oid);
        }
        this.originalCheckSumFeed = this.appUtilities.createCheckSum(this.activeFeed);
      }
    } catch (error) {
      this.appUtilities.showServerErrorSwal(error.message);
    }
  }

  isInvalid(): string {
    let rString: string = '';
    if (!this.activeFeed.name || this.activeFeed.name === '') {
      rString = rString.concat('Must have a Feed name to save </br>');
    }
    return rString;
  }

  async cancel() {
    const currentOid: number = this.activeFeed.oid;
    await this.getFeedsList();
    if(this.assignPreference === 'universal') {
      this.filterFeedsList();
    }
    const foundFeed: Feed = this.feedsList.find(f => f.oid === currentOid);
    if (foundFeed) {
      await this.setActiveFeed(foundFeed.oid);
    } else {
      await this.setActiveFeed(this.feedsList[0].oid);
    }
  }

  async canDeactivate(): Promise<boolean> {
    let bReturn = false;
    if (this.activeFeed.oid === -1 ||
      (this.activeFeed && this.originalCheckSumFeed !== '' && this.appUtilities.createCheckSum(this.activeFeed) !== this.originalCheckSumFeed) // tslint:disable-line
    ) {
      const res: string = await this.appUtilities.showUnsavedChangesSwal();
      if (res === CONSTANTS.SWAL_RESPONSE.YES) {
        await this.save();
        bReturn = true;
      } else if (res === CONSTANTS.SWAL_RESPONSE.NO) {
        this.feedsList = JSON.parse(JSON.stringify(this.masterFeedsList));
        bReturn = true;
      } else {
        bReturn = false;
      }
    } else {
      bReturn = true;
    }
    return bReturn;
  }

  assignPreferenceChange(event): void {
    // console.log(this.activeFeed, 'event');
    //this.currentOid = event;
    //this.activeFeed.entityOid_Dairy = event;
    this.assignPreference = event.target.value;
    if(event.target.value === "dairy" && this.activeFeed && this.activeFeed.entityOid_Dairy === this.universalOid){
      this.activeFeed.entityOid_Dairy = -1;
    }
  }

  showPreferenceChange(event): void {
    if (!this.masterFeedsList.length) {
      this.masterFeedsList = this.feedsList;
    }
    this.showPreference = event;
    if (this.showPreference === 'Active') {
      this.feedsList = this.masterFeedsList.filter(item => {
        return item.isActive;
      });
    } else if (this.showPreference === 'Inactive') {
      this.feedsList = this.masterFeedsList.filter(item => {
        return !item.isActive;
      });
    } else {
      this.feedsList = JSON.parse(JSON.stringify(this.masterFeedsList));
    }
    if (this.feedsList.length) {
      if (this.feedsList.find(r => r.oid === this.activeFeed.oid)) {
        this.setActiveFeed(this.activeFeed.oid);
      } else {
        this.setActiveFeed(this.feedsList[0].oid);
      }
      this.hasNoFeed = false;
    } else {
      this.hasNoFeed = true;
    }
  }

  // canAddNew(): boolean {
  //   if (this.loggedInEntity && this.loggedInEntity.securityGroupName && (this.loggedInEntity.securityGroupName === CONSTANTS.SECURITY_GROUPING.SUPERUSER) || (this.loggedInEntity.securityGroupName === CONSTANTS.SECURITY_GROUPING.ADMIN)) { // tslint:disable-line
  //     return true;
  //   }
  //   return false;
  // }

  navToMill(mill) {
    this.router.navigate([`/mills/${UrlObfuscationService.obfuscate(mill.oid)}`]);
  }

  navToNutritionist(nutritionist) {
    this.router.navigate([`/users/${UrlObfuscationService.obfuscate(nutritionist.oid)}`]);
  }

  async confirmNavigation(context) {
    const res = await SwalDialog.show({
      title: 'Edit Mapping',
      text: 'To edit the available ' + context + ' you will need to navigate to the mappings page. Are you sure you want to edit?',
      icon: 'warning',
      buttons: {
        test1: { text: CONSTANTS.SWAL_RESPONSE.YES, value: CONSTANTS.SWAL_RESPONSE.YES },
        test2: { text: CONSTANTS.SWAL_RESPONSE.NO, value: CONSTANTS.SWAL_RESPONSE.NO },
      }
    });
    if (res === CONSTANTS.SWAL_RESPONSE.YES) {
      this.router.navigate([`/mapping` + `/${UrlObfuscationService.obfuscate(this.activeFeed.oid)}`], { queryParams: { mappingTab: 'Feed Mapping'} })
    }
  }


  listOfNames(array): string {
    // console.log(array, 'response list')
    let rString: string = '';
    for(let r of array) {
      rString = r.name;
    };
    return rString;
  }



  async selectDairy() {
    const chosenDairy = this.dairies.find(d => +d.oid === +this.activeFeed.entityOid_Dairy).name;
    const response = await this.pdsService.isFeedInUseByAnotherDairy(this.activeFeed.oid, this.selectedDairyOid).toPromise();
    // console.log(response, 'response');
    if(response.length) {
      const stringOfNames: string = this.listOfNames(response);
      return SwalDialog.show({
        title: 'Feed Plan Currently In Use',
        text: 'This feed is currently in use by the following dairy(s) feed plan. \n' + stringOfNames +  ' \n Please clear feed plan(s) prior to assigning it to' +  chosenDairy,
        icon: 'warning',
        dangerMode: true,
      });
      this.activeFeed.entityOid_Dairy = this.currentOid;
    } else {
      this.currentOid = this.activeFeed.entityOid_Dairy;
    }
    //TODO: if this call comes back empty, then the feed plan is fair game. If it comes back with data, show swal and say "Feed is currently in use by the following dairy's feed plan. next line -- name of the dairy(s). Please clear feed plan(s) prior to assigning it to __dairy name trying to assign to. buttons OK. When clicked close modal, flip the dropdown back to whatever state it was before assigning. "
    // if(this.selectedDairyOid > 0) {
    //   this.activeFeed.entityOid_Dairy = this.selectedDairyOid;
    // }
  }


  async cloneFeed(): Promise<any> {
    const cloneModal = this.modalService.open(CloneFeedModalComponent, { backdrop: 'static' });
    const cloneFeed: FeedDTO = JSON.parse(JSON.stringify(this.activeFeed));
    if(this.assignPreference === "universal"){
      cloneFeed.entityOid_Dairy = this.universalOid
    }
    cloneModal.componentInstance.currentFeed = cloneFeed;
    cloneModal.result.then((result) => { }, (reason) => {
     this.getFeedsList();
    });
  }


  async setActiveTab(tabName) {
    // console.log(tabName, 'tab');
    const isValidToNavigate: boolean = await this.canDeactivate();
    if (isValidToNavigate) {
      this.pageLoading = true;

      switch (tabName) {

        case 'universal':
          this.activeTab = 'universal';
          this.filterFeedsList();
          this.feedsList = this.feedsList.filter(f => {return f.isActive});
          break;

        case 'individual':
          // console.log(this.masterFeedsList, 'master')
          this.activeTab = 'individual';
          await this.getFeedsList();
          break;

        default:
          break;
      }
      this.sideBarComponent.clearSearch();
      if(this.feedsList && this.feedsList.length) {
        await this.setActiveFeed(this.feedsList[0].oid);
      } else {
        this.activeFeed = <FeedDTO>{};
        this.originalCheckSumFeed = this.appUtilities.createCheckSum(this.activeFeed);
        this.currentOid = null;
        this.noFeed = true;
      }
      this.pageLoading = false;
    }
  }

}

import {Injectable} from '@angular/core';
import {CWGridService, GridPreference} from 'src/app/api/generated-api';

@Injectable()
export class GridPreferenceService {

    constructor(private gridService: CWGridService) {
    }

    public async loadSummaryList(grid: string): Promise<GridPreference[]> {
        return this.gridService.getGridPreferences(grid).toPromise();
    }

    public async load(oid: number): Promise<GridPreference> {
        return this.gridService.getGridPreferenceByOid(oid).toPromise();
    }

    public async loadDefault(grid: string): Promise<GridPreference> {
        return this.gridService.getDefaultByGridName(grid).toPromise();
    }

    public async save(record: GridPreference): Promise<GridPreference> {
        return this.gridService.addUpdateGridPreference(record).toPromise();
    }

    public async delete(oid: number): Promise<void> {
        return this.gridService.deleteGridPreference(oid).toPromise();
    }

}

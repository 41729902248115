import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {SwalDialog} from '../../lib/swal-dialog';
import {Feed, FeedDTO, FeedPlanDTO, PDSService, ValuePair} from '../../api/generated-api';

@Component({
  selector: 'add-feed-plan-modal',
  templateUrl: './add-feed-plan-modal.component.html',
  styleUrls: ['./add-feed-plan-modal.component.scss']
})
export class AddFeedPlanModalComponent implements OnInit {

  public feeds: Feed[] = [];
  public feedPlanDTO: FeedPlanDTO = <FeedPlanDTO>{};
  public feedOid: number = -1;
  public isSubmitted: boolean = false;
  public dairyOid: number;

  constructor(public activeModal: NgbActiveModal,
    private pdsService: PDSService,
    ) {
     }

  async ngOnInit(): Promise<void> {
    await this.getAllFeeds();
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

  save(): void {
    const isInvalidString: string = this.isInvalid();
    if (isInvalidString && isInvalidString !== '') {
      this.isSubmitted = true;
      const myhtml = document.createElement('div');
      myhtml.innerHTML = isInvalidString;
      SwalDialog.show({
        title: 'Validation Failed',
        content: myhtml,
        icon: 'error',
      });
      return;
    } else {
      const chosenFeed = this.feeds.find(f => f.oid === +this.feedOid);
      this.feedPlanDTO.feedDTO = <FeedDTO>{};
      this.feedPlanDTO.feedName = chosenFeed.name;
        const newFeedDTO: FeedPlanDTO = new FeedPlanDTO();
      newFeedDTO.numberOfCows = this.feedPlanDTO.numberOfCows;
      newFeedDTO.deleteMe = false;
      newFeedDTO.feedOid = this.feedOid;
      newFeedDTO.feedName = this.feedPlanDTO.feedName;
      newFeedDTO.poundsPerDay = this.feedPlanDTO.poundsPerDay;
      this.activeModal.dismiss(newFeedDTO);
    }

  }

  isInvalid(): string {
    let rString: string = '';
    if (!this.feedOid || +this.feedOid < +0) {
      rString = rString.concat('Must select a feed </br>');
    }
    return rString;
  }

  async getAllFeeds(): Promise<void> {
    this.feeds = await this.pdsService.getMappedFeedsByDairyOid(this.dairyOid).toPromise();
  }

}

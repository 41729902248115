import { Component, OnInit } from '@angular/core';
import { AppUtilities } from 'src/app/lib/appUtilities.service';
import { CONSTANTS } from 'src/app/lib/constants';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AddProductModalComponent} from '../../components/add-product-modal/add-product-modal.component';
import {UserService} from '../../services/user.service';
import {Entity, Lookup, Product, PDSService} from '../../api/generated-api';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  public products: Product[] = [];
  public loggedInEntity: Entity = <Entity>{};
  public showInactive: string = 'false';
  public constSuper: string = CONSTANTS.SECURITY_GROUPING.SUPERUSER;
  public constAdmin: string = CONSTANTS.SECURITY_GROUPING.ADMIN;

  constructor(private pdsService: PDSService,
    private appUtilities: AppUtilities,
    private toast: ToastrService,
    private userService: UserService,
    private modalService: NgbModal) {
  }

  async ngOnInit(): Promise<void> {
    await this.initiate();
  }

  async initiate() {
    this.loggedInEntity = await this.userService.getLoggedInEntityUser();
    await this.getProductsList();
  }

  async getProductsList(): Promise<void> {
    try {
      this.products = await this.pdsService.getAllProducts().toPromise();
    } catch (error) {
      await this.appUtilities.showServerErrorSwal(error);
    }
  }

  public togglePreference(activeString: string) {
    this.showInactive = activeString;
  }

  async save() {
    await this.getProductsList();
  }

  async addNewProduct(): Promise<void> {
    // TODO: add ngbOptions keyboard and backdrop to modals to remove static
    const feedModal: NgbModalRef = this.modalService.open(AddProductModalComponent, {backdrop: 'static'});
    feedModal.result.then((result) => { }, (reason) => {
      this.getProductsList();
    });
  }

  editProduct(obj) {
    const feedModal: NgbModalRef = this.modalService.open(AddProductModalComponent, {backdrop: 'static'});
    feedModal.componentInstance.product = obj;
    feedModal.result.then((result) => { }, (reason) => {
      this.getProductsList();
    });
  }

  async toggleActiveProperty(obj) {
    obj.isActive = !obj.isActive;
    await this.pdsService.addUpdateProduct(obj).toPromise();
    if (obj.isActive) {
      this.toast.success('Product activated');
    } else {
      this.toast.success('Product deactivated');
    }
    this.getProductsList();
  }

  async changeActivation(obj, event) {
    obj.isActive = !obj.isActive;
    await this.pdsService.addUpdateProduct(obj).toPromise();
    if (obj.isActive) {
      this.toast.success('Product activated');
    } else {
      this.toast.success('Product deactivated');
    }
    this.getProductsList();
  }


}

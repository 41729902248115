import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'monthly-production-modal',
  templateUrl: './add-monthly-production-modal.component.html',
  styleUrls: ['./add-monthly-production-modal.component.scss']
})
export class AddMonthlyProductionModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'filterByFlexString', pure: true})

export class FilterByFlexStringPipe implements PipeTransform {
  transform(items: any[], filterValue: Object, filterProperty: any): any {
      if (!items || !filterValue) {
          return items;
      }
        return items.filter(item => item[filterProperty] && item[filterProperty].toString().toLowerCase().includes(filterValue.toString().toLowerCase())); // tslint:disable-line
  }
}

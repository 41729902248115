import { Component, OnInit } from '@angular/core';
import {MonthCloseState, MonthEndService, NutritionistProductionGrid} from '../../api/generated-api';
import {CONSTANTS} from '../../lib/constants';
import {AppUtilities} from '../../lib/appUtilities.service';

@Component({
  selector: 'view-by-nutritionist',
  templateUrl: './view-by-nutritionist.component.html',
  styleUrls: ['./view-by-nutritionist.component.css']
})
export class ViewByNutritionistComponent implements OnInit {

  public nutritionistData: NutritionistProductionGrid[] = [];
  public monthCloseState: MonthCloseState = <MonthCloseState>{};
  public months: Array<{ nMonth: number, sMonth: string }> = CONSTANTS.MONTHS_SELECT_LIST;
  public years: Array<number> = CONSTANTS.YEARS;
  public selectedMonth: number = new Date().getMonth() + 1;
  public selectedYear: number = new Date().getFullYear();
  public monthString: string = '';
  public appUtilities: AppUtilities = new AppUtilities();

  constructor(private monthEnd: MonthEndService) { }

  ngOnInit(): void {
    this.initiate();
  }

  async initiate(): Promise<void> {
    await this.setClosedState();
    this.getProductionData();

  }

  async setClosedState() {
    this.monthCloseState = await this.monthEnd.getMonthCloseState().toPromise();
    this.selectedMonth = this.monthCloseState.currentMonth;
    this.selectedYear = this.monthCloseState.currentYear;
    this.setMonthString();
  }

  async getProductionData() {
    const res = await this.monthEnd.getNutritionistProductionGridByLoggedInUsersOidByMonthAndYear(this.selectedMonth, this.selectedYear).toPromise();
    if(res.hasErrors) {
      this.nutritionistData = [];
      await this.appUtilities.showServerErrorSwal(res.errorMessage);
    } else {
      this.nutritionistData = res.data.nutritionists;
    }
  }

  getTotalByColumn(columnName: string, data?) {
    return AppUtilities.addByColumn(data.deliveries, columnName);
  }

  selectDate() {
    this.setMonthString();
    this.getProductionData();
  }

  setMonthString() {
    this.monthString = this.months.find(month => +month.nMonth === +this.selectedMonth).sMonth;
  }
}

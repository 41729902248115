import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import {Lookup} from '../../api/generated-api';

@Component({
  selector: 'side-bar-list',
  templateUrl: './side-bar-list.component.html',
  styleUrls: ['./side-bar-list.component.css']
})
export class SideBarListComponent implements OnInit {

  @Input() list: any[] = [];
  @Input() title: string = '';
  @Input() context: string = '';
  @Input() displayColumnName: string = '';
  @Input() selectedOid: number;
  @Input() securityGroups: Lookup[];
  @Input() showUsersFilter: boolean;
  @Input() canAddNew: boolean;
  @Output() oidSelected: EventEmitter<number> = new EventEmitter;
  @Output() addSelected: EventEmitter<null> = new EventEmitter;

  public search: string;
  public showPreference: string = 'All';
  public masterList: any[] = [];
  public selectedGroupName: string = 'all';

  ngOnInit(): void {
  }

  itemSelected(item: any): void {
    // console.log(item, 'item')
    if (item && item.oid) {
      this.oidSelected.emit(item.oid);
    }
  }

  add(): void {
    this.addSelected.emit();
  }

  showPreferenceChange(event): void {
    if (!this.masterList.length) {
      this.masterList = this.list;
    }
    this.showPreference = event;
    if (this.showPreference === 'Active') {
      this.list = this.masterList.filter(item => {
        return item.isActive;
      });
    } else if (this.showPreference === 'Inactive') {
      this.list = this.masterList.filter(item => {
        return !item.isActive;
      });
    } else {
      this.list = this.masterList;
    }
    this.itemSelected(this.list[0]);
  }

  async filterGroup() {
    if (!this.masterList.length) {
      this.masterList = this.list;
    }
    if (this.selectedGroupName === 'all') {
      this.list = this.masterList;
    } else {
      this.list = this.masterList.filter(user => {
        return user.securityGroupName === this.selectedGroupName;
      });
    }
    this.itemSelected(this.list[0]);
  }

  clearSearch() {
   this.search = '';
   this.itemSelected(this.list[0]);

  }

}


import { Component } from '@angular/core';
import { CwGridComponent } from './cw-grid.component';
import { GridPreferenceService } from './grid-preference.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {GridPreference, CWGridService} from '../../api/generated-api';
import { SwalDialog } from 'src/app/lib/swal-dialog';
import {CONSTANTS} from "../../lib/constants";
import { ColumnNameWidthPair } from './grid-preference-json-data';

@Component({
    selector: 'cw-grid-preference',
    templateUrl: './cw-grid-preference.component.html',
    styleUrls: ['./cw-grid-preference.component.css']
})
export class CwGridPreferenceComponent {

    public formData: GridPreference = <GridPreference>{};
    public grid: CwGridComponent = <CwGridComponent>{};
    public preferences: GridPreference[] = [];
    public pageLoading: boolean = false;
    public addingNewView: boolean = false;

    public isSaving: boolean = false;

    constructor(private readonly service: GridPreferenceService, public activeModal: NgbActiveModal, public gridService: CWGridService) { }

    public close(pref?: GridPreference) {
        this.formData = <GridPreference>{};
        this.grid = <CwGridComponent>{};
        this.preferences = [];
        this.activeModal.dismiss(pref);
        this.pageLoading = false;

    }

    public async ngOnInit() {
      this.pageLoading = true;
        this.formData = <GridPreference>{};
        this.formData.isDefault = false;
        this.formData.viewName = '';
        this.preferences = [];
        this.preferences = await this.service.loadSummaryList(this.grid.definition);
        this.pageLoading = false;
    }
    
    public async saveNew() {
      if(this.formData.viewName && this.formData.viewName !== ''){
          this.isSaving = true;
          // TODO: create type for this structureu
          const jsonObj = {
              filterable: this.grid.filterable,
              groupable: this.grid.groupable,
              filter: this.grid.state.filter,
              group: this.grid.state.group,
              sort: this.grid.state.sort,
              columnOrder: this.grid.columns.map(c => {return c.name}),
              columnWidths: this.generateColumnWidthJson(),
              hiddenColumns: this.grid.hiddenColumns
          };
          const pref: GridPreference = <GridPreference>{};
          pref.entityOid = 0;
          pref.gridName = this.grid.definition;
          pref.viewName = this.formData.viewName;
          pref.isDefault = this.formData.isDefault ? true : false;
          pref.json = JSON.stringify(jsonObj);
          console.log(pref);
          const res = await this.service.save(pref);
          this.isSaving = false;
          this.close(res);
      } else {
          SwalDialog.show({
              title: 'Layout Name',
              text: 'Must have a layout name in order to save a new layout',
              icon: 'error',
            });
      }
  }

  private generateColumnWidthJson(): ColumnNameWidthPair[] {
    const NameValueList: ColumnNameWidthPair[] = [];
    const columns = this.grid.grid.columns;
    columns.forEach((column) => {
        NameValueList.push({Name: column.title, Width: column.width.toString()});
    })
    console.log(NameValueList);
    return NameValueList;
}

    public async load(gridPrefernceOid: number) {
        const pref = await this.service.load(gridPrefernceOid);
        this.close(pref);
    }

    public async delete(gridPrefernceOid: number) {

      if(this.preferences.length === 1){
        SwalDialog.show({
          title: 'Cannot Delete',
          text: 'There must be at least one record saved for a view.',
          icon: 'error',
        });
      } else if( this.preferences.length > 1) {
        const res: string = await SwalDialog.show({
          icon: 'warning',
          title: 'Confirm Delete',
          text: 'Are you sure you want to delete this view? ',
          buttons: {
            test1: { text: CONSTANTS.SWAL_RESPONSE.NO, value: CONSTANTS.SWAL_RESPONSE.NO},
            test2: { text: CONSTANTS.SWAL_RESPONSE.YES, value: CONSTANTS.SWAL_RESPONSE.YES },
          }
        });
        if (res === CONSTANTS.SWAL_RESPONSE.YES) {
          await this.service.delete(gridPrefernceOid);
          this.preferences = await this.service.loadSummaryList(this.grid.definition);
        } else {
          return;
        }
      }

    }

    public async  defaultPreferenceChanged(item){
        if(item.isDefault){
            for(const p of this.preferences){
                if(+p.oid !== +item.oid){
                    p.isDefault = false;
                }
            }
            await this.gridService.setGridDefaultRecord(item.oid, item.gridName, true).toPromise();
        } else {
            await this.gridService.setGridDefaultRecord(item.oid, item.gridName, false).toPromise();
        }
    }

    public cancel() {}

}

import {Component, Input, Output, EventEmitter, OnInit, HostListener} from '@angular/core';
import {CompositeFilterDescriptor, FilterDescriptor} from '@progress/kendo-data-query';
import {CwColumnComponent} from '../cw-column.component';
import { LookupService } from 'src/app/services/lookup.service';
import {Lookup} from '../../../api/generated-api';

export interface LookupCompositeFilterDescriptor extends CompositeFilterDescriptor {
  lookupName: string;
}

@Component({
  selector: 'multi-select-filter',
  templateUrl: './multi-select-filter.component.html',
  styleUrls: ['./multi-select-filter.component.css']
})
export class MultiSelectFilterComponent implements OnInit {
  @Input() public currentFilter: CompositeFilterDescriptor;

  @Input() public column: CwColumnComponent;

  @Output() private onLookupFilterChange: EventEmitter<LookupCompositeFilterDescriptor> = new EventEmitter(); // tslint:disable-line

  public lookups: Lookup[];

  public get selectedValue(): string {
    if (this.values.length === 1) {
      const lookup: Lookup = this._lookupService.findLookupByLookupOid(this.values[0]);
      if (lookup && lookup.value) {
        return lookup.value;
      }
    }
    return 'translations.grid.multiple';
  }

  public isOpen: boolean = false;

  public values: number[] = [];

  constructor(
    private _lookupService: LookupService
  ) { }

  ngOnInit(): void {
    if (this.currentFilter && this.currentFilter) {
      this.values = this.currentFilter.filters.map((f: FilterDescriptor) => f.value);
    }

    this.lookups = this._lookupService.findLookupByLookupName(this.column.lookup.name);
  }

  /**
   * The version of ngx-bootstrap doesn't support disabling insideClick
   * @param event
   */
  @HostListener('document:click', ['$event']) handleDocumentClick(event) {
    this.isOpen = false;
  }

  /**
   * The version of ngx-bootstrap doesn't support disabling insideClick
   * @param e
   */
  public stopIt(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  public isLookupSelected(lookup: Lookup) {
    return this.values.some(x => x === lookup.oid);
  }

  public selectLookup(lookup: Lookup, i: number): void {
    if (this.values.includes(lookup.oid)) {
      this.values = this.values.filter(x => x !== lookup.oid);
    } else {
      this.values.push(lookup.oid);
    }

    this.onLookupFilterChange.emit({
      filters: this.values.map(value => ({
        field: this.column.lookup.targetValue,
        operator: 'eq',
        value
      })),
      lookupName: this.column.lookup.name,
      logic: 'or'
    });
  }

}

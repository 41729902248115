import { Component, OnInit } from '@angular/core';
import {
  PDSService, FeedDeliveryDTO, FeedRecipeChangeDTO, ValuePair, FeedRecipe,
  FeedDeliveryRecipeDTO, FeedDeliveryIngredient
} from '../../api/generated-api';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppUtilities } from 'src/app/lib/appUtilities.service';

@Component({
  selector: 'feed-recipe-adjustment-modal',
  templateUrl: './feed-recipe-adjustment-modal.component.html',
  styleUrls: ['./feed-recipe-adjustment-modal.component.css']
})

// tslint:disable-next-line
export class FeedRecipeAdjustmentModal implements OnInit {

  public dto: FeedDeliveryDTO = <FeedDeliveryDTO>{};
  public millOid: number;
  public products: ValuePair[] = [];
  public isSaving: boolean = false;
  public feedDeliveryRecipe: FeedDeliveryIngredient[] = [];
  public editPreference: string = 'Once';
  public isMillUser: boolean = false;

  constructor(public activeModal: NgbActiveModal, private pdsService: PDSService, private appUtilities: AppUtilities, ) {
  }

  async ngOnInit(): Promise<void> {
    await this.initiate();
  }

  async initiate() {
    await this.getProductsList();
  }

  async getProductsList(): Promise<void> {
    try {
      const res = await this.pdsService.getFeedAndProductAndDairyMappingForMill(this.millOid).toPromise();
      if (res) {
        this.products = res.list2.filter(product => {
          return product.isChecked;
        })
      }
    } catch (error) {
      await this.appUtilities.showServerErrorSwal(error);
    }
  }

  clearProperties() {
    this.dto = <FeedDeliveryDTO>{};
    this.millOid = null;
    this.isSaving = false;
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

  addRecipe() {
    const newRecipe: FeedRecipe = <FeedRecipe>{};
    this.dto.productRecipe.push(newRecipe);
  }

  removeRecipe(index: number) {
    this.dto.productRecipe.splice(index, 1);
  }

  async save(): Promise<any> {
    for (let item of this.dto.productRecipe) {
      const newIngredient: FeedDeliveryIngredient = <FeedDeliveryIngredient> {
        oid: item.feedDeliveryIngredientOid,
        feedDeliveryOid: this.dto.oid,
        productOid: +item.productOid,
        productLbsPerTon: item.lbsPerTon
      };
      this.feedDeliveryRecipe.push(newIngredient);
    }
    const saveDTO: FeedDeliveryRecipeDTO = <FeedDeliveryRecipeDTO> {
      feedDeliveryOid: this.dto.oid,
      ingredients: this.feedDeliveryRecipe,
      changeScope: this.editPreference
    };
    const saveRes = await this.pdsService.updateFeedDeliveryRecipe(saveDTO).toPromise();
      this.activeModal.dismiss({submitted: true});
  }

  showPreferenceChange(event): void {
    this.editPreference = event;
    // console.log(this.editPreference)
  }

  canSeeButton() {
    if(this.isMillUser && this.dto.isUniversalFeed) {
      return false;
    } else {
      return true;
    }
  }



}

import { Component, OnInit} from '@angular/core';
import { AppUtilities } from '../../lib/appUtilities.service';
import { CONSTANTS } from 'src/app/lib/constants';
import { UserService } from 'src/app/services/user.service';
import {Entity, ProductionGridView, PDSService, MonthEndService, MonthCloseState} from '../../api/generated-api';
import { SwalDialog } from 'src/app/lib/swal-dialog';

@Component({
  selector: 'monthly-feed-production',
  templateUrl: './monthly-feed-production.component.html',
  styleUrls: ['./monthly-feed-production.component.scss']
})
export class MonthlyFeedProductionComponent implements OnInit {

  public loggedInEntity: Entity = <Entity>{};
  public monthCloseState: MonthCloseState = <MonthCloseState>{};
  public appUtilities: AppUtilities = new AppUtilities();
  public selectedMonth: number;
  public selectedYear: number;
  public constMill: string = CONSTANTS.SECURITY_GROUPING.MILL;
  public constNutritionist: string = CONSTANTS.SECURITY_GROUPING.NUTRITIONIST;
  public constSuper: string = CONSTANTS.SECURITY_GROUPING.SUPERUSER;
  public constAdmin: string = CONSTANTS.SECURITY_GROUPING.ADMIN;
  public months: Array<{ nMonth: number, sMonth: string }> = CONSTANTS.MONTHS_SELECT_LIST;
  public years: Array<number> = CONSTANTS.YEARS;
  public monthString: string = '';

  public monthList: Array<{ nMonth: number, sMonth: string }> = CONSTANTS.MONTHS_SELECT_LIST;
  public allMonths: Array<{ nMonth: number, sMonth: string }> = CONSTANTS.PRODUCTION_MONTHS_SELECT_LIST;

  public pageLoaded: boolean = false;
  public production: ProductionGridView[] = [];

  constructor(private pdsService: PDSService,
    private userService: UserService,
    ) {
  }

  async ngOnInit(): Promise<void> {
    this.loggedInEntity = await this.userService.getLoggedInEntityUser();
    this.monthCloseState = await this.userService.getMonthCloseState();
    this.selectedMonth = this.monthCloseState.currentMonth;
    this.selectedYear = this.monthCloseState.currentYear;
    this.setMonthString();
    await this.init();
    this.pageLoaded = true;
  }

  async init() {
    await this.getAllProductionGridViews();
  }

  getMonthDelivered(deliveryMonth: number): string {
    return CONSTANTS.MONTHS[deliveryMonth];
  }

  async getAllProductionGridViews() {
    const res = await this.pdsService.getAllProductionGridViews(this.selectedMonth, this.selectedYear).toPromise();
    if(res.hasErrors) {
      this.production = [];
      await this.appUtilities.showServerErrorSwal(res.errorMessage);
    } else {
      this.production = res.data;
    }
  }

  async selectDate(): Promise<void> {
    await this.setMonthString();
    this.getAllProductionGridViews();
  }

  setMonthString() {
    this.monthString = this.months.find(month => +month.nMonth === +this.selectedMonth).sMonth;
  }

  getTotalByColumn(columnName: string) {
    return AppUtilities.addByColumn(this.production, columnName);
  }

}

import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import {NavigationEnd, ParamMap, Router} from '@angular/router';
import { CONSTANTS } from 'src/app/lib/constants';
import {Entity, PDSService} from '../../api/generated-api';
import {UrlObfuscationService} from '../../lib/obfuscate.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public securityGroup: string = '';
  public constAdmin: string = CONSTANTS.SECURITY_GROUPING.ADMIN;
  public constSuper: string = CONSTANTS.SECURITY_GROUPING.SUPERUSER;
  public constNutritionist: string = CONSTANTS.SECURITY_GROUPING.NUTRITIONIST;
  public constMill: string = CONSTANTS.SECURITY_GROUPING.MILL;
  public loggedInEntity: Entity;
  isNavBarCollapsed = true;
  public allNotifications: Array<any> = [];
  public unreadNotifications: Array<any> = [];
  public activeTab: string = 'Production';
  public quicklinksShown: boolean = true;

  constructor(private userService: UserService,
    private router: Router,
    private pds: PDSService) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.quicklinksShown = event.url === '/home';
        this.setActiveTab();
      }
    });
  }

  async ngOnInit(): Promise<void> {
    await this.getLoggedInUser();
    await this.getAllNotifications();
  }

  async getLoggedInUser() {
    this.loggedInEntity = await this.userService.getLoggedInEntityUser();
    if (this.loggedInEntity) {
      this.securityGroup = this.loggedInEntity.securityGroupName;
    }
  }

  logOut() {
    this.userService.clearUserSession();
    this.navigateToNewPage('/login');
  }

  viewMappings(context) {
    this.router.navigate(['/mapping'], { queryParams: { mappingTab: context} })
  }

  navigateToNewPage(page: string, oid?: number) {
    if (oid) {
      this.router.navigate([page + '/' + UrlObfuscationService.obfuscate(oid)]);
    } else {
      this.router.navigate([page]);
    }

  }

  async getAllNotifications(): Promise<void> {
    this.allNotifications = await this.pds.getListOfNotifications().toPromise();
    this.unreadNotifications = this.allNotifications.filter(n => {
      return !n.isRead;
    });
  }

  public setActiveTab(tabString?: string): void {
    if (tabString) {
      this.activeTab = tabString;
      return;
    }
    const tab = this.router.url;
    if (tab.includes('home')) {
      this.activeTab = 'Home';
    } else if (tab.includes('feed-delivery') || tab.includes('worksheet') || tab.includes('end')) {
      this.activeTab = 'Production';
    } else if (tab.includes('mills') || tab.includes('dairies') || tab.includes('users') || tab.includes('incomplete')) {
      this.activeTab = 'Accounts';
    } else if (tab.includes('all') || tab.includes('feeds') || tab.includes('regions') || tab.includes('mapping')) {
      this.activeTab = 'Setup';
    } else if (tab.includes('profit') || tab.includes('production-delivery') || tab.includes('view') || tab.includes('cow') || tab.includes('monthly')) {
      this.activeTab = 'Reporting';
    }
  }

  public isNutritionistViewer() {
    if(this.loggedInEntity.securityGroupName === this.constNutritionist) {
      return true;
    } else {
      return false;
    }
  }

  public isMillViewer() {
    if(this.loggedInEntity.securityGroupName === this.constMill) {
      return true;
    } else {
      return false;
    }
  }

  public canViewReporting() {
    if(this.loggedInEntity.securityGroupName != this.constNutritionist && this.loggedInEntity.securityGroupName != this.constMill) {
      return true;
    } else {
      return false;
    }
  }
}

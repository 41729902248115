import { SwalOptions } from 'sweetalert/typings/modules/options';
declare let swal: any;

export interface ISWalOptions extends SwalOptions {
  buttons: any;
  content: any;
}

export class SwalDialog {

    public static async show<T>(options: Partial<ISWalOptions>): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            swal(options)
            .then(isConfirmed => {
                resolve(isConfirmed as T);
            })
            .catch(reason => {
                reject(reason as T);
            });
        });
    }

}

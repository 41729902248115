import {Component, OnInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {Lookup, CompAdjustment, PDSService, Entity} from '../../api/generated-api';
import {SwalDialog} from 'src/app/lib/swal-dialog';
import {LookupService} from '../../services/lookup.service';
import { UserService } from 'src/app/services/user.service';
import {CONSTANTS} from '../../lib/constants';

@Component({
  selector: 'add-edit-nutritionist-adjustments-modal',
  templateUrl: './add-edit-nutritionist-adjustments.component.html',
  styleUrls: ['./add-edit-nutritionist-adjustments.component.css']
})
export class AddEditNutritionistAdjustmentsComponent implements OnInit {

  public adjustment: CompAdjustment = <CompAdjustment>{};
  public nutritionistOid: number;
  public totalProfit: number;
  public grossPay: number;
  public isEdit: boolean = false;
  public isSaving: boolean = false;
  public constFlat: number = CONSTANTS.AMOUNT_TYPE_OID.FLAT;
  public constPercentage: number = CONSTANTS.AMOUNT_TYPE_OID.PERCENTAGE;
  public types: Lookup[] = [];
  public loggedInEntity: Entity = <Entity>{};
  public isSubmitted: boolean = false;

  constructor(public activeModal: NgbActiveModal,
              private lookup: LookupService,
              private userService: UserService,
              private pdsService: PDSService) {
    this.types = this.lookup.findLookupByLookupName('AmountType');
  }

  async ngOnInit(): Promise<void> {
    await this.getLoggedInUser();
    if (this.adjustment && this.adjustment.oid) {
      this.isEdit = true;
      this.adjustment.percentage = this.adjustment.percentage * 100;
    }
    if (!this.adjustment || !this.adjustment.oid) {
      this.createDefaultAdjustment();
    }
  }

  async getLoggedInUser(): Promise<void> {
    this.loggedInEntity = await this.userService.getLoggedInEntityUser();
  }

  createDefaultAdjustment() {
    this.adjustment.entityOid = this.nutritionistOid;
    this.adjustment.isActive = true;
    this.adjustment.isOneTime = true;
  }

  cancel(): void {
    this.resetProperties();
    this.activeModal.dismiss();
  }

  resetProperties(): void {
    this.adjustment = <CompAdjustment>{};
    this.isEdit = false;
    this.grossPay = null;
    this.totalProfit = null;
  }

  isInvalid(): string {
    let rString: string = '';
    if (!this.adjustment.description || this.adjustment.description === '') {
      rString = rString.concat('Must include a description </br>');
    }
    if (!this.adjustment.lkpAmountTypeOid || this.adjustment.lkpAmountTypeOid === null || this.adjustment.lkpAmountTypeOid < 0) {
      rString = rString.concat('Must select an amount type and set an amount </br>');
    }
    if (this.adjustment.lkpAmountTypeOid && +this.adjustment.lkpAmountTypeOid === +this.constFlat && (!this.adjustment.flatAmount || this.adjustment.flatAmount === null)) {
        if (!this.adjustment.flatAmount) {
          rString = rString.concat('Must include a flat amount </br>');
        }
    } else {
      if (this.adjustment.lkpAmountTypeOid && +this.adjustment.lkpAmountTypeOid === +this.constPercentage && (!this.adjustment.percentage || this.adjustment.percentage === null)) {
        rString = rString.concat('Must include a percentage amount </br>');
      }
    }
    return rString;
  }

  calculateGrossPay() {
    if (this.totalProfit) {
      if (+this.adjustment.lkpAmountTypeOid === +this.constFlat) {
        this.grossPay = this.adjustment.flatAmount ? this.adjustment.flatAmount : 0;
      } else {
        this.grossPay = this.adjustment.percentage ? ((+this.adjustment.percentage * .01) * this.totalProfit) : 0;
      }
    }
  }

  changeLkpType() {
    this.adjustment.flatAmount = null;
    this.adjustment.percentage = null;
  }

  async save() {
    const isInvalidString: string = this.isInvalid();
    if (isInvalidString && isInvalidString !== '') {
      this.isSaving = false;
      this.isSubmitted = true;
      const myhtml = document.createElement('div');
      myhtml.innerHTML = isInvalidString;
      SwalDialog.show({
        title: 'Validation Failed',
        content: myhtml,
        icon: 'error',
      });
      return;
    } else {
      this.isSubmitted = true;
      this.isSaving = true;
      if(this.adjustment.percentage != null) {
        this.adjustment.percentage_Display = this.adjustment.percentage.toString();
      }
      this.adjustment.percentage = this.adjustment.percentage / 100;
      const res = await this.pdsService.addUpdateCompAdjustments([this.adjustment]).toPromise();
      this.isSaving = false;
      this.resetProperties();
      this.activeModal.dismiss(res);
    }
  }

}

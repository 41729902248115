import { Component, OnInit } from '@angular/core';
import {SwalDialog} from '../../lib/swal-dialog';
import {Feed, FeedDTO, FeedPlanDTO, PDSService, ValuePair} from '../../api/generated-api';
import {ToastrService} from "ngx-toastr";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'confirm-seal-modal',
  templateUrl: './confirm-seal-modal.component.html',
  styleUrls: ['./confirm-seal-modal.component.css']
})
export class ConfirmSealModalComponent implements OnInit {

  public pageLoading: boolean = false;
  public confirmText: string = '';
  public isSaving: boolean = false;

  constructor(private toast: ToastrService, public activeModal: NgbActiveModal) {
  }

  async ngOnInit(): Promise<void> {
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

  confirmSeal() {
    if(this.confirmText.toUpperCase() === 'SEAL') {
      this.activeModal.dismiss(true)
    } else {
      this.toast.error('Please type seal to confirm and continue');
    }
  }


}
